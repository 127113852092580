import axios from "axios";
import {
  api_error_messages_to_exit,
  save_tokens_constant,
  session_expired,
} from "../../store/constants";
// import {
//   api_error_messages_to_exit,
//   save_tokens_constant,
//   session_expired,
// } from "../../store/constant";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

export const exit_session = () => {
  localStorage.removeItem(save_tokens_constant);
  localStorage.setItem(session_expired, true);
  window.location.reload();
};
export const baseURL = `https://preaching-quotes-2777dd334af1.herokuapp.com/`;
// export const baseURL = `http://localhost:5000`;

export const apiHandle = axios.create({
  baseURL: `${baseURL}`,
});

axios.defaults.timeout = 15000;

apiHandle.interceptors.request.use(async (req) => {
  const authTokens = localStorage.getItem(save_tokens_constant)
    ? JSON.parse(localStorage.getItem(save_tokens_constant))
    : null;

  if (authTokens) {
    req.headers.Authorization = `Bearer ${authTokens}`;

    const user = jwtDecode(authTokens);

    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    // const refreshTokenDecode = jwtDecode(authTokens?.refresh_token);
    // const isRefreshExpired =
    //   dayjs.unix(refreshTokenDecode.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      try {
        req.headers.Authorization = `Bearer ${authTokens}`;
        localStorage.setItem(save_tokens_constant, JSON.stringify(authTokens));
      } catch (error) {
        if (error.response.data) {
          if (
            api_error_messages_to_exit.includes(error.response.data.message)
          ) {
            exit_session();
          }
        }
      }
    } else {
      exit_session();
    }
  }

  return req;
});
