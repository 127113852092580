const FEATURED_QUOTES_DATA = [
  {
    message: "Successfully Get",
    data: {
      _id: "6626fea6fa537936aa24e506",
      Quote: "You might as well not be alive if you're not in awe of God.",
      Author: "Albert Einstein",
      Paraphrase: "One cannot truly live without being in awe of God.",
      Commentary:
        "This quote by Albert Einstein emphasizes the profound significance of being in awe of God. It speaks to the idea that acknowledging the divine and experiencing wonder and reverence for the mysteries of existence are essential for a truly fulfilling life.",
      Poem: "In awe of God,\n life finds its way,\n A mystery divine,\n in every day. With faith and wonder,\n we embrace,\n The presence of God,\n in every place.",
      joke: "Did you hear about the person who said they're not in awe of God? They might as well be a cod!",
      Riddle:
        "I am what makes life complete, Without me, it's just a feat. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "Awe",
        "God",
        "Life",
        "Faith",
        "Wonder",
        "Existence",
        "Spirituality",
        "Divine",
        "Mystery",
        "Creation",
      ],
      Theme: [
        "Awe of God",
        "Existence and faith",
        "Life's wonder",
        "Divine mystery",
        "Spirituality and awe",
        "Wonder of creation",
        "Faith in existence",
        "God's mystery",
        "Life and spirituality",
        "Mystery of faith",
      ],
      Questions: [
        "What does it mean to be in awe of God?",
        "How does awe of God impact one's perspective on life?",
        "In what ways can one cultivate a sense of wonder and awe in relation to God?",
        "Why is being in awe of God considered significant for many people?",
        "How does the concept of awe of God intersect with various religious and spiritual beliefs?",
        "How does this quote reflect the idea of finding meaning and purpose through spirituality?",
        "In what ways can the awe of God influence one's daily life and interactions?",
      ],
      verses: [
        "Psalm 33:8",
        "Psalm 145:5",
        "Proverbs 9:10",
        "Hebrews 11:6",
        "Romans 1:20",
      ],
      Suggestions: [
        "Explore the concept of awe and reverence in relation to God's creation",
        "Discuss the role of awe in deepening one's spiritual connection",
        "Reflect on the significance of awe in shaping one's faith journey",
        "Examine how awe of God can inspire a sense of wonder and gratitude",
        "Encourage the congregation to embrace awe and reverence as integral aspects of their faith",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe87fa537936aa2469ed",
      Quote: "To be right with God has often meant to be in trouble with men.",
      Author: "Aiden Wilson Tozer",
      Paraphrase: "Being in harmony with God often leads to discord with men.",
      Commentary:
        "This quote encapsulates the tension between living a life aligned with God's will and the inevitable conflicts that arise in a world at odds with divine principles. It highlights the paradox that righteousness may lead to opposition and trouble, yet ultimately affirms the importance of faith and obedience despite adversity.",
      Poem: "To walk with God invites the world's disdain,\n Yet in His love,\n we shall remain. Through trials and tests,\n our faith is tried,\n In Him,\n our refuge will abide.",
      joke: "Why did the righteous man always have trouble finding a seat? Because he refused to sit in the seat of the scornful!",
      Riddle:
        "I am found in being right with God, yet often bring trouble with men. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "God",
        "trouble",
        "righteousness",
        "faith",
        "persecution",
        "obedience",
        "suffering",
        "belief",
        "opposition",
        "spirituality",
      ],
      Theme: [
        "Struggle for righteousness",
        "Conflict with faith",
        "Divine approval and human conflict",
        "Alignment with God and adversity",
        "Faith's clash with society",
        "Righteousness and persecution",
        "God's favor and human opposition",
        "Spiritual obedience and worldly trouble",
        "Belief and suffering",
        "Faith and opposition",
      ],
      Questions: [
        "How does this quote challenge our understanding of faith and righteousness?",
        "In what ways can we navigate the tension between spiritual alignment and worldly conflict?",
        "What examples from history or literature resonate with the message of this quote?",
        "How does this quote speak to the experiences of individuals facing opposition for their beliefs?",
        "What implications does this quote have for our interactions with others who may oppose our faith?",
        "How does this quote resonate with personal experiences of facing opposition for one's beliefs?",
        "In what ways can this quote offer comfort or guidance to those encountering resistance for their faith?",
      ],
      verses: [
        "Matthew 5:10",
        "John 15:18",
        "2 Timothy 3:12",
        "1 Peter 4:12-14",
        "James 4:4",
      ],
      Suggestions: [
        "Explore the theme of persecution and righteousness in the Bible, drawing from the verses related to this quote.",
        "Discuss the challenges of living a faithful life in a world that may oppose God's principles.",
        "Encourage reflection on the examples of individuals in the Bible who faced opposition for their faith.",
        "Examine the concept of spiritual alignment and the conflicts it may entail in contemporary society.",
        "Offer guidance on navigating adversity while remaining steadfast in faith.",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626ffb2fa537936aa290c1d",
      Quote:
        "Christianity is not about religion. It's about faith, about being held, about being forgiven. It's about finding joy and finding home.",
      Author: "Bear Grylls",
      Paraphrase:
        "Christianity centers on faith, forgiveness, and finding joy and home, rather than mere religious rituals.",
      Commentary:
        "Bear Grylls' quote emphasizes the essence of Christianity, highlighting faith, forgiveness, joy, and finding home. It speaks to the core of the Christian faith, emphasizing a personal relationship with God over religious formalities.",
      Poem: "In faith we're held,\n forgiven,\n and free,\n Finding joy and home,\n our hearts full and light,\n Christianity's grace,\n our redemption key,\n Religion fades,\n faith's spirit takes flight.",
      joke: "A man inspired by the message of this quote walked into a bar and said, 'I'll have a glass of forgiveness on the rocks, please.' The bartender replied, 'Sorry, we only serve joy and home here.'",
      Riddle:
        "How can one find joy and home, be held and forgiven, without being confined by religion or creed?",
      "Biblical Worldview": "Yes",
      Topics: [
        "faith",
        "forgiveness",
        "joy",
        "home",
        "religion",
        "Christianity",
        "belief",
        "grace",
        "redemption",
        "spirituality",
      ],
      Theme: [
        "faith and forgiveness",
        "finding joy",
        "being held",
        "Christianity and faith",
        "forgiven and finding joy",
        "spirituality and home",
        "grace and redemption",
        "religion versus faith",
        "belief and forgiveness",
        "joy and home",
      ],
      Questions: [
        "How does this quote challenge traditional views of Christianity?",
        "What does it mean to find joy and home in the context of faith?",
        "How can one experience being held and forgiven in their spiritual journey?",
        "In what ways does this quote resonate with individuals of different faiths?",
        "How can the message of this quote be applied in daily life?",
        "How does this quote redefine the concept of Christianity?",
        "What does it mean to be held and forgiven in the context of faith?",
      ],
      verses: [
        "John 3:16",
        "Ephesians 2:8-9",
        "Romans 10:9",
        "2 Corinthians 5:17",
        "Galatians 2:20",
      ],
      Suggestions: [
        "Explore the themes of faith, forgiveness, and finding joy and home in Christian life",
        "Discuss the contrast between religion and true faith in Christianity",
        "Encourage personal reflection on the meaning of being held and forgiven",
        "Highlight the transformative power of grace and redemption in Christian belief",
        "Emphasize the joy and fulfillment found in a spiritual home through faith",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fec7fa537936aa256acd",
      Quote:
        "What I do is play football. What I do doesn't change who I am and who God sees me as.",
      Author: "Benjamin Watson",
      Paraphrase:
        "My actions on the field don't define who I am in God's eyes.",
      Commentary:
        "This quote emphasizes the importance of maintaining a consistent and unwavering sense of self, regardless of external actions or circumstances. It speaks to the idea of integrity and faith, highlighting the deeper connection between personal identity and spirituality.",
      Poem: "On the field or in the pew,\n I am who I am,\n through and through. God sees me as I truly be,\n beyond the game,\n I am free.",
      joke: "What inspired the football player to stay true to himself? His belief that his actions on the field don't change who he is in God's eyes!",
      Riddle: "I am what doesn't change who I am in God's eyes. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "identity",
        "faith",
        "football",
        "integrity",
        "character",
        "purpose",
        "belief",
        "performance",
        "consistency",
        "spirituality",
      ],
      Theme: [
        "Identity and Football",
        "Faith and Performance",
        "Character and Integrity",
        "Purpose and Belief",
        "Consistency and Spirituality",
        "Integrity in Football",
        "Faith and Identity",
        "Character and Purpose",
        "Belief and Performance",
        "Spirituality and Consistency",
      ],
      Questions: [
        "How does this quote challenge our understanding of identity?",
        "In what ways can faith influence our actions and character?",
        "How does this quote relate to the concept of integrity?",
        "What role does spirituality play in shaping our sense of self?",
        "How can we apply the message of this quote to our daily lives?",
        "How does this quote resonate with your personal beliefs?",
        "In what ways can this quote be applied to different aspects of life?",
      ],
      verses: [
        "1 Samuel 16:7",
        "Colossians 3:23",
        "Galatians 1:10",
        "Proverbs 16:3",
        "Romans 12:2",
      ],
      Suggestions: [
        "Explore the concept of identity and faith in relation to personal actions",
        "Discuss the idea of integrity and consistency in one's character",
        "Highlight the role of spirituality in shaping beliefs and purpose",
        "Encourage reflection on the connection between faith and performance",
        "Examine the impact of external influences on personal identity and belief",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fea0fa537936aa24cf54",
      Quote: "Happy the soul that has been awed by a view of God's majesty",
      Author: "Arthur W. Pink",
      Paraphrase:
        "Blessed is the soul that has experienced the grandeur of God.",
      Commentary:
        "This quote encapsulates the profound joy and contentment found in experiencing the awe-inspiring majesty of God. It speaks to the deep spiritual fulfillment and happiness that comes from recognizing and reflecting on the greatness of God's divine nature.",
      Poem: "In awe of God's majesty,\n the soul finds glee,\n \nInspired by the view,\n it's happy and free. \nReflecting on the divine,\n the spirit soars,\n \nIn worship and awe,\n the soul adores.",
      joke: "I used to think I was happy, then I saw God's majesty and realized I was ecstatic!",
      Riddle: "Why does the happy soul reflect on divine majesty?",
      "Biblical Worldview": "Yes",
      Topics: [
        "awe",
        "majesty",
        "soul",
        "God",
        "view",
        "happy",
        "inspired",
        "spirituality",
        "worship",
        "reflection",
      ],
      Theme: [
        "Awe of God's majesty",
        "Soul's happiness in God",
        "View of divine majesty",
        "Inspired by God's majesty",
        "Spirituality and awe",
        "Majestic view of God",
        "Happy soul in God's majesty",
        "Reflecting on God's majesty",
        "Worship and awe",
        "Divine inspiration",
      ],
      Questions: [
        "How does experiencing God's majesty impact one's soul?",
        "What practices can cultivate a sense of awe towards God's majesty?",
        "In what ways can reflecting on God's majesty bring happiness?",
        "How does the concept of divine majesty influence one's spiritual journey?",
        "What role does awe play in one's relationship with God?",
        "How does the concept of God's majesty influence one's perspective on life?",
        "In what ways can experiencing awe lead to spiritual growth?",
      ],
      verses: [
        "Psalm 8:1",
        "Psalm 29:2",
        "Psalm 96:9",
        "Psalm 104:1",
        "Isaiah 6:3",
      ],
      Suggestions: [
        "Incorporate personal experiences of awe and happiness in God's majesty",
        "Discuss the transformative power of recognizing God's grandeur",
        "Explore the concept of divine inspiration and its impact on the soul",
        "Encourage reflection on the happiness found in awe of God's majesty",
        "Connect the quote to the biblical portrayal of God's majestic nature",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe5bfa537936aa23c231",
      Quote: "He who has courage and faith will never perish in misery!",
      Author: "Anne Frank",
      Paraphrase: "Those with bravery and trust will not succumb to suffering!",
      Commentary:
        "This quote by Anne Frank emphasizes the enduring power of courage and faith in the face of adversity. It serves as a reminder that maintaining hope and resilience can help individuals overcome even the most challenging circumstances.",
      Poem: "With courage and faith,\n we stand tall,\n Never to falter,\n never to fall. In times of misery,\n we find our might,\n Guided by hope,\n we'll win the fight.",
      joke: "How does a courageous person handle misery? They face it head-on and say, 'Not today, misery, not today!'",
      Riddle:
        "Why can someone with courage and faith never perish in misery? Because in their heart, hope and strength will always be!",
      "Biblical Worldview": "Yes",
      Topics: [
        "courage",
        "faith",
        "perseverance",
        "strength",
        "hope",
        "resilience",
        "belief",
        "determination",
        "optimism",
        "endurance",
      ],
      Theme: [
        "Courage and faith",
        "Never perish",
        "Strength in adversity",
        "Hope and resilience",
        "Belief in overcoming",
        "Determination and optimism",
        "Perseverance in hardship",
        "Unyielding faith",
        "Endurance through faith",
        "Courage against misery",
      ],
      Questions: [
        "How does courage contribute to overcoming misery?",
        "What role does faith play in enduring hardship?",
        "Can optimism and determination alleviate suffering?",
        "How can one cultivate resilience in the midst of adversity?",
        "In what ways can belief help in facing difficult situations?",
        "How does the concept of courage apply to real-life challenges?",
        "What are some examples of faith helping individuals overcome adversity?",
      ],
      verses: [
        "Joshua 1:9",
        "Psalm 31:24",
        "Isaiah 41:10",
        "Romans 5:3-4",
        "Hebrews 10:35",
      ],
      Suggestions: [
        "Highlight stories of courage and faith from the Bible",
        "Emphasize the importance of resilience and hope in challenging times",
        "Encourage the congregation to support one another in times of adversity",
        "Explore the concept of enduring faith in the face of hardship",
        "Discussing practical ways to cultivate courage and faith in daily life",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fed8fa537936aa25ab13",
      Quote: "Battering the gates of heaven with the storms of prayer.",
      Author: "Alfred Lord Tennyson",
      Paraphrase: "Persistently praying, the storms batter heaven's gates.",
      Commentary:
        "This quote encapsulates the idea of persistent faith and the power of prayer. It speaks to the unwavering determination and hope that individuals hold when facing challenges. It highlights the belief in the transformative strength of prayer and the resilience of the human spirit.",
      Poem: "With prayers that storm,\n heaven's gates transform,\n faith's power to perform,\n in trials,\n we'll reform.",
      joke: "A man inspired by the message of this quote decided to pray for a storm of money to batter the gates of his bank account!",
      Riddle: "How can one reach heaven's gates with a forceful plea?",
      "Biblical Worldview": "Yes",
      Topics: [
        "prayer",
        "heaven",
        "storms",
        "faith",
        "persistence",
        "spirituality",
        "hope",
        "perseverance",
        "power",
        "divine",
      ],
      Theme: [
        "Persistent prayer",
        "Heaven's gates",
        "Storms of faith",
        "Divine perseverance",
        "Hopeful persistence",
        "Spiritual power",
        "Prayer's strength",
        "Heaven's storms",
        "Faith's endurance",
        "Persevering faith",
      ],
      Questions: [
        "How does this quote reflect the concept of perseverance in faith?",
        "What role does prayer play in overcoming obstacles, according to this quote?",
        "In what ways can the storms of life be likened to the storms of prayer?",
        "How does this quote inspire hope and resilience in the face of adversity?",
        "What does this quote teach about the power of persistence and determination in spiritual matters?",
        "How does this quote inspire perseverance in the face of challenges?",
        "What does the imagery of 'battering the gates of heaven with the storms of prayer' evoke in your mind?",
      ],
      verses: [
        "Matthew 7:7",
        "Luke 18:1",
        "Ephesians 6:18",
        "James 5:16",
        "1 Thessalonians 5:17",
      ],
      Suggestions: [
        "Explore the theme of persistent faith and prayer in the face of adversity",
        "Highlight the transformative power of unwavering belief and perseverance",
        "Encourage the congregation to embrace hope and resilience through prayer",
        "Discuss the significance of spiritual endurance and the strength found in persistent prayer",
        "Illustrate the concept of 'storms of prayer' and its impact on spiritual growth and perseverance",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe8dfa537936aa24812e",
      Quote: "Who can ever measure the benefit of a mother's inspiration?",
      Author: "Charles R. Swindoll",
      Paraphrase: "The influence of a mother's inspiration is immeasurable.",
      Commentary:
        "This quote beautifully encapsulates the immeasurable impact of a mother's inspiration. It highlights the profound influence of maternal guidance and support, emphasizing the lasting effects of a mother's love and encouragement.",
      Poem: "A mother's love,\n a guiding light,\n Inspires our hearts,\n day and night. Her words and deeds,\n a lasting treasure,\n A source of strength,\n a well of pleasure.",
      joke: "Why did the mother always carry a pencil? Because she was the inspiration behind all the family's 'write' decisions!",
      Riddle:
        "What is always present, yet immeasurable in its impact? It's the source of inspiration that flows from a mother's heart.",
      "Biblical Worldview": "Yes",
      Topics: [
        "love",
        "influence",
        "motherhood",
        "inspiration",
        "gratitude",
        "family",
        "support",
        "encouragement",
        "impact",
        "appreciation",
      ],
      Theme: [
        "Mother's inspiration",
        "Unmeasurable influence",
        "Grateful for mothers",
        "Power of maternal support",
        "Inspirational mother figures",
        "Impact of maternal guidance",
        "Influence of mother's love",
        "Appreciating maternal inspiration",
        "Mother's lasting impact",
        "Encouragement from mothers",
      ],
      Questions: [
        "How does a mother's inspiration shape a person's character?",
        "What are some tangible examples of a mother's influence in shaping individuals?",
        "In what ways can we express gratitude for a mother's inspiration?",
        "How can we carry forward a mother's inspiration to positively impact others?",
        "What role does a mother's inspiration play in shaping family dynamics and relationships?",
        "How does a mother's inspiration continue to influence us throughout our lives?",
        "In what ways can we actively acknowledge and appreciate a mother's inspiration?",
      ],
      verses: [
        "Proverbs 31:26",
        "Proverbs 6:20-22",
        "Ephesians 6:2",
        "Proverbs 23:22",
        "2 Timothy 1:5",
      ],
      Suggestions: [
        "Explore the enduring impact of a mother's inspiration in shaping individuals' lives.",
        "Highlight biblical examples of maternal influence and inspiration.",
        "Encourage gratitude and appreciation for the role of mothers in inspiring and guiding others.",
        "Discuss the ways in which a mother's inspiration aligns with biblical teachings on love and guidance.",
        "Emphasize the importance of carrying forward the legacy of maternal inspiration in our own lives and communities.",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fea2fa537936aa24d769",
      Quote: "We keep asking for answers; God keeps sending us people.",
      Author: "Bob Goff",
      Paraphrase: "We often seek answers, yet God often sends us people.",
      Commentary:
        "This quote by Bob Goff emphasizes the idea that often the answers we seek are found in the people around us. It highlights the significance of human connections, community, and the role of others in providing guidance and support in our lives.",
      Poem: "Seeking answers,\n we find in others,\n A divine plan,\n sisters and brothers. Trusting in people,\n our faith is strong,\n God's guidance in them,\n where we belong.",
      joke: "Did you hear about the person who kept asking for answers? God sent them a whole bunch of people! Looks like God has a sense of humor.",
      Riddle:
        "What is it that we seek, yet often find in others? It's not an answer, but it's just as divine.",
      "Biblical Worldview": "Yes",
      Topics: [
        "connection",
        "community",
        "help",
        "support",
        "relationships",
        "faith",
        "trust",
        "guidance",
        "people",
        "answers",
      ],
      Theme: [
        "seeking guidance",
        "divine connections",
        "faith in people",
        "community support",
        "trusting relationships",
        "answers in others",
        "people as answers",
        "faithful guidance",
        "supportive community",
        "divine connections",
      ],
      Questions: [
        "How can we cultivate meaningful connections with others?",
        "In what ways can we be the 'answers' for someone else?",
        "What role do relationships play in our search for guidance?",
        "How does this quote challenge our individualistic mindset?",
        "What does it mean to be open to receiving help from others?",
        "How does this quote challenge our perspective on seeking answers?",
        "In what ways can we recognize the guidance of others in our lives?",
      ],
      verses: [
        "Proverbs 11:14",
        "Ecclesiastes 4:9-10",
        "Galatians 6:2",
        "1 Thessalonians 5:11",
        "Hebrews 10:24-25",
      ],
      Suggestions: [
        "Highlight the importance of community and support in seeking guidance.",
        "Explore biblical examples of people being answers for each other.",
        "Encourage fostering meaningful connections within the congregation.",
        "Discuss the role of faith and trust in seeking help from others.",
        "Emphasize the idea of being open to receiving guidance from unexpected sources.",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fea0fa537936aa24ce51",
      Quote:
        "Never let the presence of a storm cause you to doubt the presence of God.",
      Author: "Craig Groeschel",
      Paraphrase: "Never doubt God's presence in the storm's essence.",
      Commentary:
        "This quote encapsulates the enduring message of unwavering faith and trust in God's presence, even amidst life's storms. It serves as a reminder to hold onto belief and hope, finding strength in the assurance of God's constant companionship.",
      Poem: "In the storm's embrace,\n God's presence I trace,\n Doubt gives way to faith,\n His love,\n my saving grace.",
      joke: "I used to think storms meant God's on a break, but then I realized He's the calm in the wake!",
      Riddle:
        "I am with you in doubt and storm, Yet my presence keeps you warm. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "faith",
        "trust",
        "God",
        "storm",
        "doubt",
        "presence",
        "belief",
        "hope",
        "perseverance",
        "strength",
      ],
      Theme: [
        "Faith in Storms",
        "Trusting God's Presence",
        "Overcoming Doubt in Trials",
        "Belief Amidst Storms",
        "Finding Hope in Adversity",
        "Perseverance and Faith",
        "Strength in God's Presence",
        "Trusting Through Storms",
        "Doubt vs. Belief",
        "God's Unwavering Presence",
      ],
      Questions: [
        "How does this quote challenge our perception of adversity?",
        "In what ways can we apply this quote to our daily struggles?",
        "What role does faith play in overcoming doubt during difficult times?",
        "How can we help others recognize God's presence in their storms?",
        "What practical steps can we take to strengthen our trust in God's presence during challenges?",
        "How does this quote inspire resilience in the face of adversity?",
        "What does 'presence of God' mean in the context of this quote?",
      ],
      verses: [
        "Psalm 23:4",
        "Isaiah 43:2",
        "Matthew 8:26",
        "Hebrews 13:5",
        "2 Corinthians 4:8-9",
      ],
      Suggestions: [
        "Illustrate with biblical accounts of faith in adversity (e.g., Daniel in the lion's den)",
        "Explore the concept of God's presence in the midst of trials and tribulations",
        "Encourage the congregation to share personal experiences of trusting God in difficult times",
        "Discuss practical ways to strengthen faith and trust in God's presence during storms",
        "Highlight the biblical promises of God's unwavering presence and faithfulness",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe86fa537936aa246498",
      Quote:
        "We do not see into men's hearts. We cannot judge, and are indeed forbidden to judge.",
      Author: "C. S. Lewis",
      Paraphrase:
        "We lack insight into men's hearts, thus we must refrain from passing judgment, as it is not within our purview.",
      Commentary:
        "This quote by C. S. Lewis emphasizes the importance of empathy and understanding, reminding us to refrain from hasty judgments. It encourages introspection and compassion, highlighting the complexity of human nature and the limitations of our perception.",
      Poem: "In hearts we cannot see,\n judgment must not be,\n empathy is the key,\n to understand and see.",
      joke: "I used to think I could see into men's hearts, but then I realized I'm not an X-ray machine!",
      Riddle:
        "I am with this quote, yet unseen by the eye, judging is forbidden, what am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "judgment",
        "heart",
        "forbidden",
        "understanding",
        "compassion",
        "empathy",
        "tolerance",
        "introspection",
        "perception",
        "discernment",
      ],
      Theme: [
        "Understanding Others",
        "Heart Perception",
        "Forbidden Judgment",
        "Empathy and Tolerance",
        "Discerning Hearts",
        "Compassion Over Judgment",
        "Seeing Beyond Surface",
        "Respecting Others' Hearts",
        "Banning Hasty Judgments",
        "Empathetic Understanding",
      ],
      Questions: [
        "How does this quote challenge our natural tendency to judge others?",
        "What role does empathy play in understanding others' hearts?",
        "How can we cultivate a mindset of compassion over judgment?",
        "In what ways can this quote influence our interactions with others?",
        "What impact could embracing this quote have on our relationships and communities?",
        "How does this quote encourage us to approach others with empathy?",
        "What are the implications of forbidding judgment based on this quote?",
      ],
      verses: [
        "Matthew 7:1",
        "Luke 6:37",
        "1 Samuel 16:7",
        "Proverbs 21:2",
        "James 4:12",
      ],
      Suggestions: [
        "Explore the theme of empathy and understanding in relationships",
        "Discuss the dangers of hasty judgments and the value of compassion",
        "Encourage introspection and self-awareness in understanding others",
        "Highlight biblical teachings on empathy and refraining from judgment",
        "Illustrate the impact of empathy and understanding through real-life examples",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe93fa537936aa249c02",
      Quote:
        "Remember when you're out there trying to heal the sick, that you must always first forgive them.",
      Author: "Bob Dylan",
      Paraphrase: "When helping the sick, remember to first offer forgiveness.",
      Commentary:
        "This quote by Bob Dylan emphasizes the importance of forgiveness in the process of healing. It reminds us that before we can help others, we must first let go of any resentment or anger. By offering forgiveness, we open the door to compassion and understanding, creating a foundation for true healing and reconciliation.",
      Poem: "To heal the sick,\n forgiveness is the trick,\n A heart so kind,\n can mend the mind.",
      joke: "Did you hear about the forgiving doctor? He always prescribes a dose of forgiveness before any treatment!",
      Riddle:
        "How can healing begin before forgiveness? It's the first step to mend and bless.",
      "Biblical Worldview": "Yes",
      Topics: [
        "forgiveness",
        "healing",
        "compassion",
        "empathy",
        "mercy",
        "understanding",
        "kindness",
        "reconciliation",
        "patience",
        "love",
      ],
      Theme: [
        "Forgiving others",
        "Healing through forgiveness",
        "Compassion in healing",
        "Forgiveness and reconciliation",
        "The power of forgiveness",
        "Forgiveness before healing",
        "Forgiveness and understanding",
        "Healing with empathy",
        "Forgiveness and mercy",
        "The importance of forgiveness",
      ],
      Questions: [
        "How does forgiveness contribute to the healing process?",
        "What role does empathy play in forgiving others?",
        "Why is it important to prioritize forgiveness when helping others?",
        "How can we cultivate a spirit of forgiveness in our interactions with others?",
        "In what ways does forgiveness lead to personal and relational healing?",
        "How does forgiveness pave the way for healing?",
        "What does it mean to forgive before helping others?",
      ],
      verses: [
        "Matthew 6:14",
        "Colossians 3:13",
        "Ephesians 4:32",
        "Mark 11:25",
        "Luke 6:37",
      ],
      Suggestions: [
        "Explore the concept of forgiveness and its role in healing",
        "Illustrate biblical examples of forgiveness and healing",
        "Encourage personal reflection on the importance of forgiveness in aiding others",
        "Discuss practical ways to integrate forgiveness into caregiving and support",
        "Highlight the transformative power of forgiveness in promoting emotional and spiritual well-being",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe8efa537936aa2482eb",
      Quote:
        "Faith sees the invisible, believes the unbelievable, and receives the impossible.",
      Author: "Corrie Ten Boom",
      Paraphrase:
        "Perceiving the unseen, embracing the incredible, and achieving the unimaginable.",
      Commentary:
        "Corrie Ten Boom's quote encapsulates the essence of faith, urging us to trust in the unseen, believe in the extraordinary, and remain open to the miraculous. It serves as a powerful reminder of the resilience and hope that faith can bring in the face of adversity.",
      Poem: "Faith's sight sees the unseen,\n Believes in what's never been,\n Receives the impossible dream,\n Trusting in what's not yet seen.",
      joke: "I hate when I'm expected to believe the unbelievable, receive the impossible, and see the invisible. It's like asking me to find a unicorn in my backyard!",
      Riddle:
        "Why do we embrace the unseen, believe the unbelievable, and receive the impossible, yet still find hope?",
      "Biblical Worldview": "Yes",
      Topics: [
        "Faith",
        "Invisible",
        "Belief",
        "Hope",
        "Miracle",
        "Courage",
        "Trust",
        "Perseverance",
        "Divine",
        "Possibility",
      ],
      Theme: [
        "Belief in miracles",
        "Courage in adversity",
        "Trusting the unseen",
        "Hope in impossibility",
        "Faith in the divine",
        "Perseverance in trials",
        "Belief in the extraordinary",
        "Courageous faith",
        "Trusting the impossible",
        "Hope beyond reason",
      ],
      Questions: [
        "How does faith enable us to see the invisible?",
        "What role does belief in the unbelievable play in our lives?",
        "How can we cultivate a mindset of receiving the impossible?",
        "In what ways does this quote challenge conventional thinking?",
        "How can faith in the impossible inspire action and perseverance?",
        "How does this quote challenge traditional views on faith?",
        "What does it mean to believe in the unbelievable?",
      ],
      verses: [
        "Hebrews 11:1",
        "2 Corinthians 4:18",
        "Mark 9:23",
        "Matthew 19:26",
        "Luke 1:37",
      ],
      Suggestions: [
        "Explore the power of faith in overcoming challenges",
        "Discuss the significance of believing in the extraordinary",
        "Encourage perseverance in the face of impossibility",
        "Highlight biblical examples of trusting in the unseen",
        "Examine the transformative nature of embracing the impossible",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe60fa537936aa23d5b0",
      Quote:
        "If you come at the Bible as if it's a document of encyclopedic information, you've pretty much killed any kind of life change in a seeker and unbeliever.",
      Author: "Erwin McManus",
      Paraphrase:
        "Approaching the Bible as a source of life-altering wisdom, rather than a mere repository of facts, is crucial for impacting seekers and unbelievers.",
      Commentary:
        "This quote emphasizes the importance of approaching the Bible with a mindset focused on transformation and understanding, rather than treating it as a dry source of information. It highlights the need for seekers and unbelievers to encounter the living message of the Bible, which has the power to bring about profound change.",
      Poem: "Approach the Book with open heart,\n not as encyclopedia's part. Seekers need a living Word,\n not just facts that may be heard.",
      joke: "How do you inspire a seeker? Tell them the Bible's not an encyclopedia!",
      Riddle:
        "What is a source of transformation for seekers and unbelievers, not just a compendium of facts?",
      "Biblical Worldview": "Yes",
      Topics: [
        "Bible",
        "seeker",
        "change",
        "belief",
        "faith",
        "interpretation",
        "understanding",
        "transformation",
        "perspective",
        "approach",
      ],
      Theme: [
        "Biblical interpretation",
        "Life change",
        "Encyclopedic information",
        "Seeker's perspective",
        "Unbeliever's understanding",
        "Document of faith",
        "Transformation through belief",
        "Perspective on the Bible",
        "Approach to seekers",
        "Belief and change",
      ],
      Questions: [
        "How does our approach to the Bible impact seekers and unbelievers?",
        "What role does personal transformation play in sharing the message of the Bible?",
        "In what ways can we communicate the life-changing power of the Bible to seekers?",
        "How can we shift our perspective on the Bible to better reach those who are seeking?",
        "What steps can we take to ensure the Bible is presented as a source of transformation rather than just information?",
        "How can we convey the life-changing message of the Bible to seekers and unbelievers?",
        "What strategies can be employed to shift the perception of the Bible from an encyclopedia to a source of transformation?",
      ],
      verses: [
        "2 Corinthians 3:18",
        "Romans 12:2",
        "Hebrews 4:12",
        "James 1:22",
        "Matthew 13:15",
      ],
      Suggestions: [
        "Preach on the transformative power of the living Word",
        "Discuss the impact of belief on personal change",
        "Examine the seeker's perspective on the Bible",
        "Encourage a shift in approach to sharing the message of the Bible",
        "Highlight the importance of understanding over mere knowledge",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fed7fa537936aa25a953",
      Quote: "All my discoveries have been made in answer to prayer.",
      Author: "Isaac Newton",
      Paraphrase:
        "All the breakthroughs I've made have come in response to prayer.",
      Commentary:
        "Isaac Newton's quote highlights the profound connection between faith and discovery. It suggests that prayer and spiritual reflection can be sources of inspiration and guidance in the pursuit of knowledge and understanding. This quote invites contemplation on the role of faith in scientific exploration and the acknowledgment of the divine in moments of discovery.",
      Poem: "In prayer I seek,\n in discovery I find,\n A path illuminated,\n by faith entwined. Through trials and errors,\n and moments of despair,\n I find my answers,\n in whispered prayer.",
      joke: "How does a scientist make discoveries? By praying for a little divine inspiration!",
      Riddle:
        "What leads to discoveries and breakthroughs, yet cannot be seen or touched? It's the key to Isaac Newton's revelations.",
      "Biblical Worldview": "Yes",
      Topics: [
        "discovery",
        "prayer",
        "faith",
        "inspiration",
        "science",
        "belief",
        "spirituality",
        "reflection",
        "meditation",
        "gratitude",
      ],
      Theme: [
        "power of prayer",
        "divine inspiration",
        "spiritual discovery",
        "faith and science",
        "prayerful reflection",
        "inspired by faith",
        "meditative discovery",
        "gratitude in discovery",
        "prayer and inspiration",
        "faithful reflection",
      ],
      Questions: [
        "How does prayer influence the process of discovery?",
        "What role does faith play in scientific breakthroughs?",
        "Can spirituality and science coexist in the pursuit of knowledge?",
        "How does gratitude intersect with the process of discovery?",
        "In what ways can prayer inspire creativity and innovation?",
        "How can prayer impact the pursuit of knowledge and discovery?",
        "What does this quote reveal about the relationship between faith and scientific exploration?",
      ],
      verses: [
        "Matthew 7:7",
        "Philippians 4:6",
        "James 1:5",
        "1 Thessalonians 5:17",
        "Colossians 4:2",
      ],
      Suggestions: [
        "Explore the connection between faith and scientific discovery",
        "Discuss the role of prayer in seeking knowledge and inspiration",
        "Reflect on the spiritual aspects of scientific breakthroughs",
        "Encourage gratitude and reflection in the pursuit of understanding",
        "Highlight the intersection of faith and exploration in scientific endeavors",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "66270027fa537936aa2aee4e",
      Quote:
        "Our society is so fragmented, our family lives so sundered by physical and emotional distance, our friendships so sporadic, our intimacies so 'in-between' things and often so utilitarian, that there are few places where we can feel truly safe.",
      Author: "Henri J.M. Nouwen",
      Paraphrase:
        "In our fragmented society, with distant families and sporadic friendships, finding true safety is rare due to utilitarian and in-between intimacies.",
      Commentary:
        "This quote poignantly captures the modern struggle for genuine connection and safety in a society marked by fragmentation and distance. It reflects the longing for deeper, more meaningful relationships and the challenge of finding true intimacy in a utilitarian world.",
      Poem: "In a world so fragmented,\n where distance takes its toll,\n finding true safety becomes the goal. Intimacies sporadic,\n friendships so few,\n seeking safe places,\n a rare rendezvous.",
      joke: "What inspired the fragmented society to seek safety? A utilitarian friend who said, 'I'm only here in-between things!'",
      Riddle:
        "I am the elusive feeling of true safety, sought in a world of fragmentation and distance. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "fragmentation",
        "distance",
        "safety",
        "intimacy",
        "society",
        "family",
        "friendship",
        "utilitarian",
        "emotional",
        "sporadic",
      ],
      Theme: [
        "emotional distance",
        "fragmented society",
        "utilitarian intimacies",
        "sundered family lives",
        "sporadic friendships",
        "physical distance",
        "safe places",
        "emotional fragmentation",
        "intimacies in-between",
        "utilitarian relationships",
      ],
      Questions: [
        "How does fragmentation impact our ability to feel safe and connected?",
        "What steps can be taken to bridge emotional and physical distance in relationships?",
        "In what ways do utilitarian attitudes hinder the development of deep, meaningful connections?",
        "What role does sporadic intimacy play in our overall sense of safety and belonging?",
        "How can we create safe spaces for genuine intimacy in our fragmented society?",
        "How does the concept of 'utilitarian intimacies' resonate with modern relationships?",
        "In what ways can emotional and physical distance impact our sense of safety?",
      ],
      verses: [
        "Psalm 133:1",
        "Ephesians 4:3",
        "1 Peter 4:8",
        "Proverbs 18:24",
        "John 15:13",
      ],
      Suggestions: [
        "Explore the impact of fragmentation on relationships and intimacy",
        "Discuss biblical examples of deep, meaningful connections",
        "Examine the concept of safety and belonging in the context of utilitarian attitudes",
        "Encourage fostering genuine connections in a fragmented society",
        "Reflect on creating safe spaces for intimacy and belonging",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe98fa537936aa24aea7",
      Quote:
        "In marriage it is never having my own way. It is rather discovering our way.",
      Author: "Gary Chapman",
      Paraphrase: "Marriage means finding our way, not insisting on my way.",
      Commentary:
        "This quote encapsulates the essence of partnership and compromise in marriage. It emphasizes the importance of mutual understanding and joint decision-making, highlighting that a successful marriage is built on collaboration and unity.",
      Poem: "In marriage,\n we find our way,\n not just my say,\n but our sway,\n together we stay,\n through night and day,\n come what may,\n our love will never decay.",
      joke: "What inspired the husband to always let his wife have her way in their marriage? He realized that in marriage, it's not about having his own way, but discovering their way!",
      Riddle:
        "How can a successful marriage be described as never having my own way, but rather discovering our way?",
      "Biblical Worldview": "Yes",
      Topics: [
        "marriage",
        "compromise",
        "partnership",
        "communication",
        "unity",
        "understanding",
        "collaboration",
        "commitment",
        "relationship",
        "harmony",
      ],
      Theme: [
        "shared decision-making",
        "mutual understanding",
        "joint direction",
        "collective journey",
        "united pathfinding",
        "cooperative navigation",
        "together discovery",
        "shared navigation",
        "mutual exploration",
        "joint decision-making",
      ],
      Questions: [
        "How does this quote challenge traditional views of marriage?",
        "What are the implications of prioritizing 'our way' over 'my way' in a marriage?",
        "How can this quote be applied to other types of relationships beyond marriage?",
        "What role does communication play in 'discovering our way' in a marriage?",
        "In what ways can this quote inspire personal growth and selflessness in relationships?",
        "How does this quote redefine the concept of individuality within a marriage?",
        "What are the potential benefits of embracing 'our way' in a marriage?",
      ],
      verses: [
        "Ephesians 5:21",
        "Philippians 2:3",
        "1 Peter 3:7",
        "Colossians 3:14",
        "1 Corinthians 13:4-7",
      ],
      Suggestions: [
        "Discuss the importance of compromise and unity in marriage",
        "Explore the biblical principles of selflessness and mutual respect in relationships",
        "Illustrate the concept of partnership through real-life examples and anecdotes",
        "Encourage open dialogue and active listening within marital relationships",
        "Emphasizing the significance of joint decision-making and shared goals in marriage",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626feacfa537936aa24f92a",
      Quote: "What punishments of God are not gifts?",
      Author: "J. R. R. Tolkien",
      Paraphrase: "What trials from God are not blessings?",
      Commentary:
        "Tolkien's quote encapsulates the paradoxical nature of faith, highlighting the transformative power of trials and suffering. It challenges us to perceive difficulties as opportunities for growth and blessings in disguise, echoing the biblical concept of finding joy in tribulations.",
      Poem: "In trials we find gifts divine,\n A paradox of God's design. Punishments may blessings be,\n A mercy in adversity.",
      joke: "A man inspired by the message of this quote walked into a bakery and asked, 'Do you have any punishment that's actually a gift?' The baker replied, 'Sure, here's a free sample of our guilt-free chocolate cake!'",
      Riddle:
        "I bring both pain and grace, A paradox in every case. What am I?",
      "Biblical Worldview": "Yes",
      Topics: [
        "Gifts",
        "Punishments",
        "God",
        "Faith",
        "Suffering",
        "Blessings",
        "Grace",
        "Divine",
        "Paradox",
        "Mercy",
      ],
      Theme: [
        "Divine paradox",
        "Suffering as grace",
        "God's gifts in trials",
        "Faith in hardships",
        "Mercy in suffering",
        "Blessings in disguise",
        "Paradox of God's justice",
        "Grace in affliction",
        "Divine mercy in trials",
        "Punishments as blessings",
      ],
      Questions: [
        "How can suffering be viewed as a gift?",
        "What does this quote reveal about the nature of divine justice?",
        "In what ways can hardships lead to blessings?",
        "How does this quote challenge traditional views of punishment and reward?",
        "What role does faith play in perceiving trials as gifts?",
        "How does this quote challenge conventional views of suffering?",
        "What biblical stories illustrate the concept of trials as gifts?",
      ],
      verses: [
        "Romans 5:3-5",
        "James 1:2-4",
        "2 Corinthians 12:9",
        "1 Peter 4:12-13",
        "Hebrews 12:6",
      ],
      Suggestions: [
        "Explore the paradox of divine justice and suffering",
        "Illustrate the concept of trials as gifts through biblical narratives",
        "Encourage a shift in perspective regarding hardships and blessings",
        "Discuss the role of faith in perceiving trials as gifts",
        "Highlight the transformative power of God's grace in adversity",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626fe70fa537936aa241418",
      Quote:
        "The biggest obstacle to making Christ magnificent is the refusal to make yourself small.",
      Author: "James MacDonald",
      Paraphrase:
        "The refusal to diminish oneself is the greatest hindrance to exalting Christ.",
      Commentary:
        "This quote emphasizes the importance of humility and selflessness in exalting Christ. It challenges us to overcome the obstacle of pride and embrace the virtue of making ourselves small in order to magnify the greatness of Christ. By recognizing our own smallness, we can truly elevate the magnificence of Christ in our lives.",
      Poem: "To make Christ great,\n we must be small,\n Refusing pride,\n embracing the call. In selflessness,\n we find the key,\n To magnify His majesty. Overcoming obstacles,\n we stand tall,\n Making Christ magnificent,\n above all.",
      joke: "I hate when people refuse to be small, it's like they're allergic to humility!",
      Riddle:
        "What is the obstacle to making Christ magnificent, yet the key to exalting His glory?",
      "Biblical Worldview": "Yes",
      Topics: [
        "Christ",
        "magnificent",
        "obstacle",
        "refusal",
        "small",
        "faith",
        "humility",
        "pride",
        "selflessness",
        "glory",
      ],
      Theme: [
        "Making Christ Magnificent",
        "Refusing to be Small",
        "Overcoming Obstacles",
        "Embracing Humility",
        "Magnifying Christ",
        "Selflessness and Faith",
        "Pride and Refusal",
        "The Glory of Christ",
        "Smallness and Magnificence",
        "Obstacles to Glory",
      ],
      Questions: [
        "How does pride hinder the magnification of Christ?",
        "What does it mean to make oneself small in the context of faith?",
        "How can we overcome the obstacle of refusing to be small?",
        "In what ways can selflessness lead to magnifying Christ?",
        "What role does humility play in making Christ magnificent?",
        "How does this quote challenge the concept of self-importance?",
        "What are some practical ways to make oneself small in the context of faith?",
      ],
      verses: [
        "Philippians 2:3",
        "James 4:10",
        "1 Peter 5:6",
        "Matthew 23:12",
        "Proverbs 11:2",
      ],
      Suggestions: [
        "Preach on the virtue of humility and its role in magnifying Christ",
        "Discuss the obstacles of pride and self-importance in the Christian journey",
        "Examine biblical examples of individuals who made themselves small to exalt God",
        "Explore the concept of selflessness and its significance in glorifying Christ",
        "Encourage the congregation to reflect on ways to overcome the refusal to be small in order to magnify Christ",
      ],
      isLiked: false,
      likes: 0,
    },
  },
  {
    message: "Successfully Get",
    data: {
      _id: "6626ff04fa537936aa26541b",
      Quote:
        "A true knowledge of Jesus is our greatest need and our greatest happiness.",
      Author: "John Eldredge",
      Paraphrase:
        "Our greatest and most essential joy comes from truly understanding Jesus.",
      Commentary:
        "This quote emphasizes the profound significance of knowing Jesus, highlighting that true knowledge of Him is not only essential but also brings the greatest happiness. It underscores the transformative power of a deep, personal relationship with Jesus, offering fulfillment and joy beyond measure.",
      Poem: "In Jesus we find our greatest delight,\n His knowledge brings our souls alight. With Him we find our hearts' true song,\n In His truth,\n we truly belong.",
      joke: "Did you hear that seeking Jesus brings the greatest happiness? I guess that means I should stop looking for joy in my chocolate stash!",
      Riddle:
        "How can we find our greatest need and happiness? Seek the one who offers truth and joy, the answer lies in Him.",
      "Biblical Worldview": "Yes",
      Topics: [
        "knowledge",
        "Jesus",
        "happiness",
        "faith",
        "truth",
        "salvation",
        "grace",
        "redemption",
        "spirituality",
        "fulfillment",
      ],
      Theme: [
        "True Knowledge of Jesus",
        "Greatest Happiness in Jesus",
        "Seeking Jesus for Happiness",
        "Knowing Jesus Brings Joy",
        "Jesus: Our Greatest Need",
        "Finding Happiness in Jesus",
        "Jesus: Ultimate Knowledge",
        "Faith in Jesus' Knowledge",
        "Jesus' Joyful Revelation",
        "Jesus: Source of Happiness",
      ],
      Questions: [
        "How does knowing Jesus fulfill our greatest need?",
        "What does it mean to seek true knowledge of Jesus?",
        "In what ways does Jesus bring ultimate happiness?",
        "How can we deepen our understanding of Jesus?",
        "What role does Jesus play in our pursuit of happiness?",
        "How does knowing Jesus fulfill our greatest need?",
        "What does it mean to seek true knowledge of Jesus?",
      ],
      verses: [
        "John 17:3",
        "Philippians 3:8",
        "Colossians 2:2-3",
        "Psalm 16:11",
        "Matthew 6:33",
      ],
      Suggestions: [
        "Preach on the transformative power of knowing Jesus",
        "Discuss the joy found in a personal relationship with Jesus",
        "Explore the concept of Jesus as our greatest need",
        "Examine the happiness derived from true knowledge of Jesus",
        "Encourage seeking fulfillment through a deeper understanding of Jesus",
      ],
      isLiked: false,
      likes: 0,
    },
  },
];
export { FEATURED_QUOTES_DATA };
