import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getQuotes } from "../services/QuotesService";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import QuoteCard from "../component/common/QuoteCard/QuoteCard";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import { asyncStatus, searchBy } from "../store/constants";
import {
  setQuotesBySearchStatus,
  setSearchByKey,
  setSearchDataState,
  setSearchState,
} from "../store/slices/quoteSlice";
import { FaSearch } from "react-icons/fa";
import Input from "../component/common/Input/Input";
import { themeGray } from "../utils/colorTheme";
import { FEATURED_QUOTES_DATA } from "../utils/FeaturedQuotesData";

const FeaturedQuotes = () => {
  const { getQuotesData, getQuotesStatus, getQuotesError, searchByKey } =
    useSelector((state) => state.quotes);

  const [data, setData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const quoteLoading =
    getQuotesStatus === asyncStatus.LOADING && currentPage < 2;
  const quotePaginationLoading =
    getQuotesStatus === asyncStatus.LOADING && currentPage >= 2;

  useEffect(() => {
    if (getQuotesStatus == asyncStatus.IDLE) {
      dispatch(getQuotes(currentPage));
    }
  }, []);

  const navigateHandler = (_id, index) => {
    localStorage.setItem("currentImgIndex", index);
    navigate(`/product/${_id}`);
  };

  const search = (e) => {
    e.preventDefault();
    let firstLetterCapital =
      searchByKey?.toLowerCase()?.charAt(0).toUpperCase() +
      searchByKey?.toLowerCase()?.slice(1);

    if (firstLetterCapital === searchBy.ALL) {
      let obj = {
        query: searchBy.ALL,
        data,
        // bothOrEither,
      };
      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
    } else if (firstLetterCapital === searchBy.AUTHOR) {
      let obj = {
        query: searchBy.AUTHOR,
        data,
      };
      console.log("--->data", obj);
      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/authors");
    } else if (firstLetterCapital === searchBy.TOPIC) {
      let obj = {
        query: searchBy.TOPIC,
        data,
      };

      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/topics");
    }
  };

  return (
    <>
      <Navbar />

      <Grid container>
        {quoteLoading ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack p={2} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Grid>
        ) : getQuotesError ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack p={2} justifyContent={"center"} alignItems={"center"}>
              <Typography>
                Something went wrong please try again{" "}
                <Button onClick={dispatch(getQuotes(1))}>Reload</Button>
              </Typography>
            </Stack>
          </Grid>
        ) : (
          getQuotesData &&
          getQuotesData.length &&
          FEATURED_QUOTES_DATA.map(
            (e, i) =>
              i > 3 && (
                <Grid item xs={12} p={2} sm={6} md={6} lg={6} xl={6} key={i}>
                  <QuoteCard
                    onClick={() => navigateHandler(e.data._id, i)}
                    data={e.data}
                    img={quoteBackgroundImgList[i]}
                  />
                  <Divider />
                </Grid>
              )
          )
        )}
      </Grid>
      <form onSubmit={search}>
        <Stack
          sx={{
            display: "flex",

            // justifyContent: "center",
            alignItems: "center",
            gap: 2,
            my: 2,
            maxWidth: 700,
            mx: "auto",
          }}
        >
          <Stack width={"100%"} position={"relative"}>
            <Input
              placeholder={"Search Quotes..."}
              style={{
                width: "100%",
                borderRadius: 45,
                outlined: "none",
                border: "none",
                backgroundColor: themeGray,
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
              onChange={(e) => setData(e.target.value)}
            />
            <Button
              sx={{
                position: "absolute",
                top: "30%",
                right: 0,
                cursor: "pointer",
                p: 0,
                ":hover": {
                  background: "none",
                },
              }}
              type="submit"
            >
              <FaSearch size={20} color={"#686565"} />
            </Button>
          </Stack>
          <Stack
            p={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 0 }}
            component={"form"}
            width={"100%"}
            sx={{ backgroundColor: "white", border: "1px solid black" }}
            borderRadius={5}
            marginY={1}
            direction={"row"}
            mb={4}
            justifyContent={"center"}
          >
            <RadioGroup
              row
              aria-label="searchBy"
              name="searchBy"
              value={
                searchByKey
                  ? searchByKey?.charAt(0).toUpperCase() + searchByKey?.slice(1)
                  : "TOPIC"
              }
              defaultValue={searchBy.TOPIC.toLocaleUpperCase()}
              onChange={(e) => {
                dispatch(setSearchByKey(e.target.value));
                console.log(e.target.value);
              }}
            >
              {Object.keys(searchBy).map((e) => {
                return (
                  <Stack
                    direction={"row"}
                    style={{ cursor: "pointer" }}
                    alignItems={"center"}
                  >
                    <Radio
                      value={e.charAt(0).toUpperCase() + e.slice(1)}
                      label="Soft"
                      variant="soft"
                    />
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {/* {e.charAt(0).toUpperCase() + e.slice(1)} */}
                      {e.toLowerCase()}
                    </Typography>
                  </Stack>
                );
              })}
            </RadioGroup>
          </Stack>
        </Stack>
      </form>
      <Stack width={1} alignItems={"center"} my={4}>
        {quotePaginationLoading && (
          <Stack p={2} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {getQuotesStatus === asyncStatus.SUCCEEDED &&
          !quotePaginationLoading && (
            <Button
              variant="outlined"
              onClick={() => navigate("/get-all-quotes")}
            >
              Load More
            </Button>
          )}
      </Stack>

      <Footer />
    </>
  );
};

export default FeaturedQuotes;
