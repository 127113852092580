import React, { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Modal } from "./ShareModal";
import CustomModal from "../component/Modal/CustomModal";
import { FaFacebook } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaCopy } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdSms } from "react-icons/md";
import { Grid, Stack, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import { IoIosShareAlt } from "react-icons/io";
import { MdDownload } from "react-icons/md";
import { SlLink } from "react-icons/sl";
import { FaSms } from "react-icons/fa";
import { SlCloudDownload } from "react-icons/sl";
import { FaWhatsapp } from "react-icons/fa";
import email from "../assets/email.png"



const ShareButtons = ({ url, title, onClickDownload,sharing }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleShare = () => {
    const instagramURL = `https://www.instagram.com/share?url=${url}&title=${"Post meme to your page"}`;
    window.open(instagramURL, "_blank");
  };
  const shareViaSMS = () => {
    // Current page URL retrieve karein
    const currentURL = url;

    // SMS body tayyar karein
    const smsBody = `Check out this link: ${currentURL}`;

    // SMS bhejne ke liye anchor element ka istemal karein
    const smsLink = `sms:?&body=${encodeURIComponent(smsBody)}`;

    // SMS link ko open karein
    window.open(smsLink);
  };
  const copyURLToClipboard = () => {
    // URL ko clipboard mein copy karein
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
        setIsModalOpen(!isModalOpen);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
        alert("Failed to copy URL to clipboard.");
      });
  };
  return (
    <div>
      {/* <button onClick={toggleModal}>Share</button> */}
      <Button
        onClick={toggleModal}
        variant="outlined"
        color="error"
        endIcon={<IoIosShareAlt color="red" />}
      >
        Share
      </Button>
      {isModalOpen && (
        <CustomModal open={isModalOpen} handleClose={toggleModal}>
          <Stack direction={"row"} alignItems="center" gap={{ sm: 2, xs: 1 }}>
            <FacebookShareButton onClick={sharing} url={url} quote={"Post meme to your feed"}>
              <FaFacebook size={30} />
            </FacebookShareButton>
            <TwitterShareButton onClick={sharing} url={url} title={"Tweet the meme"}>
              <FaSquareXTwitter size={30} />
            </TwitterShareButton>
            <EmailShareButton onClick={sharing} url={url} title={"Email meme"}>
              {/* <MdEmail size={30} /> */}
              <img width={"38px"} src={email}/>
            </EmailShareButton>
            <WhatsappShareButton onClick={sharing} url={url} title={"Send meme via WhatsApp"}>
              <FaWhatsapp size={30} />
            </WhatsappShareButton>
            <button
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleShare}
            >
              <AiFillInstagram size={30} />
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={shareViaSMS}
            >
              <FaSms size={30} />
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={copyURLToClipboard}
            >
              <SlLink size={30} />
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={onClickDownload}
            >
              <SlCloudDownload size={30} />
            </button>
          </Stack>
        </CustomModal>
      )}
    </div>
  );
};

export default ShareButtons;
