import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import backImg from "../assets/quoteImg.jpg";
import QuoteCard from "../component/common/QuoteCard/QuoteCard";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getQuoteByAuthorName } from "../services/QuotesService";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import { asyncStatus } from "../store/constants";
import { themeDarkBlue } from "../utils/colorTheme";
import { CircularProgress } from "@material-ui/core";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
function QuoteByAuthor() {
  const navigate = useNavigate();
  const disptach = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const { name } = params;

  const {
    authorQuotesData,
    authorQuotesStatus,
    authorQuotesError,
    authorQuotesTotalPage,
  } = useSelector((state) => state.quotes);
  const loading = authorQuotesStatus === asyncStatus.LOADING;

  const navigateHandler = (_id, index) => {
    localStorage.setItem("currentImgIndex", index);
    navigate(`/product/${_id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    disptach(getQuoteByAuthorName({ authorName: name, page: currentPage }));
  }, [currentPage]);

  // console.log(authorQuotesData)

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
    // disptach(getQuoteByAuthorName(name, currentPage));
  };
  const prePage = () => {
    setCurrentPage((prev) => prev - 1);
    // disptach(getQuoteByAuthorName(name, currentPage));
  };

  if (authorQuotesStatus === asyncStatus.LOADING) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"}>
        <CircularProgress size={50} color={themeDarkBlue} />
      </Stack>
    );
  }
  console.log(authorQuotesTotalPage, currentPage);
  if (authorQuotesStatus === asyncStatus.SUCCEEDED) {
    return (
      <>
        <Navbar />
        <Container width={"100%"}>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
              Quotes: {authorQuotesData[0]?.Author}
            </Typography>
            {/* <Divider sx={{height:"2px"}}/> */}
          </Stack>
          {/* <Typography sx={{ fontSize: 16 }}>
            Share our pictorial quotes on Facebook, Twitter, and Instagram.
          </Typography> */}
          <Grid container>
            {loading ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack p={2} justifyContent={"center"} alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              </Grid>
            ) : authorQuotesError ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack p={2} justifyContent={"center"} alignItems={"center"}>
                  <Typography>
                    Something went wrong please try again{" "}
                    <Button
                      onClick={disptach(
                        getQuoteByAuthorName({
                          authorName: "Katherine Heigl",
                          page: 1,
                        })
                      )}
                    >
                      Reload
                    </Button>
                  </Typography>
                </Stack>
              </Grid>
            ) : authorQuotesData && authorQuotesData.length ? (
              authorQuotesData?.map(
                (e, i) =>
                  i < 6 && (
                    <Grid
                      item
                      xs={12}
                      p={2}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={i}
                    >
                      <QuoteCard
                        onClick={() => navigateHandler(e._id, i)}
                        data={e}
                        img={quoteBackgroundImgList[i]}
                      />
                      <Divider />
                    </Grid>
                  )
              )
            ) : (
              <Typography
                align="center"
                fontWeight={"bold"}
                fontSize={"1.4rem"}
                my={1}
              >
                No Quotes
              </Typography>
            )}
          </Grid>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                color: themeDarkBlue,
                fontWeight: "bold",
              }}
              disabled={currentPage === 1}
              onClick={
                //   () => {
                //   setCurrentPage((prev) => prev - 1);
                // }
                prePage
              }
            >
              <FaArrowAltCircleLeft size={24} />
            </Button>
            <Typography>{currentPage}</Typography>
            <Button
              onClick={
                //   () => {
                //   setCurrentPage((prev) => prev + 1);
                // }
                nextPage
              }
              sx={{
                color: themeDarkBlue,
                fontWeight: "bold",
              }}
              disabled={currentPage === authorQuotesTotalPage}
            >
              <FaArrowAltCircleRight size={24} />
            </Button>
          </Stack>
        </Container>
        <Footer />
      </>
    );
  }
}

export default QuoteByAuthor;
