export const authorName = [
    {
        name: "William Shakespeare",
        nationality: "English",
        notableWorks: ["Romeo and Juliet", "Hamlet", "Macbeth"],
        genre: "Drama"
    },
    {
        name: "Anonymous",
        nationality: "Various",
        notableWorks: ["Beowulf", "The Epic of Gilgamesh"],
        genre: "Various"
    },
    {
        name: "Oscar Wilde",
        nationality: "Irish",
        notableWorks: ["The Picture of Dorian Gray", "The Importance of Being Earnest"],
        genre: "Drama, Fiction"
    },
    {
        name: "Ralph Waldo Emerson",
        nationality: "American",
        notableWorks: ["Self-Reliance", "Nature"],
        genre: "Essays"
    },
    {
        name: "Mark Twain",
        nationality: "American",
        notableWorks: ["The Adventures of Huckleberry Finn", "The Adventures of Tom Sawyer"],
        genre: "Fiction, Satire"
    },
    {
        name: "Henry David Thoreau",
        nationality: "American",
        notableWorks: ["Walden", "Civil Disobedience"],
        genre: "Essays"
    },
    {
        name: "Chuck Palahniuk",
        nationality: "American",
        notableWorks: ["Fight Club", "Choke"],
        genre: "Fiction"
    },
    {
        name: "Stephen King",
        nationality: "American",
        notableWorks: ["The Shining", "It", "The Dark Tower series"],
        genre: "Horror, Fantasy"
    },
    {
        name: "Victor Hugo",
        nationality: "French",
        notableWorks: ["Les Misérables", "The Hunchback of Notre-Dame"],
        genre: "Novels, Poetry"
    },
    {
        name: "Rick Riordan",
        nationality: "American",
        notableWorks: ["Percy Jackson & the Olympians series", "The Kane Chronicles"],
        genre: "Fantasy, Young Adult"
    },
    {
        name: "Terry Pratchett",
        nationality: "English",
        notableWorks: ["Discworld series", "Good Omens (with Neil Gaiman)"],
        genre: "Fantasy, Satire"
    },
    {
        name: "Margaret Atwood",
        nationality: "Canadian",
        notableWorks: ["The Handmaid's Tale", "Alias Grace"],
        genre: "Fiction, Speculative Fiction"
    },
    {
        name: "Jodi Picoult",
        nationality: "American",
        notableWorks: ["My Sister's Keeper", "The Pact"],
        genre: "Fiction"
    },
    {
        name: "Cassandra Clare",
        nationality: "American",
        notableWorks: ["The Mortal Instruments series", "The Infernal Devices series"],
        genre: "Young Adult, Fantasy"
    }
]