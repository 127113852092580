export const type_constant = {
  PROVIDER: "provider",
  APPLICANT: "applicant",
};

export const asyncStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  ERROR: "error",
};
export const searchBy = {
  TOPIC: "Topic",
  AUTHOR: "Author",
  ALL: "All",

};

export const api_error_messages_to_exit = [
  "Token is not valid",
  "Refresh or Access Token is not valid",
  "Token is Expired",
  "Invalid token",
];
export const session_expired = "@session_expired";

export const save_tokens_constant = "@usertokens";

export const signup_methods_constants = {
  EMAIL_PASSWORD: "email_password",
  GOOGLE: "google",
  FACEBOOK: "facebook",
  APPLE: "apple",
};
