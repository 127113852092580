import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import CustomBreadcrumbs from "../../component/common/Breadcrumbs/Breadcrumbs";
import QuoteDetailCard from "../../component/QuoteDetailCard/QuoteDetailCard";
import QuoteCard from "../../component/common/QuoteCard/QuoteCard";
import backImg from "../../assets/quoteImg.jpg";
import { quoteBackgroundImgList } from "../../utils/quoteBackgroundImgList";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getQuoteByID } from "../../services/QuotesService";
import { asyncStatus } from "../../store/constants";
import { themeDarkBlue } from "../../utils/colorTheme";
import { CircularProgress } from "@material-ui/core";
import Footer from "../../component/Footer/Footer";

// const quoteMoreDetails = [
//   {
//     title: "Commentary",
//     textList: [
//       "Katherine Heigl’s quote reflects the pervasive struggle many individuals face in today’s society, where unrealistic beauty standards and societal pressures often lead to perpetual dissatisfaction with one’s appearance. This quote highlights the importance of self-acceptance and the detrimental effects of constant self-criticism.",
//     ],
//   },
//   {
//     title: "Paraphrase",
//     textList: ["I’m always unhappy with my appearance."],
//   },
//   {
//     title: "Poem",
//     textList: [
//       "In the mirror,",
//       "I see a flaw,",
//       "never content with what I saw. Seeking perfection,",
//       "a constant chore,",
//       "beauty’s standard,",
//       "an endless war.",
//     ],
//   },
//   {
//     title: "Joke",
//     textList: [
//       "How does a perfectionist change a light bulb? They can’t – they’re never satisfied with the way the light shines on them!",
//     ],
//   },
//   {
//     title: "Riddle",
//     textList: ["How can one be forever discontent with their reflection?"],
//   },
//   {
//     title: "Preaching Suggestions",
//     textList: [
//       "Addressing societal beauty standards and their impact on self-image",
//       "Encourage self-acceptance and self-love",
//       "Discuss the dangers of perfectionism and constant self-criticism",
//       "Explore biblical perspectives on inner beauty and self-worth",
//       "Offer guidance on cultivating a healthy self-image in a beauty-focused world",
//     ],
//   },
//   {
//     title: "Related Verses",
//     textList: ["Psalm 139:14, Samuel 16:7, Proverbs 31:30, Peter 3:3-4"],
//   },
//   {
//     title: "Questions for Reflection and Discussion",
//     textList: [
//       "How does societal pressure contribute to our dissatisfaction with our appearance?",
//       "How can we cultivate a healthy self-image in a world focused on external beauty?",
//       "How does constant self-criticism affect our overall well-being?",
//       "How can we support others who struggle with dissatisfaction with their appearance?",
//       "How can we shift societal beauty standards to promote self-acceptance?",
//       "Why do many people struggle with being satisfied with their appearance?",
//       "How does constant self-criticism impact an individual’s mental and emotional well-being?",
//     ],
//   },
// ];

// const cardData = [
//   {
//     img: backImg,
//     text: "I’m never satisfied with the way I look.",
//     rating: 0,
//   },
//   {
//     img: backImg,
//     text: "I’m never satisfied with the way I look.",
//     rating: 0,
//   },
//   {
//     img: backImg,
//     text: "I’m never satisfied with the way I look.",
//     rating: 0,
//   },
// ];

const QuoteDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const { quotesByIdData, quotesByIdStatus, quotesByIdError } = useSelector(
    (state) => state.quotes
  );
  const { userAuth, userData } = useSelector((state) => state.userAuth);

  // getQuoteByID(id)

  useEffect(() => {
    let obj = {
      id: id,
      userId: userAuth ? userData?._id : "",
    };
    dispatch(getQuoteByID(obj));
  }, []);
  console.log(quotesByIdData?.poem);

  if (quotesByIdStatus === asyncStatus.LOADING) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"}>
        <CircularProgress size={50} color={themeDarkBlue} />
      </Stack>
    );
  }
  if (quotesByIdStatus === asyncStatus.SUCCEEDED) {
    return (
      <Stack>
        <Navbar />

        {/* <CustomBreadcrumbs /> */}

        <Container
          sx={{
            my: 8,
          }}
        >
          <QuoteDetailCard />
        </Container>
        <Stack spacing={2} p={2}>
          <Stack
            spacing={1}
            sx={{
              border: `2px solid lightgray`,
              borderRadius: 4,
            }}
          >
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Commentary
              </Typography>
              <Typography color={`gray`}>
                {quotesByIdData?.Commentary}
              </Typography>
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Paraphrase
              </Typography>
              <Typography color={`gray`}>
                {quotesByIdData?.Paraphrase}
              </Typography>
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Poem
              </Typography>
              {quotesByIdData?.Poem?.split(",").map((e, i) => (
                <Typography lineHeight={0.3} color={`gray`}>
                  {e}
                  {quotesByIdData?.Poem?.split(",").length - 1 === i ? "" : ","}
                </Typography>
              ))}
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Joke
              </Typography>
              <Typography color={`gray`}>{quotesByIdData?.joke}</Typography>
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Riddle
              </Typography>
              <Typography color={`gray`}>{quotesByIdData?.Riddle}</Typography>
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Preaching Suggestions
              </Typography>
              {/* <Typography color={`gray`} >
                {quotesByIdData?.Suggestions}
              </Typography> */}
              {quotesByIdData?.Suggestions?.map((e) => {
                return <li color={`gray`}>{e}</li>;
              })}
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Related Verses
              </Typography>
              <Typography color={`gray`} sx={{ textDecoration: "underline" }}>
                {/* {quotesByIdData?.verses} */}
                {quotesByIdData?.verses?.map((e) => {
                  return (
                    <Typography
                      onClick={() => navigate(`/verse/${e}`)}
                      sx={{ cursor: "pointer" }}
                      color={themeDarkBlue}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Typography>
            </Stack>
            <Stack spacing={2} p={2}>
              <Typography color={`black`} fontWeight={"bold"}>
                Questions for Reflection and Discussion
              </Typography>
              {quotesByIdData?.Questions?.map((e) => {
                return <Typography color={`gray`}>{e}</Typography>;
              })}
            </Stack>
          </Stack>

          {/* <Divider
            sx={{
              mt: 1,
              color: "black",
              "::after": {
                borderTop: `1px solid black`,
              },
              "::before": {
                borderTop: `1px solid black`,
              },
            }}
          >
            <Typography fontSize={`33px`}>Related Quotes</Typography>
          </Divider>

          <Grid container>
            {cardData.map((e, i) => (
              <Grid item xs={12} p={2} sm={6} md={4} lg={4} xl={4} key={i}>
                <QuoteCard data={e} img={quoteBackgroundImgList[i]} />
              </Grid>
            ))}
          </Grid> */}
        </Stack>
        <Footer />
      </Stack>
    );
  }
};

export default QuoteDetail;
