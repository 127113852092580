import AllQuotes from "../pages/AllQuotes";
import Authors from "../pages/Authors";
import Bible from "../pages/Bible";
import ContactUs from "../pages/ContactUs";
import QuoteDetail from "../pages/DetailView/QuoteDetail";
import FeaturedQuotes from "../pages/FeaturedQuotes";
import Home from "../pages/Home";
import MakeYourOwn from "../pages/MakeYourOwn";
import Privacy_Policy from "../pages/Privacy_Policy";
import QuoteByAuthor from "../pages/QuoteByAuthor";
import QuoteByTopic from "../pages/QuoteByTopic";
import SearchData from "../pages/SearchData";
import SelectBGPic from "../pages/SelectBGPic";
import Terms_Condition from "../pages/Terms_Condition";
import Topics from "../pages/Topics";
import Verse from "../pages/Verse";
import ViewQuote from "../pages/ViewQuote";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";

export const mainRoutes = [
  {
    caption: "Home",
    linkTo: "/",
    icon: "",
    element: <Home />,
    authRequired: false,
  },
  {
    caption: "Authors",
    linkTo: "/authors",
    icon: "",
    element: <Authors />,
    authRequired: false,
  },
  {
    caption: "MakeYourQuote",
    linkTo: "/make-your-own",
    icon: "",
    element: <MakeYourOwn />,
    authRequired: false,
  },
  {
    caption: "Search",
    linkTo: "/search",
    icon: "",
    element: <SearchData />,
    authRequired: false,
  },
  {
    caption: "Quotes",
    linkTo: "/get-all-quotes",
    icon: "",
    element: <AllQuotes />,
    authRequired: false,
  },
  {
    caption: "featured",
    linkTo: "/featured-quotes",
    icon: "",
    element: <FeaturedQuotes />,
    authRequired: false,
  },
  {
    caption: "Topics",
    linkTo: "/topics",
    icon: "",
    element: <Topics />,
    authRequired: false,
  },
  {
    caption: "Quote",
    linkTo: "/product/:id",
    // icon: "",
    element: <QuoteDetail />,
    authRequired: false,
  },
  {
    caption: "Verse",
    linkTo: "/verse/:id",
    // icon: "",
    element: <Verse />,
    authRequired: false,
  },
  {
    caption: "QuoteByAuthor",
    linkTo: "/quote-by-author/:name",
    // icon: "",
    element: <QuoteByAuthor />,
    authRequired: false,
  },
  {
    caption: "QuoteByTopic",
    linkTo: "/quote-by-topic/:name",
    // icon: "",
    element: <QuoteByTopic />,
    authRequired: false,
  },
  {
    caption: "SetBGOnQuote",
    linkTo: "/set-bg-quote/:id",
    // icon: "",
    element: <SelectBGPic />,
    authRequired: false,
  },
  {
    caption: "ViewQuote",
    linkTo: "/view-quote",
    // icon: "",
    element: <ViewQuote />,
    authRequired: false,
  },
  {
    caption: "Bible",
    linkTo: "/bible",
    // icon: "",
    element: <Bible />,
    authRequired: false,
  },
  {
    caption: "Terms_Condition",
    linkTo: "/Terms_Condition",
    // icon: "",
    element: <Terms_Condition />,
    authRequired: false,
  },
  {
    caption: "Privacy_Policy",
    linkTo: "/Privacy_Policy",
    // icon: "",
    element: <Privacy_Policy />,
    authRequired: false,
  },
  {
    caption: "Contact-us",
    linkTo: "/Contact-us",
    // icon: "",
    element: <ContactUs />,
    authRequired: false,
  },
  // {
  //   caption: "Login",
  //   linkTo: "/login",
  //   icon: "",
  //   element: <Login />,
  //   authRequired: false,
  // },
  // {
  //   caption: "Signup",
  //   linkTo: "/signup",
  //   icon: "",
  //   element: <SignUp />,
  //   authRequired: false,
  // },
];
