import { Button, Stack, Typography } from "@mui/material";
import "./CustomSelect.css";
import { FaArrowLeft } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  get_inner_single_chapter_verses,
  get_main_chapters,
  get_verse_inner_chapters,
} from "../../services/QuotesService";
import { asyncStatus } from "../../store/constants";

const DATA = [
  {
    book_name: "Joshua",
    chapters: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24,
    ],
  },
  {
    book_name: "Leviticus",
    chapters: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27,
    ],
  },
  {
    book_name: "Ruth",
    chapters: [1, 2, 3, 4],
  },
  {
    book_name: "Nahemiah",
    chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  },
  {
    book_name: "Ezra",
    chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    book_name: "Nahemiah",
    chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  },
  {
    book_name: "Leviticus",
    chapters: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27,
    ],
  },
  {
    book_name: "Ruth",
    chapters: [1, 2, 3, 4],
  },
];

const CustomSelect = ({defaultCh}) => {
  const dispatch = useDispatch();

  const {
    chapterMainData,
    chapterMainStatus,
    chapterMainError,
    verseInnerChapterData,
    verseInnerChapterStatus,
    verseInnerChapterError,
    single_verses_Status,
    single_verses_Data,
    single_verses_Error,
  } = useSelector((state) => state.quotes);

  const [isselectOpen, setIsSelectOpen] = useState(false);
  const [bookSelected, setbookSelected] = useState(false);
  const [selectedBookIndex, setSelectedBookIndex] = useState();
  const [inputValue, setInputValue] = useState("");
  const [selectedBookValue, setSelectedBookValue] = useState("");
console.log("selectedBookValue",selectedBookValue);
  const bookClickHandler = (item, i) => {
    // console.log("item", item);
    setbookSelected((prev) => true);
    setSelectedBookIndex(i);
    setSelectedBookValue(item);
    dispatch(get_verse_inner_chapters({ chapter: item }));
  };

  const get_verse_handle = (item) => {
    setbookSelected(false);
    setIsSelectOpen(() => false);
    // console.log("item", item);
    dispatch(get_inner_single_chapter_verses(item));
  };

  useEffect(() => {
    dispatch(get_main_chapters());
    dispatch(get_verse_inner_chapters({ chapter: defaultCh?.split(" ")[0]||"Genesis" }));
    dispatch(get_inner_single_chapter_verses(defaultCh||"Genesis 1"));
    if(defaultCh){
      setSelectedBookValue(defaultCh?.split(" ")[0])
      const pos = chapterMainData?.data?.map(e => e.chapterName).indexOf(defaultCh?.split(" ")[0]);

      setSelectedBookIndex(pos)
    }
  }, []);

  // console.log("===============>>>",chapterMainData?.data[0].chapterName);
  // console.log("===============>>>",verseInnerChapterData);

  return (
    <Stack padding={1} maxWidth={450} minWidth={350} position={"relative"}>
      <Button
        sx={{
          display: "flex",
          justifyContent: "start",
          color: "black",
          justifyContent: "space-between",
          borderBottom: `2px solid #edebeb`,
          ":hover": {
            background: "#edebeb",
          },
        }}
        onClick={() => setIsSelectOpen((prev) => !prev)}
      >
        {selectedBookIndex
          ? // ? `${DATA[selectedBookIndex].book_name} ${DATA[selectedBookIndex].chapters[1]}`
            selectedBookValue
          : chapterMainData?.data[0]?.chapterName}
        <FaCaretDown />
      </Button>
      {isselectOpen && (
        <Stack
          width={1}
          position={"absolute"}
          zIndex={1010101}
          top={45}
          left={0}
        >
          <Stack
            bgcolor={"#edebeb"}
            p={1}
            sx={{
              borderRadius: `10px 10px 0 0 `,
            }}
          >
            <Stack flexDirection={"row"} alignItems={"center"}>
              {bookSelected && (
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    color: "black",
                    minWidth: 0,
                  }}
                  onClick={() => setbookSelected((prev) => false)}
                >
                  <FaArrowLeft />
                </Button>
              )}
              <Typography align="center" flexGrow={1}>
                {bookSelected ? "CHAPTER" : "BOOK"}
              </Typography>
              <Button onClick={() => setIsSelectOpen((prev) => !prev)}>
                CANCEL
              </Button>
            </Stack>
            {!bookSelected && (
              <Stack>
                <input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="_input"
                  placeholder="Filter Books.."
                />
              </Stack>
            )}
          </Stack>
          <Stack
            maxHeight={250}
            bgcolor={"white"}
            sx={{ overflowY: "scroll" }}
            flexDirection={bookSelected ? "row" : "column"}
            flexWrap={bookSelected ? "wrap" : "no-wrap"}
          >
            {!bookSelected
              ? // DATA.filter((e) =>
                //   e.book_name.toLowerCase().includes(inputValue.toLowerCase())
                // )
                chapterMainData?.data?.map((e, i) => {
                  return (
                    <Stack>
                      <Button
                        sx={{
                          width: 1,
                          display: "flex",
                          justifyContent: "start",
                          color: "black",
                        }}
                        key={i}
                        onClick={() => bookClickHandler(e?.chapterName, i)}
                      >
                        {e?.chapterName}
                      </Button>
                    </Stack>
                  );
                })
              : bookSelected &&
                verseInnerChapterData?.data?.map((e, i) => {
                  // console.log("verseInnerChapterData==========>>>>", e);

                  return (
                    <Stack width={"33%"}>
                      <Button
                        sx={{
                          width: 1,
                          display: "flex",
                          justifyContent: "start",
                          color: "black",
                          border: `1px solid #edebeb`,
                          borderRadius: 0,
                        }}
                        // key={i}
                        onClick={() => get_verse_handle(e.chapter)}
                      >
                        {e?._id}
                      </Button>
                    </Stack>
                  );
                })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomSelect;
