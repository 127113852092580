import { Container, Stack } from "@mui/system";
import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Checkbox, CircularProgress, Grid, Typography } from "@mui/material";
import { BsApple, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/servicehub4_720.png"
import { FcGoogle } from "react-icons/fc";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { themeOrange } from "../../utils/colorTheme";
import { useSelector } from "react-redux";
import { type_constant } from "../../store/constants";
import { useTranslation } from "react-i18next";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";

// import CircularProgress from '@mui/joy/CircularProgress';


const render_buttons = [
    {
        caption: common_caption_strings.continue_with_google,
        icon: <FcGoogle style={{}} size={30} />,
        on_change: "",
        background_style: { backgroundColor: "#fafafa", color: "black" },
    },
    {
        caption: common_caption_strings.continue_with_facebook,
        icon: <FacebookOutlinedIcon fontSize="large" />,
        on_change: "",
        background_style: { backgroundColor: "#3b5998", color: "white" },
    },
    {
        caption: common_caption_strings.continue_with_apple,
        icon: <BsApple size={30} />,
        on_change: "",
        background_style: { backgroundColor: "black", color: "white" },
    },
];


const SignUp = () => {
    const [showPass, setShowPass] = useState(true);
    // const [value, setValue] = useState(true)
    const [data, setData] = useState({});
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const { role } = useSelector((state) => state.userAuth);
    //   const { login_status } = useSelector((state) => state.auth);


    const { t, i18n } = useTranslation();



    const submit = () => {

        if (role === type_constant.PROVIDER) {
            navigate('/step1')
        } else {
            navigate("/")
        }
    }

    console.log(role)

    return (
        <Container maxWidth={false}>
            <Stack mt={2} justifyContent={"center"} alignItems={"center"}>
                <Stack
                    justifyContent={"flex-start"}
                    sx={{
                        maxWidth: "600px",
                        width: "100%",
                        boxShadow: "0px 0px 30px lightgray",
                        borderRadius: 5,
                        padding: 5,
                    }}
                    textAlign={"left"}
                    color={"black"}
                >
                    <Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <img
                            style={{ width: "100%", objectFit: "contain", maxWidth: "200px" }}
                            src={logo}
                            alt="logo"
                        />
                    </Box>


                    <Stack justifyContent={"center"} alignItems={"center"} spacing={3} mt={2} mb={2}>
                        <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                            <h1>{t(common_caption_strings.Signup)}</h1>
                            <Stack sx={{ borderBottom: "2px solid orange", width: 90 }}></Stack>
                        </Stack>
                        <Stack >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Input
                                        id={"firstName"}
                                        onChange={(e) =>
                                            setData({ ...data, [e.target.id]: e.target.value })
                                        }
                                        style={{ padding: "10px 10px 10px 15px", borderRadius: "6px", backgroundColor: "#EBEBEB", border: "none" }}
                                        placeholder={t(common_caption_strings.first_name)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <Input
                                        id={"name"}
                                        type={"text"}
                                        onChange={(e) =>
                                            setData({ ...data, [e.target.id]: e.target.value })
                                        }
                                        style={{ padding: "10px 10px 10px 15px", borderRadius: "6px", backgroundColor: "#EBEBEB", border: "none" }}
                                        placeholder={t(common_caption_strings.last_name)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Input
                                        id={"email"}
                                        type={"text"}
                                        onChange={(e) =>
                                            setData({ ...data, [e.target.id]: e.target.value })
                                        }
                                        style={{ padding: "10px 10px 10px 15px", borderRadius: "86x", backgroundColor: "#EBEBEB", border: "none" }}
                                        placeholder={t(common_caption_strings.example_email)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Stack position={"relative"}>
                                        <Input
                                            id={"password"}
                                            type={showPass ? "password" : "text"}
                                            onChange={(e) =>
                                                setData({ ...data, [e.target.id]: e.target.value })
                                            }
                                            style={{ padding: "10px 10px 10px 15px", borderRadius: "6px", backgroundColor: "#EBEBEB", border: "none" }}
                                            placeholder={t(common_caption_strings.password)}
                                        />

                                        {showPass ? (
                                            <BsFillEyeFill
                                                onClick={() => setShowPass(false)}
                                                style={{
                                                    position: "absolute",
                                                    top: 10,
                                                    right: 10,
                                                    cursor: "pointer",
                                                }}
                                                size={18}
                                            />
                                        ) : (
                                            <BsFillEyeSlashFill
                                                onClick={() => setShowPass(true)}
                                                style={{
                                                    position: "absolute",
                                                    top: 10,
                                                    right: 10,
                                                    cursor: "pointer",
                                                }}
                                                size={18}
                                            />
                                        )}
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack>
                        <ButtonComp
                            onClick={submit}
                            // loading={login_status === asyncStatus.LOADING}
                            // disabled={login_status === asyncStatus.LOADING}
                            // disabled={value}
                            backgroundColor={themeOrange}
                            style={{ padding: "10px", fontSize: "18px", borderRadius: "8px" }}
                            label={t(common_caption_strings.Signup)}
                        />
                    </Stack>
                    <Stack mt={1} direction={"row"} justifyContent={"center"}>
                        <Typography color={"#9F9F9F"}>{t(common_caption_strings.already_have_account)}</Typography><Typography onClick={() => navigate("/login")} sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}>{t(common_caption_strings.Login)}</Typography>
                    </Stack>
                    <div
                        style={{ display: "flex", alignItems: "center", padding: "10px" }}
                    >
                        <div
                            style={{
                                height: "2px",
                                width: "100%",
                                backgroundColor: "#9F9F9F",
                                margin: "0px 30px",
                            }}
                        ></div>
                        <div style={{}}>{t(common_caption_strings.Or)}</div>
                        <div
                            style={{
                                height: "2px",
                                width: "100%",
                                backgroundColor: "#9F9F9F",
                                margin: "0px 30px",
                            }}
                        ></div>
                    </div>
                    <Stack mt={2} spacing={2}>
                        {render_buttons.map((item, i) => {
                            const { caption, icon, on_change, background_style } = item;
                            return (
                                <Stack
                                key={i}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={2}

                                    // textAlign={"center"}
                                    sx={{
                                        padding: "10px",
                                        fontSize: "18px",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        ...background_style,
                                    }}
                                >
                                    <Stack flex={4} alignItems={'end'}>
                                        <Typography>     {icon}</Typography>

                                    </Stack>
                                    <Stack flex={10}>

                                        <Typography>{t(caption)}</Typography>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>

                </Stack>
            </Stack>
        </Container>
    );

}
export default SignUp;