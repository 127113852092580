import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { mainRoutes } from "../../utils/routeList";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "../../services/authentication";
import { asyncStatus, save_tokens_constant } from "../../store/constants";
import { jwtDecode } from "jwt-decode";
import { exit_session } from "../apiHandle/apiHandle";
import dayjs from "dayjs";
import { CircularProgress, Stack } from "@mui/material";
import { setCheckAuthStatus, setModalState } from "../../store/slices/user_auth_slice";

export const RouterApp = () => {
  const { check_auth_status, check_auth_data, userAuth} = useSelector(
    (state) => state.userAuth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem(save_tokens_constant);
    if (token) {
      const user = jwtDecode(token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (isExpired) {
        exit_session();
      } else {
        dispatch(checkAuth());
      }
    }else{
      dispatch(setCheckAuthStatus())
    }
  }, []);
  useEffect(() => {
    if(userAuth){
      dispatch(setModalState(false));
    }
  }, [userAuth])
  
  console.log(`check_auth_data_----->`, check_auth_data);
  if(check_auth_status===asyncStatus.IDLE||check_auth_status===asyncStatus.LOADING){
    return <Stack width={1} height={"100vh"} justifyContent={"center"} alignItems={"center"}>
      <CircularProgress/>
    </Stack>
  }
  return (
    <Router>
      <Routes>
        {React.Children.toArray(
          mainRoutes.map((route) => {
            const { linkTo, element, authRequired } = route;
            return <Route element={element} path={linkTo} />;
          })
        )}
      </Routes>
    </Router>
  );
};
