import React from "react";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { Stack, Typography } from "@mui/material";
const data = [
  "Acceptance of Terms By accessing and using PreachingQuotes.com, you accept and agree to be bound by these terms and conditions, our Privacy Policy, and any other legal notices published by us on the website. If you do not agree to these terms, you must not use our website.",
  "User Contributions Users may submit quotes to be featured on PreachingQuotes.com. By submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such content throughout the world in any media. You also represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to publish and share the content you submit.",
  "Content Accuracy We do not guarantee the accuracy, completeness, or usefulness of any information on the site and are not responsible for any errors or omissions in the content provided by users or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the website.",
  "Privacy Policy Your privacy is important to us. Personal information submitted through PreachingQuotes.com, such as your name and email address, will be handled in accordance with our Privacy Policy. This information will not be shared with third parties unless you provide explicit consent to share such information with our marketing partners.",
  "Conduct and Use of the Service You agree to use PreachingQuotes.com only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else’s use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website.",
  "Intellectual Property The content on PreachingQuotes.com, including but not limited to text, graphics, images, and software, is protected by intellectual property laws. Unless explicitly stated otherwise, you may not reproduce, modify, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any content contained on the site without our prior written consent.",
  'Disclaimers PreachingQuotes.com is provided on an "as is" and "as available" basis. We disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.',
  "Limitation of Liability In no event shall PreachingQuotes.com, its affiliates, officers, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages resulting from the use of or inability to use the website.",
  "Changes to Terms We reserve the right to modify these terms and conditions at any time. Your continued use of PreachingQuotes.com after any such changes constitutes your acceptance of the new terms and conditions.",
  "Contact Us If you have any questions about these terms and conditions, please contact us at ",
];

const Terms_Condition = () => {
  return (
    <main>
      <Navbar />
      <section>
        <Stack px={3} gap={2} py={5} maxWidth={1000} mx={"auto"}>
          <Typography variant="h2">Terms and Conditions</Typography>
          <p>
            Welcome to PreachingQuotes.com. By accessing our website and using
            our services, you agree to be bound by the following terms and
            conditions. Please read them carefully.
          </p>
          <Stack px={2}>
            <ol>
              {data.map((e, i) => (
                <li key={i} style={{ padding: `10px 5px` }}>
                  {i == data.length - 1 ? (
                    <>
                      {e}{" "}
                      <a href="mailto:support@preachingquotes.com.">
                        support@preachingquotes.com.
                      </a>
                    </>
                  ) : (
                    e
                  )}
                </li>
              ))}
            </ol>
          </Stack>
        </Stack>
      </section>
      <Footer />
    </main>
  );
};

export default Terms_Condition;
