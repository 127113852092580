import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllAuthors,
  getQuoteByAuthorName,
  getQuoteBySearch,
  getQuotes,
  getTopicsAsync,
} from "../services/QuotesService";
import { asyncStatus, searchBy } from "../store/constants";
import QuoteCard from "../component/common/QuoteCard/QuoteCard";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import { setSearchByKey } from "../store/slices/quoteSlice";
import { authorName } from "../utils/AuthorName";

const SearchData = () => {
  // SERACH MIEN TOPICS RENDER HOGYE HAIN AB FLAT KA ERROR AARHA THA BCUZ K SHYD API CHANGE HUI HAI YA SPELLING MISTAKE THI CATEGORIES KI TW AB KHAIR TOPICS AAGYE HAIN AB SEARCHING KS TRHA HORHI THI WO KRLYNA MAI TOPICS WLY PAGE PY KAAM KR RHA OKIE DOKIE JO B IS PAGE PY AAYE YE PRH LYNA AUR CHECK KRLYNA TATA

  const {
    getQuotesData,
    getQuotesStatus,
    getQuotesError,
    quotesBySearchStatus,
    quotesBySearchData,
    quotesBySearchError,
    searchByKey,

    authorQuotesData,
    authorQuotesStatus,
    authorQuotesError,

    getTopicsStatus,
    getTopicsError,
    getTopicsData,

    getAuthorsStatus,
    getAuthorsError,
    getAuthorsData,
  } = useSelector((state) => state.quotes);

  const location = useLocation();
  const state = location.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAuthorName, setSelectedAuthorName] = useState(
    authorName[0].name
  );
  const quoteLoading =
    quotesBySearchStatus === asyncStatus.LOADING && currentPage < 2;
  useEffect(() => {
    console.log(state);
    if (quotesBySearchStatus == asyncStatus.IDLE) {
      // if (searchByKey === searchBy.ALL) {
      dispatch(
        getQuoteBySearch({
          value:
            state.query === "All"
              ? state?.bothOrEither === "both"
                ? state.data
                : state.data?.split(" ")[0]
              : state.data,
          query: state.query,
          page: currentPage,
        })
      );
      // } else if (searchByKey === searchBy.AUTHOR) {
      //   dispatch(getQuoteByAuthorName(selectedAuthorName));
      // } else if (searchByKey === searchBy.TOPIC) {
      //   dispatch(getTopicsAsync());
      // }
      // }, [, selectedAuthorName, searchByKey]);
    }
  }, [, quotesBySearchStatus]);

  const navigateHandler = (_id, index) => {
    localStorage.setItem("currentImgIndex", index);
    navigate(`/product/${_id}`);
  };

  // useEffect(() => {
  //   dispatch(getAllAuthors());
  // }, []);

  const quotePaginationLoading =
    quotesBySearchStatus === asyncStatus.LOADING && currentPage >= 2;
  return (
    <>
      <Navbar />
      {/* <Stack paddingX={4} component={"form"} borderRadius={5}>
        <Stack direction={"row"} justifyContent={"end"}>
          <RadioGroup
            row
            aria-label="searchBy"
            name="searchBy"
            defaultValue={searchBy.ALL}
            value={searchByKey.toLowerCase()}
            onChange={(e) => dispatch(setSearchByKey(e.target.value))}
          >
            {Object.keys(searchBy).map((e) => {
              return (
                <Stack
                  direction={"row"}
                  style={{ cursor: "pointer" }}
                  alignItems={"center"}
                >
                  <Radio value={e.toLowerCase()} label="Soft" variant="soft" />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {e.toLowerCase()}
                  </Typography>
                </Stack>
              );
            })}
          </RadioGroup>
        </Stack>
        {searchByKey === searchBy.AUTHOR && (
          <Stack alignItems={"end"}>
            <Select
              sx={{ padding: 0, width: "50%" }}
              defaultValue={authorName[0].name}
              onChange={(e) => setSelectedAuthorName(e.target.value)}
              value={selectedAuthorName}
            >
              {authorName.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.name}>
                    {" "}
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        )}
        {searchByKey === searchBy.TOPIC &&
          (getTopicsStatus === asyncStatus.LOADING ? (
            <CircularProgress />
          ) : (
            getTopicsData && (
              <Stack alignItems={"end"}>
                <Select
                  sx={{ padding: 0, width: "50%" }}
                  defaultValue={getTopicsData.Categories[0]}
                  // onChange={(e) => setSelectedAuthorName(e.target.value)}
                  // value={selectedAuthorName}
                >
                  {getTopicsData.Categories.map((e, i) => {
                    return (
                      i < 50 && (
                        <MenuItem value={e} key={i}>
                          {" "}
                          {e}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </Stack>
            )
          ))}
      </Stack> */}
      <Grid container p={5}>
        {/* {quoteLoading ?
         (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack p={2} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Grid>
        ) : quotesBySearchError || authorQuotesError ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack p={2} justifyContent={"center"} alignItems={"center"}>
              <Typography>
                Something went wrong please try again{" "}
                <Button onClick={dispatch(getQuotes(1))}>Reload</Button>
              </Typography>
            </Stack>
          </Grid>
        ) : searchByKey === searchBy.ALL ? (
          quotesBySearchData &&
          quotesBySearchData.length &&
          quotesBySearchData.map((e, i) => (
            <Grid item xs={12} p={2} sm={6} md={4} lg={4} xl={4} key={i}>
              <QuoteCard
                onClick={() => navigateHandler(e._id, i)}
                data={e}
                img={quoteBackgroundImgList[i]}
              />
              <Divider />
            </Grid>
          ))
        ) : (
          authorQuotesData &&
          authorQuotesData.length &&
          authorQuotesData?.map(
            (e, i) =>
              i < 6 && (
                <Grid item xs={12} p={2} sm={4} md={4} lg={4} xl={4} key={i}>
                  <QuoteCard
                    onClick={() => navigateHandler(e._id, i)}
                    data={e}
                    img={quoteBackgroundImgList[i]}
                  />
                  <Divider />
                </Grid>
              )
          )
        )} */}
        {quoteLoading ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack p={2} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Grid>
        ) : (
          quotesBySearchData &&
          quotesBySearchData.length &&
          quotesBySearchData.map((e, i) => (
            <Grid item xs={12} p={2} sm={6} md={4} lg={4} xl={4} key={i}>
              <QuoteCard
                onClick={() => navigateHandler(e._id, i)}
                data={e}
                img={quoteBackgroundImgList[i]}
              />
              <Divider />
            </Grid>
          ))
        )}
      </Grid>

      <Stack width={1} alignItems={"center"} my={2}>
        {quotePaginationLoading && (
          <Stack p={2} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {quotesBySearchStatus === asyncStatus.SUCCEEDED &&
          !quotePaginationLoading && (
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(
                  // getQuoteBySearch({ value: state, page: currentPage + 1 })
                  getQuoteBySearch({
                    value: state.data,
                    query: state.query,
                    page: currentPage + 1,
                  })
                  // dispatch(getQuoteBySearch({ value: state.data, query: state.query, page: currentPage }));
                );
                setCurrentPage(currentPage + 1);
              }}
            >
              Load More
            </Button>
          )}
      </Stack>
      <Footer />
    </>
  );
};

export default SearchData;
