import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { serviceConstant } from "./Constants/ServiceConstant";

export const loginAsync = createAsyncThunk(
  serviceConstant.LOGIN,
  async (obj) => {
    try {
      const response = await apiHandle.post(`public/login`, obj);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const registerAsync = createAsyncThunk(
  serviceConstant.REGISTER,
  async (obj) => {
    try {
      const response = await apiHandle.post(`public/register`, obj);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
//CHECK AUTH
export const checkAuth = createAsyncThunk(
  serviceConstant.CHECK_AUTH,
  async () => {
    try {
      const response = await apiHandle.get(`public/check-auth?role=user`);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
