import React, { useState } from "react";
import CustomSelect from "../component/CustomSelect/CustomSelect";
import Navbar from "../component/navbar/Navbar";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Footer from "../component/Footer/Footer";
import { useSelector } from "react-redux";
import { themeDarkBlue } from "../utils/colorTheme";
import { useLocation } from "react-router-dom";

const data = [
  {
    chapter: "GENESIS 2",
    verseTitle: "The Seventh Day, God Rests",
    verse:
      "Thus the heavens and the earth were finished, and all the host of them. 2And on the seventh day God finished his work that he had done, and he rested on the seventh day from all his work that he had done. 3So God blessed the seventh day and made it holy, because on it God rested from all his work that he had done in creation.",
    secondVerseTitle: "The Creation of Man and Woman",
    secondVerse: `These are the generations
    of the heavens and the earth when they were created,
    in the day that the Lord God made the earth and the heavens.
    5When no bush of the field was yet in the land and no small plant of the field had yet sprung up—for the Lord God had not caused it to rain on the land, and there was no man to work the ground, 6and a mist was going up from the land and was watering the whole face of the ground— 7then the Lord God formed the man of dust from the ground and breathed into his nostrils the breath of life, and the man became a living creature. 8And the Lord God planted a garden in Eden, in the east, and there he put the man whom he had formed. 9And out of the ground the Lord God made to spring up every tree that is pleasant to the sight and good for food. The tree of life was in the midst of the garden, and the tree of the knowledge of good and evil.
    10A river flowed out of Eden to water the garden, and there it divided and became four rivers. 11The name of the first is the Pishon. It is the one that flowed around the whole land of Havilah, where there is gold. 12And the gold of that land is good; bdellium and onyx stone are there. 13The name of the second river is the Gihon. It is the one that flowed around the whole land of Cush. 14And the name of the third river is the Tigris, which flows east of Assyria. And the fourth river is the Euphrates.
    15The Lord God took the man and put him in the garden of Eden to work it and keep it. 16And the Lord God commanded the man, saying, “You may surely eat of every tree of the garden, 17but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.”
    18Then the Lord God said, “It is not good that the man should be alone; I will make him a helper fit for him.” 19Now out of the ground the Lord God had formed every beast of the field and every bird of the heavens and brought them to the man to see what he would call them. And whatever the man called every living creature, that was its name. 20The man gave names to all livestock and to the birds of the heavens and to every beast of the field. But for Adam there was not found a helper fit for him. 21So the Lord God caused a deep sleep to fall upon the man, and while he slept took one of his ribs and closed up its place with flesh. 22And the rib that the Lord God had taken from the man he made into a woman and brought her to the man. 23Then the man said,
    “This at last is bone of my bones
    and flesh of my flesh;
    she shall be called Woman,
    because she was taken out of Man.”
    24 Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. 25And the man and his wife were both naked and were not ashamed.`,
  },
  {
    chapter: "GENESIS 3",
    verseTitle: "The Seventh Day, God Rests",
    verse:
      "Thus the heavens and the earth were finished, and all the host of them. 2And on the seventh day God finished his work that he had done, and he rested on the seventh day from all his work that he had done. 3So God blessed the seventh day and made it holy, because on it God rested from all his work that he had done in creation.",
    secondVerseTitle: "The Creation of Man and Woman",
    secondVerse: `These are the generations
    of the heavens and the earth when they were created,
    in the day that the Lord God made the earth and the heavens.
    5When no bush of the field was yet in the land and no small plant of the field had yet sprung up—for the Lord God had not caused it to rain on the land, and there was no man to work the ground, 6and a mist was going up from the land and was watering the whole face of the ground— 7then the Lord God formed the man of dust from the ground and breathed into his nostrils the breath of life, and the man became a living creature. 8And the Lord God planted a garden in Eden, in the east, and there he put the man whom he had formed. 9And out of the ground the Lord God made to spring up every tree that is pleasant to the sight and good for food. The tree of life was in the midst of the garden, and the tree of the knowledge of good and evil.
    10A river flowed out of Eden to water the garden, and there it divided and became four rivers. 11The name of the first is the Pishon. It is the one that flowed around the whole land of Havilah, where there is gold. 12And the gold of that land is good; bdellium and onyx stone are there. 13The name of the second river is the Gihon. It is the one that flowed around the whole land of Cush. 14And the name of the third river is the Tigris, which flows east of Assyria. And the fourth river is the Euphrates.
    15The Lord God took the man and put him in the garden of Eden to work it and keep it. 16And the Lord God commanded the man, saying, “You may surely eat of every tree of the garden, 17but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.”
    18Then the Lord God said, “It is not good that the man should be alone; I will make him a helper fit for him.” 19Now out of the ground the Lord God had formed every beast of the field and every bird of the heavens and brought them to the man to see what he would call them. And whatever the man called every living creature, that was its name. 20The man gave names to all livestock and to the birds of the heavens and to every beast of the field. But for Adam there was not found a helper fit for him. 21So the Lord God caused a deep sleep to fall upon the man, and while he slept took one of his ribs and closed up its place with flesh. 22And the rib that the Lord God had taken from the man he made into a woman and brought her to the man. 23Then the man said,
    “This at last is bone of my bones
    and flesh of my flesh;
    she shall be called Woman,
    because she was taken out of Man.”
    24 Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. 25And the man and his wife were both naked and were not ashamed.`,
  },
  {
    chapter: "GENESIS 4",
    verseTitle: "The Seventh Day, God Rests",
    verse:
      "Thus the heavens and the earth were finished, and all the host of them. 2And on the seventh day God finished his work that he had done, and he rested on the seventh day from all his work that he had done. 3So God blessed the seventh day and made it holy, because on it God rested from all his work that he had done in creation.",
    secondVerseTitle: "The Creation of Man and Woman",
    secondVerse: `These are the generations
    of the heavens and the earth when they were created,
    in the day that the Lord God made the earth and the heavens.
    5When no bush of the field was yet in the land and no small plant of the field had yet sprung up—for the Lord God had not caused it to rain on the land, and there was no man to work the ground, 6and a mist was going up from the land and was watering the whole face of the ground— 7then the Lord God formed the man of dust from the ground and breathed into his nostrils the breath of life, and the man became a living creature. 8And the Lord God planted a garden in Eden, in the east, and there he put the man whom he had formed. 9And out of the ground the Lord God made to spring up every tree that is pleasant to the sight and good for food. The tree of life was in the midst of the garden, and the tree of the knowledge of good and evil.
    10A river flowed out of Eden to water the garden, and there it divided and became four rivers. 11The name of the first is the Pishon. It is the one that flowed around the whole land of Havilah, where there is gold. 12And the gold of that land is good; bdellium and onyx stone are there. 13The name of the second river is the Gihon. It is the one that flowed around the whole land of Cush. 14And the name of the third river is the Tigris, which flows east of Assyria. And the fourth river is the Euphrates.
    15The Lord God took the man and put him in the garden of Eden to work it and keep it. 16And the Lord God commanded the man, saying, “You may surely eat of every tree of the garden, 17but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.”
    18Then the Lord God said, “It is not good that the man should be alone; I will make him a helper fit for him.” 19Now out of the ground the Lord God had formed every beast of the field and every bird of the heavens and brought them to the man to see what he would call them. And whatever the man called every living creature, that was its name. 20The man gave names to all livestock and to the birds of the heavens and to every beast of the field. But for Adam there was not found a helper fit for him. 21So the Lord God caused a deep sleep to fall upon the man, and while he slept took one of his ribs and closed up its place with flesh. 22And the rib that the Lord God had taken from the man he made into a woman and brought her to the man. 23Then the man said,
    “This at last is bone of my bones
    and flesh of my flesh;
    she shall be called Woman,
    because she was taken out of Man.”
    24 Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. 25And the man and his wife were both naked and were not ashamed.`,
  },
  {
    chapter: "GENESIS 5",
    verseTitle: "The Seventh Day, God Rests",
    verse:
      "Thus the heavens and the earth were finished, and all the host of them. 2And on the seventh day God finished his work that he had done, and he rested on the seventh day from all his work that he had done. 3So God blessed the seventh day and made it holy, because on it God rested from all his work that he had done in creation.",
    secondVerseTitle: "The Creation of Man and Woman",
    secondVerse: `These are the generations
    of the heavens and the earth when they were created,
    in the day that the Lord God made the earth and the heavens.
    5When no bush of the field was yet in the land and no small plant of the field had yet sprung up—for the Lord God had not caused it to rain on the land, and there was no man to work the ground, 6and a mist was going up from the land and was watering the whole face of the ground— 7then the Lord God formed the man of dust from the ground and breathed into his nostrils the breath of life, and the man became a living creature. 8And the Lord God planted a garden in Eden, in the east, and there he put the man whom he had formed. 9And out of the ground the Lord God made to spring up every tree that is pleasant to the sight and good for food. The tree of life was in the midst of the garden, and the tree of the knowledge of good and evil.
    10A river flowed out of Eden to water the garden, and there it divided and became four rivers. 11The name of the first is the Pishon. It is the one that flowed around the whole land of Havilah, where there is gold. 12And the gold of that land is good; bdellium and onyx stone are there. 13The name of the second river is the Gihon. It is the one that flowed around the whole land of Cush. 14And the name of the third river is the Tigris, which flows east of Assyria. And the fourth river is the Euphrates.
    15The Lord God took the man and put him in the garden of Eden to work it and keep it. 16And the Lord God commanded the man, saying, “You may surely eat of every tree of the garden, 17but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.”
    18Then the Lord God said, “It is not good that the man should be alone; I will make him a helper fit for him.” 19Now out of the ground the Lord God had formed every beast of the field and every bird of the heavens and brought them to the man to see what he would call them. And whatever the man called every living creature, that was its name. 20The man gave names to all livestock and to the birds of the heavens and to every beast of the field. But for Adam there was not found a helper fit for him. 21So the Lord God caused a deep sleep to fall upon the man, and while he slept took one of his ribs and closed up its place with flesh. 22And the rib that the Lord God had taken from the man he made into a woman and brought her to the man. 23Then the man said,
    “This at last is bone of my bones
    and flesh of my flesh;
    she shall be called Woman,
    because she was taken out of Man.”
    24 Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. 25And the man and his wife were both naked and were not ashamed.`,
  },
  {
    chapter: "GENESIS 6",
    verseTitle: "The Seventh Day, God Rests",
    verse:
      "Thus the heavens and the earth were finished, and all the host of them. 2And on the seventh day God finished his work that he had done, and he rested on the seventh day from all his work that he had done. 3So God blessed the seventh day and made it holy, because on it God rested from all his work that he had done in creation.",
    secondVerseTitle: "The Creation of Man and Woman",
    secondVerse: `These are the generations
    of the heavens and the earth when they were created,
    in the day that the Lord God made the earth and the heavens.
    5When no bush of the field was yet in the land and no small plant of the field had yet sprung up—for the Lord God had not caused it to rain on the land, and there was no man to work the ground, 6and a mist was going up from the land and was watering the whole face of the ground— 7then the Lord God formed the man of dust from the ground and breathed into his nostrils the breath of life, and the man became a living creature. 8And the Lord God planted a garden in Eden, in the east, and there he put the man whom he had formed. 9And out of the ground the Lord God made to spring up every tree that is pleasant to the sight and good for food. The tree of life was in the midst of the garden, and the tree of the knowledge of good and evil.
    10A river flowed out of Eden to water the garden, and there it divided and became four rivers. 11The name of the first is the Pishon. It is the one that flowed around the whole land of Havilah, where there is gold. 12And the gold of that land is good; bdellium and onyx stone are there. 13The name of the second river is the Gihon. It is the one that flowed around the whole land of Cush. 14And the name of the third river is the Tigris, which flows east of Assyria. And the fourth river is the Euphrates.
    15The Lord God took the man and put him in the garden of Eden to work it and keep it. 16And the Lord God commanded the man, saying, “You may surely eat of every tree of the garden, 17but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.”
    18Then the Lord God said, “It is not good that the man should be alone; I will make him a helper fit for him.” 19Now out of the ground the Lord God had formed every beast of the field and every bird of the heavens and brought them to the man to see what he would call them. And whatever the man called every living creature, that was its name. 20The man gave names to all livestock and to the birds of the heavens and to every beast of the field. But for Adam there was not found a helper fit for him. 21So the Lord God caused a deep sleep to fall upon the man, and while he slept took one of his ribs and closed up its place with flesh. 22And the rib that the Lord God had taken from the man he made into a woman and brought her to the man. 23Then the man said,
    “This at last is bone of my bones
    and flesh of my flesh;
    she shall be called Woman,
    because she was taken out of Man.”
    24 Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. 25And the man and his wife were both naked and were not ashamed.`,
  },
];

const length = data.length;
const Bible = () => {
  const { single_verses_Status, single_verses_Data, single_verses_Error } =
    useSelector((state) => state.quotes);

  const [currentIndex, setCurrentIndex] = useState(0);
  const nextChapterHandler = () => {
    const newIndex = (currentIndex + 1) % data.length;
    setCurrentIndex(newIndex);
  };
  const prevChapterHandler = () => {
    const newIndex = (currentIndex - 1 + data.length) % data.length;
    setCurrentIndex(newIndex);
  };
  // console.log(currentIndex);
  const location = useLocation();
  // const {chapter} = location?.state;
  // console.log("state",chapter);
  console.log("===============>>>", single_verses_Data);

  return (
    <>
      <Navbar />
      <Container>
        {/* IS SELECT KO BANANY MIEN BHT TIME LGA HAI TW MISTAKES HO SKTI HAIN, LOVE & UR FAV ZUNAIR 😍 */}
        <Stack alignItems={"center"}>
          <CustomSelect defaultCh={location?.state?.chapter?location?.state?.chapter:"Genesis 1"} />
        </Stack>
        <Divider />
        {/* <Box
        sx={{
          margin: "10px auto",
          maxWidth: {
            sm: `95%`,
            xs: `95%`,
            md: "60%",
            lg: "60%",
            xl: `60%`,


          },
          position: "relative",
        }}
      >
        <Stack
          sx={{
            margin: "10px auto",
            maxWidth: {
              sm: `100%`,
              xs: `100%`,
              md: "65%",
              lg: "65%",
              xl: `65%`,
            },
          }}
        >
          <Stack gap={1}>
            <Typography
              fontSize={"1.6rem"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              {data[currentIndex].chapter}
            </Typography>
            <Typography fontSize={"1.6rem"} mt={2} fontWeight={"bold"}>
              {data[currentIndex].verseTitle}
            </Typography>
            <Typography fontSize={"1.2rem"}>
              {data[currentIndex].verse}
            </Typography>
            <Typography fontSize={"1.6rem"} mt={2} fontWeight={"bold"}>
              {data[currentIndex].secondVerseTitle}
            </Typography>
            <Typography fontSize={"1.2rem"}>
              {data[currentIndex].secondVerse}
            </Typography>
          </Stack>
        </Stack>
        <Button
          sx={{
            minWidth: 0,
            borderRadius: "50%",
            boxShadow: "0 0 10px lightgray",
            color: "gray",
            width: 50,
            height: 50,
            border: `2px solid #gray`,
            position: "fixed",
            top: "60%",
            left: `10%`,
          }}
        >
          <IoIosArrowBack size={24} onClick={prevChapterHandler} />
        </Button>
        <Button
          sx={{
            minWidth: 0,
            borderRadius: "50%",
            boxShadow: "0 0 10px lightgray",
            color: "gray",
            width: 50,
            height: 50,
            border: `2px solid #gray`,
            position: "fixed",
            top: "60%",
            right: `10%`,
          }}
        >
          <IoIosArrowForward size={24} onClick={nextChapterHandler} />
        </Button>
      </Box> */}

        {single_verses_Data?.data?.map((e, i) => {
          return (
            <Grid container mt={3} spacing={1} alignItems="start">
              <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                <Stack
                  sx={{
                    fontSize: {
                      sm: "14px",
                      xs: `14px`,
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {e?.chapter}
                </Stack>
              </Grid>
              <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                <Stack
                  sx={{
                    fontSize: {
                      sm: "14px",
                      xs: `14px`,
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: "bold",

                    color: themeDarkBlue,
                  }}
                >
                  {e?.BSB}
                </Stack>
              </Grid>
            </Grid>
          );
        })}
      </Container>
      <Stack justifyContent={"center"} alignItems={"center"} mt={5}>
        <Typography
          textAlign={"center"}
          maxWidth={580}
          width={"100%"}
          fontSize={12}
          p={1}
        >
          The Holy Bible, Berean Standard Bible, BSB is produced in cooperation
          with Bible Hub, Discovery Bible, OpenBible.com, and the Berean Bible
          Translation Committee. This text of God's Word has been dedicated to
          the public domain. Free resources and databases are available at
          BereanBible.com.
        </Typography>
      </Stack>
      <Footer />
    </>
  );
};

export default Bible;
