const AuthorsData = [
  "A.A. Milne",
  "Abraham Lincoln",
  "Agatha Christie",
  "Aiden Wilson Tozer",
  "Albert Einstein",
  "Aleksandr Solzhenitsyn",
  "Alexander Hamilton",
  "Alexander Pope",
  "Alexandre Dumas",
  "Alexis de Tocqueville",
  "Alfred Hitchcock",
  "Alfred Lord Tennyson",
  "Anne Frank",
  "Aristotle",
  "Arthur C. Clarke",
  "Arthur Miller",
  "Arthur W. Pink",
  "B. B. King",
  "Barack Obama",
  "Bear Grylls",
  "Ben Carson",
  "Benjamin Franklin",
  "Benjamin Netanyahu",
  "Benjamin Watson",
  "Bertrand Russell",
  "Beth Moore",
  "Billy Crystal",
  "Billy Graham",
  "Blaise Pascal",
  "Bob Dylan",
  "Bob Goff",
  "Brené Brown",
  "Brother Lawrence",
  "C. S. Lewis",
  "Carl Sandburg",
  "Chaim Potok",
  "Charles Barkley",
  "Charles F. Stanley",
  "Charles M. Schulz",
  "Charles R. Swindoll",
  "Charles Spurgeon",
  "Charlie Chaplin",
  "Charlotte Bronte",
  "Chevy Chase",
  "Claude Monet",
  "Confucius",
  "Corrie Ten Boom",
  "Craig Groeschel",
  "D.H. Lawrence",
  "Dale Carnegie",
  "Danica McKellar",
  "Dave Ramsey",
  "David Livingstone",
  "Dean Karnazes",
  "Derek Jeter",
  "Dietrich Bonhoeffer",
  "Donald Miller",
  "Dr. Seuss",
  "Dwight D. Eisenhower",
  "E.E. Cummings",
  "E.M. Forster",
  "Edgar Allan Poe",
  "Eleanor Roosevelt",
  "Elie Wiesel",
  "Elisabeth Elliot",
  "Elon Musk",
  "Elvis Presley",
  "Emily Bronte",
  "Emily Brontë",
  "Ernest Hemingway",
  "Erwin McManus",
  "Eugene H. Peterson",
  "F. Scott Fitzgerald",
  "Francine Pascal",
  "Francis Bacon",
  "Frank Lloyd Wright",
  "Franklin D. Roosevelt",
  "Fred Rogers",
  "Frederick Douglass",
  "Gary Chapman",
  "George Bernard Shaw",
  "George Eliot",
  "George H. W. Bush",
  "George W. Bush",
  "Groucho Marx",
  "H.L. Mencken",
  "Harper Lee",
  "Harry S. Truman",
  "Helen Keller",
  "Henri J.M. Nouwen",
  "Henri Nouwen",
  "Henry David Thoreau",
  "Henry Ford",
  "Henry James",
  "Henry T. Blackaby",
  "Henry Wadsworth Longfellow",
  "Herman Melville",
  "Hippocrates",
  "Homer",
  "Isaac Newton",
  "J. C. Watts",
  "J. R. R. Tolkien",
  "Jack London",
  "James Baldwin",
  "James Fenimore Cooper",
  "James MacDonald",
  "James Madison",
  "James Thurber",
  "Jane Austen",
  "Jean-Jacques Rousseau",
  "Jeff Foxworthy",
  "Jennifer Garner",
  "Jim Gaffigan",
  "Jimmy Carter",
  "Joe Rogan",
  "Joel Osteen",
  "Johann Wolfgang von Goethe",
  "John Calvin",
  "John D. Rockefeller",
  "John Donne",
  "John Eldredge",
  "John F. Kennedy",
  "John Grisham",
  "John Locke",
  "John Ortberg",
  "John Owen",
  "John Piper",
  "John Steinbeck",
  "John Stuart Mill",
  "John Wooden",
  "Jon Gordon",
  "Josh McDowell",
  "Kirk Cameron",
  "Kurt Warner",
  "L.M. Montgomery",
  "Larry Osborne",
  "LeBron James",
  "Lee Strobel",
  "Leo Tolstoy",
  "Leonardo da Vinci",
  "Lewis Carroll",
  "Liam Neeson",
  "Lord Byron",
  "Lou Holtz",
  "Louis L'Amour",
  "Louisa May Alcott",
  "Lucy Maud Montgomery",
  "Ludwig van Beethoven",
  "Lysa TerKeurst",
  "M. Scott Peck",
  "Madeleine L'Engle",
  "Mae West",
  "Marcus Aurelius",
  "Mark Twain",
  "Marlon Brando",
  "Martin Luther",
  "Martin Luther King Jr.",
  "Martin Luther King, Jr.",
  "Max Lucado",
  "Maya Angelou",
  "Michael Phelps",
  "Michelle Obama",
  "Miguel de Cervantes",
  "Muhammad Ali",
  "N.T. Wright",
  "Napoleon Bonaparte",
  "Napoléon Bonaparte",
  "Nathaniel Hawthorne",
  "Neil T. Anderson",
  "Nelson Mandela",
  "Niccolò Machiavelli",
  "Nick Saban",
  "Nikola Tesla",
  "Novak Djokovic",
  "Os Guinness",
  "Oscar Wilde",
  "Oswald Chambers",
  "Pearl S. Buck",
  "Percy Bysshe Shelley",
  "Peter O'Toole",
  "Phil Jackson",
  "Philip Yancey",
  "Plato",
  "R. C. Sproul",
  "Rafael Nadal",
  "Ralph Waldo Emerson",
  "Ray Bradbury",
  "Ray Kroc",
  "Reinhold Niebuhr",
  "René Descartes",
  "Richard J. Foster",
  "Rick Warren",
  "Roald Dahl",
  "Robert Browning",
  "Robert Frost",
  "Robert Kennedy",
  "Robert Louis Stevenson",
  "Ronald Reagan",
  "Rudyard Kipling",
  "Saint Augustine",
  "Scot McKnight",
  "Seth Godin",
  "Shimon Peres",
  "Smith Wigglesworth",
  "Sophocles",
  "Soren Kierkegaard",
  "Søren Kierkegaard",
  "Stephen R. Covey",
  "Steve Harvey",
  "Steve Martin",
  "Sun Tzu",
  "T.D. Jakes",
  "T.S. Eliot",
  "Taylor Swift",
  "Teresa of Ávila",
  "Thom S. Rainer",
  "Thomas à Kempis",
  "Thomas A. Edison",
  "Thomas Aquinas",
  "Thomas Jefferson",
  "Thomas Merton",
  "Thomas Sowell",
  "Tim Allen",
  "Tim Tebow",
  "Timothy Keller",
  "Tony Dungy",
  "Tony Evans",
  "Troy Aikman",
  "Ulysses S. Grant",
  "Victor Hugo",
  "Viktor E. Frankl",
  "Vincent Van Gogh",
  "Voltaire",
  "W. E. B. Du Bois",
  "W.E.B. Du Bois",
  "W.H. Auden",
  "Walt Disney",
  "Walt Whitman",
  "Walter Cronkite",
  "Walter Isaacson",
  "Warren Buffett",
  "Will Durant",
  "Will Ferrell",
  "Will Rogers",
  "William Blake",
  "William Faulkner",
  "William Shakespeare",
  "William Wordsworth",
  "Winston Churchill",
  "Winston S. Churchill",
  "Woody Allen",
  "Yogi Berra",
  "Zig Ziglar",
];
export { AuthorsData };
