import {
  Button,
  Container,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import footer_logo from "../../assets/footer_logo.png";
import footer_card_img from "../../assets/footer_img.png";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { subscribeToEmail } from "../../services/QuotesService";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const {
    SubscribeToEmailStatus,
    SubscribeToEmailError,
    SubscribeToEmailData,
  } = useSelector((state) => state.quotes);

  const submitHandle = () => {
    dispatch(subscribeToEmail(data));
  };

  return (
    <>
      <Grid container bgcolor={`#BCCDE6`} px={1.5} py={3}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} p={1}>
          <Stack spacing={1}>
            <Stack px={1}>
              <img src={footer_logo} width={"100%"} />
            </Stack>
            <Stack>
              <Typography>Follow us on Social Media</Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <FaFacebookF size={32} color="black" />
              <FaInstagram size={32} color="black" />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={3} p={1}>
          <Stack spacing={1}>
            <Stack>
              <Typography fontWeight={"bold"}>Quick Links</Typography>
            </Stack>
            <Stack>
              <Typography>About us</Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/Contact-us");
                }}
              >
                Contact us
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/Privacy_Policy");
                }}
              >
                Privacy Policy
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/Terms_Condition");
                }}
              >
                Terms & Conditions
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/make-your-own");
                }}
              >
                Slide Maker
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={3} p={1}>
          <Stack spacing={1}>
            <Stack>
              <Typography fontWeight={"bold"}>Menu</Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/topics");
                }}
              >
                Topics
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/authors");
                }}
              >
                Authors
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/set-bg-quote/65d1290c17bade0480609825");
                }}
              >
                Meme Maker
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={3} p={1}>
          <Stack spacing={1}>
            <Stack>
              <Typography fontWeight={"bold"}>
                Subscribe To Our Newsletter And Receive Daily Preaching Quotes
                And Inspiration.
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                background: "white",
                borderRadius: "50px",
                border: `1px solid lightgray`,
                pl: 1,
              }}
              columnGap={1}
            >
              <input
                placeholder="enter your email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  flexGrow: 1,
                }}
              />
              <Button
                sx={{
                  background: "#042E70",
                  borderRadius: "50px",
                  color: "white",
                  fontWeight: "bold",

                  ":hover": {
                    background: "#042E70",
                  },
                }}
                onClick={submitHandle}
              >
                Subscribe <MdOutlineKeyboardArrowRight size={35} />
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        sx={{
          background: "#282828",
        }}
      >
        <Container
          sx={{
            py: 5,
            color: "white",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "space-around",
                lg: "space-around",
                xl: "space-around",
              },
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "center",
                lg: "center",
                xl: "center",
              },
            }}
          >
            <Typography>
              PreachingQuotes.com is ©️ 2024. All Rights Reserved.
            </Typography>

            <img height="26" src={footer_card_img} alt="cards" />
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default Footer;
