import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  checkAuth,
  loginAsync,
  registerAsync,
} from "../../services/authentication";
import { save_tokens_constant } from "../constants";

const initialState = {
  login_status: asyncStatus.IDLE,
  login_data: null,
  login_error: null,

  register_status: asyncStatus.IDLE,
  register_data: null,
  register_error: null,

  check_auth_status: asyncStatus.IDLE,
  check_auth_data: null,
  modalState: false,
  userAuth: false,
  userData: null,
  // for only booking value change
  pageValue: "1",
  // for only booking value change
};

const user_auth_slice = createSlice({
  name: "userAuth",
  initialState,

  reducers: {
    setModalState(state, { payload }) {
      console.log(payload);
      state.modalState = payload;
    },
    setCheckAuthStatus(state, { payload }) {
      state.check_auth_status = asyncStatus.SUCCEEDED;
    },
  },

  extraReducers: (builder) => {
    //LOGIN
    builder.addCase(loginAsync.pending, (state, action) => {
      state.login_status = asyncStatus.LOADING;
      state.login_data = null;
      state.login_error = null;
    });
    builder.addCase(loginAsync.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.login_data = data;
      state.userData = data;
      state.userAuth = success;
      state.login_status = asyncStatus.SUCCEEDED;
      state.login_error = null;
      success_toast_message("Login successfully!")
      localStorage.setItem(save_tokens_constant, JSON.stringify(payload.token));
    });
    builder.addCase(loginAsync.rejected, (state, actions) => {
      state.login_data = null;
      state.login_status = asyncStatus.SUCCEEDED;
      state.login_error = actions.error.message;
      error_toast_message(actions.error.message);
    });

    //SIGNUP

    builder.addCase(registerAsync.pending, (state, action) => {
      state.register_status = asyncStatus.LOADING;
      state.register_data = null;
      state.register_error = null;
    });
    builder.addCase(registerAsync.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.register_data = data;
      state.userData = data;
      state.userAuth = success;
      state.register_status = asyncStatus.SUCCEEDED;
      localStorage.setItem(save_tokens_constant, JSON.stringify(payload.token));
      success_toast_message("Registered successfully!")
      state.register_error = null;
    });
    builder.addCase(registerAsync.rejected, (state, actions) => {
      state.register_data = null;
      state.register_status = asyncStatus.SUCCEEDED;
      state.register_error = actions.error.message;
      error_toast_message(actions.error.message);
    });

    //CHECK AUTH

    builder.addCase(checkAuth.pending, (state, action) => {
      state.check_auth_status = asyncStatus.LOADING;
    });
    builder.addCase(checkAuth.fulfilled, (state, { payload }) => {
      state.check_auth_data = payload.data;
      state.userData = payload.data;
      state.check_auth_status = asyncStatus.SUCCEEDED;
      state.userAuth = payload.success;
    });
    builder.addCase(checkAuth.rejected, (state, actions) => {
      state.check_auth_status = asyncStatus.ERROR;
      state.check_auth_data = null;
    });
  },
});

export const { setUserRole, setIdleStatus, setpageValuem, setModalState ,setCheckAuthStatus} =
  user_auth_slice.actions;

export default user_auth_slice.reducer;
