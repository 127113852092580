import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle } from "../config/apiHandle/apiHandle";
import { serviceConstant } from "./Constants/ServiceConstant";
export const getQuotes = createAsyncThunk(
  serviceConstant.GET_QUOTES,
  async (page) => {
    try {
      const response = await apiHandle.get(`/public/qoutes?page=${page || 1}`);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-QUOTE-BY-ID
export const getQuoteByID = createAsyncThunk(
  serviceConstant.GET_QUOTE_BY_ID,
  async (post_data) => {
    try {
      const response = await apiHandle.get(
        `/public/get-single-quote?postId=${post_data.id}&userId=${post_data.userId ? post_data.userId : ""
        }`
      );
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-QUOTE-BY-TOPIC-NAME

export const getQuoteByTopicName = createAsyncThunk(
  serviceConstant.GET_QUOTE_BY_TOPIC_NAME,
  async ({ topic, page }) => {
    try {
      const response = await apiHandle.get(
        `/public/get-quotes-by-topic-name/${topic}?page=${page || 1}`
      );
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-QUOTE-BY-AUTHOR-NAME

export const getQuoteByAuthorName = createAsyncThunk(
  serviceConstant.GET_QUOTE_BY_AUTHOR_NAME,
  async ({ authorName, page }) => {
    try {
      console.log("Page============>", page);
      console.log("authorName============>", authorName);
      const response = await apiHandle.get(
        `/public/get-quotes-by-auther-name/${authorName}?page=${page || 1}`
      );
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-QUOTE-BY-SEARCH

export const getQuoteBySearch = createAsyncThunk(
  serviceConstant.GET_QUOTE_BY_SEARCH,
  async ({ value, page, query }) => {
    console.log("ok", { value, page, query });
    try {
      // const response = await apiHandle.post(`/public/search?searchQuery=${value}&page=${page}`);
      console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaa", value, page, query);
      const response = await apiHandle.post(
        `/public/search?page=${page}&searchText=${value}&category=${query.toLowerCase().charAt(0).toUpperCase() +
        query.toLowerCase().slice(1)
        }`
      );

      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
//GET-ALL-TOPICS

export const getTopicsAsync = createAsyncThunk(
  serviceConstant.GET_ALL_TOPICS,
  async (page) => {
    try {
      const response = await apiHandle.get(
        `public/get-all-categories?page=${page || 1}`
      );
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET ALL AUTHORS
export const getAllAuthors = createAsyncThunk(
  serviceConstant.GET_ALL_AUTHORS,
  async (page) => {
    try {
      const response = await apiHandle.get(
        `/public/get-all-aurthor?page=${page || 1}`
      );
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//LIKE || DISLIKE QUOTE

export const likeDislikeQuote = createAsyncThunk(
  serviceConstant.LIKE_DISLIKE_QUOTE,
  async (data) => {
    try {
      const response = await apiHandle.put(`public/likePost`, data);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const subscribeToEmail = createAsyncThunk(
  serviceConstant.SUBSCRIBE_TO_EMAIL,
  async (data) => {
    try {
      const response = await apiHandle.post(`/public/subscribe-to-email`, data);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-VERSE
export const getVerse = createAsyncThunk(
  serviceConstant.GET_VERSE,
  async (data) => {
    try {
      const response = await apiHandle.post(`/public/get-verse-range`, data);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-CHAPTERS
export const get_main_chapters = createAsyncThunk(
  serviceConstant.GET_MAIN_CHAPTERS,
  async () => {
    try {
      const response = await apiHandle.get(`/public/get-chapters`);
      const res_data = await response.data;

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-VERSE-INNER-CHAPTERS
export const get_verse_inner_chapters = createAsyncThunk(
  serviceConstant.GET_VERSE_INNER_CHAPTERS,
  async (chapter) => {

    try {
      const response = await apiHandle.post(`/public/get-verse-inner-chapter`,chapter);
      const res_data = await response.data;
      // console.log("res_data=======>>>>", res_data);

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//GET-inner-single-chapter-verses
export const get_inner_single_chapter_verses = createAsyncThunk(
  serviceConstant.GET_SINGLE_VERSE,
  async (chapter) => {

    try {
      const response = await apiHandle.get(`/public/get-inner-single-chapter-verses?chapter=${chapter}`);
      const res_data = await response.data;
      // console.log("res_data=======>>>>", res_data);

      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
