import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { useNavigate } from "react-router-dom";

import { themeDarkBlue, themeGray } from "../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { getQuotes, getTopicsAsync } from "../services/QuotesService";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { asyncStatus } from "../utils/asyncStatus";
import QuoteCard from "../component/common/QuoteCard/QuoteCard";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import { TopicsData } from "../utils/TOPICS_DATA";
import { searchBy } from "../store/constants";
import {
  setQuotesBySearchStatus,
  setSearchByKey,
  setSearchDataState,
  setSearchState,
} from "../store/slices/quoteSlice";
import Input from "../component/common/Input/Input";

const Topics = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const { getTopicsStatus, getTopicsError, getTopicsData } = useSelector(
  //   (state) => state.quotes
  // );

  // const [currentPage, setCurrentPage] = useState(1);
  // const loading = getTopicsStatus === asyncStatus.LOADING;

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   dispatch(getTopicsAsync(currentPage));
  // }, [, currentPage]);

  // const { getQuotesData, getQuotesStatus, getQuotesError } = useSelector(
  //   (state) => state.quotes
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchByKey, quotesBySearchStatus } = useSelector(
    (state) => state.quotes
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState();
  // const quoteLoading =
  //   getQuotesStatus === asyncStatus.LOADING && currentPage < 2;
  // const quotePaginationLoading =
  //   getQuotesStatus === asyncStatus.LOADING && currentPage >= 2;

  // useEffect(() => {
  //   if (getQuotesStatus == asyncStatus.IDLE) {
  //     dispatch(getQuotes(currentPage));
  //   }
  // }, []);

  // const navigateHandler = (_id, index) => {
  //   localStorage.setItem("currentImgIndex", index);
  //   navigate(`/product/${_id}`);
  // };

  const searchHandler = (e) => {
    e.preventDefault();

    let obj = {
      query: searchBy.TOPIC,
      data,
    };
    dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
    navigate("/search", { state: obj });
    // navigate("/topics");
  };

  useEffect(() => {
    if (quotesBySearchStatus === asyncStatus.SUCCEEDED) {
      dispatch(setSearchState());
      dispatch(setSearchDataState());
    }
  }, [quotesBySearchStatus]);

  useEffect(() => {
    dispatch(setSearchByKey(searchBy.TOPIC.toLocaleUpperCase()));
  }, []);

  const search = (e) => {
    e.preventDefault();
    let firstLetterCapital =
      searchByKey?.toLowerCase()?.charAt(0).toUpperCase() +
      searchByKey?.toLowerCase()?.slice(1);
    console.log(firstLetterCapital);
    if (firstLetterCapital === searchBy.ALL) {
      let obj = {
        query: searchBy.ALL,
        data,
        // bothOrEither
      };
      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
    } else if (firstLetterCapital === searchBy.AUTHOR) {
      let obj = {
        query: searchBy.AUTHOR,
        data,
      };
      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/authors");
    } else if (firstLetterCapital === searchBy.TOPIC) {
      let obj = {
        query: searchBy.TOPIC,
        data,
      };
      dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/topics");
    }
  };

  return (
    <>
      <Navbar />
      <Container
        sx={{
          minHeight: `55dvh`,
        }}
      >
        {
          // loading ? (
          //   <Stack alignItems={"center"} my={2}>
          //     <CircularProgress />
          //   </Stack>
          // ) : getTopicsError ? (
          //   <Typography>
          //     Something Went Wrong{" "}
          //     <Button
          //       onClick={() => {
          //         dispatch(getTopicsAsync(currentPage));
          //       }}
          //     >
          //       Reload
          //     </Button>
          //   </Typography>
          // ) : (
          TopicsData && (
            <Stack p={5} justifyContent={"space-between"}>
              <Grid container>
                <Grid container spacing={1}>
                  {TopicsData.slice(
                    (currentPage - 1) * 25,
                    currentPage * 25
                  ).map((e, i) => (
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6} key={i}>
                      <ul style={{ padding: 1 }}>
                        <li
                          style={{
                            listStyle: "none",
                            cursor: "pointer",
                            margin: "5px 0px",
                            color: themeDarkBlue,
                          }}
                          onClick={() => navigate(`/quote-by-topic/${e}`)}
                        >
                          {e}
                        </li>
                      </ul>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    color: themeDarkBlue,
                    fontWeight: "bold",
                  }}
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage((prev) => prev - 1);
                  }}
                >
                  <FaArrowAltCircleLeft size={24} />
                </Button>
                <Typography>
                  {currentPage} of {Math.ceil(TopicsData.length / 25)}
                </Typography>
                <Button
                  onClick={() => {
                    setCurrentPage((prev) => prev + 1);
                  }}
                  sx={{
                    color: themeDarkBlue,
                    fontWeight: "bold",
                  }}
                  disabled={currentPage === Math.ceil(TopicsData.length / 25)}
                >
                  <FaArrowAltCircleRight size={24} />
                </Button>
              </Stack>
            </Stack>
          )
        }
        <form onSubmit={search}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Stack width={"100%"} position={"relative"}>
              <Input
                placeholder={"Search Quotes..."}
                style={{
                  width: "100%",
                  borderRadius: 45,
                  outlined: "none",
                  border: "none",
                  backgroundColor: themeGray,
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
                onChange={(e) => setData(e.target.value)}
              />
              <Button
                sx={{
                  position: "absolute",
                  top: "30%",
                  right: 0,
                  cursor: "pointer",
                  p: 0,
                  ":hover": {
                    background: "none",
                  },
                }}
                type="submit"
              >
                <FaSearch size={20} color={"#686565"} />
              </Button>
            </Stack>
          </Stack>
        </form>
        {/* <Stack
          p={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 0 }}
          component={"form"}
          width={"100%"}
          sx={{ backgroundColor: "white", border: "1px solid black" }}
          borderRadius={5}
          marginY={1}
          direction={"row"}
          mb={4}
          justifyContent={"center"}
        >
          <RadioGroup row aria-label="searchBy" name="searchBy" value={"TOPIC"}>
            {Object.keys(searchBy).map(
              (e) =>
                e === "TOPIC" && (
                  <Stack
                    direction={"row"}
                    style={{ cursor: "pointer" }}
                    alignItems={"center"}
                  >
                    <Radio
                      value={e.charAt(0).toUpperCase() + e.slice(1)}
                      label="Soft"
                      variant="soft"
                    />
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {e.toLowerCase()}
                    </Typography>
                  </Stack>
                )
            )}
          </RadioGroup>
        </Stack> */}
        <Stack
          p={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 0 }}
          component={"form"}
          width={"100%"}
          sx={{ backgroundColor: "white", border: "1px solid black" }}
          borderRadius={5}
          marginY={1}
          direction={"row"}
          mb={4}
          justifyContent={"center"}
        >
          <RadioGroup
            row
            aria-label="searchBy"
            name="searchBy"
            value={
              searchByKey
                ? searchByKey?.charAt(0).toUpperCase() + searchByKey?.slice(1)
                : "TOPIC"
            }
            defaultValue={searchBy.TOPIC.toLocaleUpperCase()}
            onChange={(e) => {
              dispatch(setSearchByKey(e.target.value));
              console.log(e.target.value);
            }}
          >
            {Object.keys(searchBy).map((e) => {
              return (
                <Stack
                  direction={"row"}
                  style={{ cursor: "pointer" }}
                  alignItems={"center"}
                >
                  <Radio
                    value={e.charAt(0).toUpperCase() + e.slice(1)}
                    label="Soft"
                    variant="soft"
                  />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {/* {e.charAt(0).toUpperCase() + e.slice(1)} */}
                    {e.toLowerCase()}
                  </Typography>
                </Stack>
              );
            })}
          </RadioGroup>
        </Stack>
      </Container>

      <Footer />
    </>
  );
};

export default Topics;
