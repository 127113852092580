import React from "react";
import Navbar from "../component/navbar/Navbar";
import { Stack, Typography } from "@mui/material";
import Footer from "../component/Footer/Footer";

const ContactUs = () => {
  return (
    <main>
      <Navbar />
      <section>
        <Stack px={3} gap={2} py={5} maxWidth={1000} mx={"auto"}>
          <Typography variant="h2">Contact Us</Typography>
          <p>
            We value your feedback and inquiries. Please feel free to reach out
            to us through any of the following methods:
          </p>

          <Stack gap={3}>
            <Typography>
              <Typography variant="span" fontWeight={"bold"}>
                Email:
              </Typography>{" "}
              <a href="mailto:support@preachingquotes.com">
                support@preachingquotes.com
              </a>
            </Typography>
            <Typography>
              <Typography variant="span" fontWeight={"bold"}>
                Website:
              </Typography>{" "}
              <a href="www.preachingquotes.com">www.preachingquotes.com</a>
            </Typography>
            <Typography>
              <Typography variant="span" fontWeight={"bold"}>
                Phone:
              </Typography>{" "}
              <a href="tel:+16302153365">(630) 215-3365</a>
            </Typography>
            <Typography>
              <Typography variant="span" fontWeight={"bold"}>
                Mailing Address:
              </Typography>{" "}
              Inspire Media Group, LLC 5331 Triangle Ln Export, PA 15632 United
              States
            </Typography>

            <Typography>
              For media inquiries, support issues, or more information about
              PreachingQuotes.com, our customer service team is ready to assist
              you. We aim to respond to all queries as quickly as possible.
            </Typography>
          </Stack>
        </Stack>
      </section>
      <Footer />
    </main>
  );
};

export default ContactUs;
