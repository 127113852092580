import { Box, Button, Stack, Typography } from "@mui/material";
import { CiStar } from "react-icons/ci";

import { CiHeart } from "react-icons/ci";
import { RiSignalTowerFill } from "react-icons/ri";
import { CiShoppingBasket } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const icons = [
  {
    name: "fav",
    icon: <CiHeart />,
    onClick: () => {},
  },
  {
    name: "signal",
    icon: <FaRegEye />,
    onClick: (data, navigate) => {
      // window.location.pathname = data;
      navigate("/view-quote", { state: data });
    },
  },
  // {
  //   name: "basket",
  //   icon: <IoCloudDownloadOutline />,
  //   onClick: () => { },
  // },
];
const QuoteCard = ({ data, onClick, img }) => {
  const { text, rating, Quote, Author } = data;
  const [showBtns, setShowBtns] = useState(false);
  const navigate = useNavigate();
  const onHoverHandler = () => {
    setShowBtns(true);
  };
  const onHoverOutHandler = () => {
    setShowBtns(false);
  };

  return (
    <Stack
      onClick={onClick}
      onMouseOver={onHoverHandler}
      sx={{ cursor: "pointer", borderRadius: 5 }}
      onMouseOut={onHoverOutHandler}
    >
      <Stack
        position={"relative"}
        sx={{
          height: {
            xs: 300,
            sm: 300,
            md: 400,
            lg: 400,
            xl: 400,
          },

          background: `url(${img})`,
          backgroundSize: "cover",
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          sx={{
            overflow: "hidden",
            minHeight: "100%",
            background: `rgba(0,0,0,.4)`,
            p: 3,
          }}
        >
          <Stack>
            <Typography
              color={"white"}
              fontWeight={"bold"}
              textAlign={"center"}
              fontSize={"20px"}
            >
              {Quote.length > 100 ? (
                <>{Quote.slice(0, 101)}... Read more</>
              ) : (
                Quote
              )}
            </Typography>
          </Stack>
          <Typography
            color={"white"}
            textAlign={"center"}
            ml={15}
            fontWeight={"500"}
            fontSize={"18px"}
          >
            {`~ ${Author}` || "authorName "}
          </Typography>
        </Stack>

        {showBtns && (
          <Stack
            key={showBtns}
            position={"absolute"}
            alignItems={"center"}
            spacing={1}
            justifyContent={"center"}
            bottom={0}
            padding={1}
            right={0}
          >
            {icons.map((e, i) => {
              // const {onClick} = e
              return (
                <Button
                  sx={{
                    color: "black",
                    background: "white",
                    p: 1.2,
                    minWidth: 0,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    ":hover": {
                      background: `#042E70`,
                      color: "white",
                    },
                  }}
                  key={i}
                  onClick={(event) => {
                    event.stopPropagation();
                    e.onClick({ ...data, img }, navigate);
                  }}
                >
                  {e.icon}
                </Button>
              );
            })}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default QuoteCard;
