export const common_caption_strings = {
    first_name: "First Name",
    last_name: "Last Name",
    example_email: "abc@mail.com",
    password: "Password",
    already_have_account: "I already have an account?",
    Login: "Login",
    Signup: "Sign up",
    dont_have_account: "Don't have an account?",
    Or: "Or",
    continue_with_google: "Continue with Google account",
    continue_with_facebook: "Continue with Facebook account",
    continue_with_apple: "Continue with Apple id",
}