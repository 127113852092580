const TopicsData = [
  "Age",
  "Alone",
  "Anger",
  "Argument",
  "Art",
  "Attitude",
  "Beauty",
  "Books",
  "Business",
  "Car",
  "Chance",
  "Christmas",
  "Communication",
  "Computer",
  "Confidence",
  "Courage",
  "Dad",
  "Death",
  "Design",
  "Desire",
  "Desire",
  "Destiny",
  "Dignity",
  "Dreams",
  "Education",
  "Emotional",
  "Excellence",
  "Experience",
  "Failure",
  "Faith",
  "Family",
  "Father",
  "Fear",
  "Food",
  "Forgiveness",
  "Freedom",
  "Friendship",
  "Funny",
  "Future",
  "Girls",
  "Goal",
  "God",
  "Government",
  "Happiness",
  "Health",
  "Help",
  "History",
  "Home",
  "Honesty",
  "Honor",
  "Hope",
  "Humanity",
  "Humor",
  "Humour",
  "Imagination",
  "Independence",
  "Inspirational",
  "Intelligence",
  "Kindness",
  "Knowledge",
  "Leadership",
  "Learning",
  "Life",
  "Love",
  "Luck",
  "Marriage",
  "Marriage",
  "Men",
  "Mom",
  "Money",
  "Morning",
  "Mother",
  "Motivational",
  "Music",
  "Nature",
  "Parenting",
  "Patience",
  "Patriotism",
  "Peace",
  "Philosophy",
  "Poetry",
  "Politics",
  "Positive",
  "Power",
  "Prayer",
  "Relate",
  "Relationship",
  "Religion",
  "Respect",
  "Romantic",
  "Sad",
  "Science",
  "Shy",
  "Sisters",
  "Smile",
  "Society",
  "Solve",
  "Speed",
  "Spiritual",
  "Sports",
  "Stand Up",
  "Staying",
  "Streets",
  "Strength",
  "Stress",
  "Struggling",
  "Stuck",
  "Studying",
  "Success",
  "Suffer",
  "Supportive",
  "Suppose",
  "Surprised",
  "Sympathy",
  "Systems",
  "Table",
  "Talent",
  "Taste",
  "Taxes",
  "Teacher",
  "Teachers",
  "Teaching",
  "Teammates",
  "Technology",
  "Teenager",
  "Tennis",
  "Terrorism",
  "Test",
  "Texas",
  "Theater",
  "Theatre",
  "Theory",
  "They Say",
  "Thinks",
  "Time",
  "Tired",
  "To Be Honest",
  "Together",
  "Tomorrow",
  "Tool",
  "Tools",
  "Touch",
  "Tough",
  "Touring",
  "Town",
  "Track",
  "Trade",
  "Tradition",
  "Traditional",
  "Train",
  "Trained",
  "Travel",
  "Traveling",
  "Tree",
  "Trouble",
  "Truly",
  "Trust",
  "Truth",
  "Truth",
  "Type",
  "Typical",
  "Ugly",
  "Ultimate",
  "Unbelievable",
  "Uncomfortable",
  "Understanding",
  "Unfortunately",
  "Unique",
  "Unity",
  "Universal",
  "Universe",
  "Unusual",
  "Upset",
  "Urban",
  "Valley",
  "Valuable",
  "Value",
  "Variety",
  "Victims",
  "Victory",
  "Village",
  "Violence",
  "Virtue",
  "Vision",
  "Visit",
  "Visual",
  "Vital",
  "Voices",
  "Vulnerable",
  "Wait",
  "Waiting",
  "Walk",
  "Walking",
  "Walks",
  "Wall",
  "Walls",
  "Wanted",
  "War",
  "Wars",
  "Washington",
  "Waste",
  "Water",
  "Wave",
  "Weakness",
  "Wealth",
  "Wealthy",
  "Weapons",
  "Weather",
  "Wedding",
  "Wedding",
  "Weekend",
  "Weight",
  "Weird",
  "Welcome",
  "Welfare",
  "West",
  "White House",
  "Why",
  "Wife",
  "Wild",
  "Win",
  "Wind",
  "Wine",
  "Winning",
  "Winter",
  "Wisdom",
  "Wisdom",
  "Wise",
  "Wish",
  "Within",
  "Without",
  "Woman",
  "Women",
  "Wonder",
  "Words",
  "Work",
  "Work Out",
  "Working",
  "Workout",
  "World",
  "Worry",
  "Worrying",
  "Worthy",
  "Wow",
  "Writer",
  "Writers",
  "Writing",
  "Writing",
  "Written",
  "Wrong",
  "Year",
  "Yes",
  "Young",
  "Youtube",
  "Zero",
];
export { TopicsData };
