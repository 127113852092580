import React from "react";
import Navbar from "../component/navbar/Navbar";
import { Stack, Typography } from "@mui/material";
import Footer from "../component/Footer/Footer";

const data = [
  {
    heading: "Information Collection",
    para: "We collect information from you when you voluntarily provide such information, such as when you register on our site, submit quotes, or contact us with inquiries. The personal information we collect may include your name, email address, and other contact details.",
  },
  {
    heading: "Use of Information",
    para: "The information we collect from you may be used in one of the following ways: \n To personalize your experience on our site and to allow us to deliver the type of content in which you are most interested.\n To improve our website based on the feedback and interactions you provide.\n To administer a contest, promotion, survey, or other site feature.\n To send periodic emails regarding your submissions or other products and services.",
  },

  {
    heading: "Protection of Information",
    para: "We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.",
  },
  {
    heading: "Disclosure of Information",
    para: "We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless you explicitly grant us permission to do so. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.",
  },
  {
    heading: "Third Party Links",
    para: "Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.",
  },
  {
    heading: "Consent and Changes to our Privacy Policy",
    para: "By using our site, you consent to our privacy policy. If we decide to change our privacy policy, we will post those changes on this page.",
  },
  {
    heading: "Contacting Us",
    para: "If there are any questions regarding this privacy policy, you may contact us using the information below:",
    email: "support@preachingquotes.com",
  },
];

const Privacy_Policy = () => {
  return (
    <main>
      <Navbar />
      <section>
        <Stack px={3} gap={2} py={5} maxWidth={1000} mx={"auto"}>
          <Typography variant="h2">Privacy Policy</Typography>
          <p>
            Welcome to PreachingQuotes.com. We are committed to protecting your
            privacy. This policy explains how we collect, use, and disclose
            personal information that you provide while using
            PreachingQuotes.com. By using our website, you agree to the
            collection and use of information in accordance with this policy.
          </p>

          <Stack px={2}>
            <ol>
              {data.map((e, i) => (
                <li key={i} style={{ padding: `10px 5px` }}>
                  <Typography variant="h6">{e.heading}</Typography>
                  {e.para.split("\n").map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                  ))}
                  {e.email && (
                    <Typography py={2}>
                      <a href={`mailto:${e.email}`}>{e.email}</a>
                    </Typography>
                  )}
                </li>
              ))}
            </ol>
          </Stack>
        </Stack>
      </section>
      <Footer />
    </main>
  );
};

export default Privacy_Policy;
