import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
// import { useSelector } from "react-redux"
import { BsFillCartFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { success_toast_message } from "../../utils/toast_message";
import CustomButton from "../common/Button/Button";
import Input from "../common/Input/Input";
import logo from "../../assets/logo.png";
import { CiUser } from "react-icons/ci";
import { themeGray, themeOrange } from "../../utils/colorTheme";

import { FaSearch } from "react-icons/fa";
import CustomModal from "../Modal/CustomModal";
import { useState } from "react";
import ButtonComp from "../common/ButtonComp";
import { loginAsync, registerAsync } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setModalState } from "../../store/slices/user_auth_slice";
import { exit_session } from "../../config/apiHandle/apiHandle";
import { searchBy } from "../../store/constants";
import { setQuotesBySearchStatus } from "../../store/slices/quoteSlice";

const drawerWidth = 240;

const navItemss = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "Topics",
    link: "/topics",
  },
  {
    page: "Authors",
    link: "/authors",
  },
  {
    page: "Bible",
    link: "/bible",
  },
  {
    page: "Slide Maker",
    link: "/make-your-own",
  },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const [loginData, setLoginData] = React.useState();
  const [registerData, setRegisterData] = React.useState();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  // const { userAuth } = useSelector((state) => state.auth)

  const submit = () => {
    // exit_session()
    success_toast_message("Logout successfully");
  };
  const search = (e) => {
    e.preventDefault();
    let obj = {
      query: searchBy.ALL,
      data,
    };
    dispatch(setQuotesBySearchStatus(asyncStatus.IDLE));
    navigate("/search", { state: obj });
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography onClick={() => navigate("/")} variant="h6" sx={{ my: 2 }}>
        <img
          style={{ objectFit: "cover", width: "60%", cursor: "pointer" }}
          src={logo}
        />
      </Typography>
      <Divider />
      <List>
        {navItemss.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.link)}
              sx={{ textAlign: "center", color: "black", cursor: "pointer" }}
            >
              <ListItemText primary={item.page} />
            </ListItemButton>
          </ListItem>
        ))}
        <Stack
          spacing={2}
          style={{ flexWrap: "wrap", justifyContent: "space-between" }}
        >
          <>
            <Stack justifyContent={"center"} alignItems="center">
              <CustomButton>Login</CustomButton>
            </Stack>
          </>
        </Stack>
      </List>
    </Box>
  );
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(setModalState(false));
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const dispatch = useDispatch();

  const { login_status, login_data, login_error, modalState, userAuth } =
    useSelector((state) => state.userAuth);
  React.useEffect(() => {
    if (modalState) handleOpen();
  }, [modalState]);
  useEffect(() => {
    if (userAuth) {
      setOpen(false);
    }
  }, [, userAuth]);
  return (
    <Box sx={{ display: "flex", color: "black" }} mt={2}>
      <CustomModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        children={
          login ? (
            <Stack gap={3}>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                Login
              </Typography>
              <Input
                id={"email"}
                type={"text"}
                onChange={(e) =>
                  setLoginData({ ...loginData, [e.target.id]: e.target.value })
                }
                style={{
                  padding: "10px 10px 10px 15px",
                  borderRadius: "86x",
                  backgroundColor: "#EBEBEB",
                  border: "none",
                }}
                placeholder={"Enter email"}
              />
              <Input
                id={"password"}
                type={"password"}
                onChange={(e) =>
                  setLoginData({ ...loginData, [e.target.id]: e.target.value })
                }
                style={{
                  padding: "10px 10px 10px 15px",
                  borderRadius: "86x",
                  backgroundColor: "#EBEBEB",
                  border: "none",
                }}
                placeholder={"Enter password"}
              />
              {login_status === asyncStatus.ERROR && (
                <Typography color={"red"} fontSize={12}>
                  {login_error}
                </Typography>
              )}
              <ButtonComp
                disabled={login_status === asyncStatus.LOADING}
                onClick={() => {
                  dispatch(loginAsync(loginData));
                }}
                label={"Login"}
              />
              <Typography
                fontSize={14}
                color={"blue"}
                onClick={() => setLogin(false)}
                sx={{ cursor: "pointer" }}
              >
                Dont Have Account? Create Now
              </Typography>
            </Stack>
          ) : (
            <Stack gap={3}>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                Signup
              </Typography>

              <Input
                id={"email"}
                type={"text"}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    [e.target.id]: e.target.value,
                  })
                }
                style={{
                  padding: "10px 10px 10px 15px",
                  borderRadius: "86x",
                  backgroundColor: "#EBEBEB",
                  border: "none",
                }}
                placeholder={"Enter email"}
              />

              <Input
                id={"password"}
                type={"password"}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    [e.target.id]: e.target.value,
                  })
                }
                style={{
                  padding: "10px 10px 10px 15px",
                  borderRadius: "86x",
                  backgroundColor: "#EBEBEB",
                  border: "none",
                }}
                placeholder={"Enter password"}
              />
              <ButtonComp
                onClick={() =>
                  dispatch(
                    registerAsync({ ...registerData, user_role: "user" })
                  )
                }
                label={"Signup"}
              />
              <Typography
                fontSize={14}
                color={"blue"}
                onClick={() => setLogin(true)}
                sx={{ cursor: "pointer" }}
              >
                Already have an Account?
              </Typography>
            </Stack>
          )
        }
      />
      <CssBaseline />
      <AppBar
        component="nav"
        color="inherit"
        position="static"
        sx={{ boxShadow: "none" }}
      >
        {/* <Stack justifyContent={"flex-start"}>
                </Stack> */}
        <Stack
          px={3}
          className="sethampburger"
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          {/* <Toolbar > */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className="hamBurger"
            onClick={handleDrawerToggle}
            // sx={{ mr: 2, display: { sm: 'none' } }}
            sx={{
              display: "none",
              pl: 1,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            onClick={() => navigate("/")}
            sx={{ display: { md: "block", sm: "none", xs: "none" } }}
          >
            <img
              style={{ objectFit: "cover", width: "180px", cursor: "pointer" }}
              src={logo}
            />
          </Typography>
          <Stack
            sx={{
              flexGrow: {
                xs: 1,
                sm: 1,
                md: 0,
              },
            }}
            direction="row"
            alignItems="center"
            gap={2}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "9px",
                gap: 2,
                display: {
                  xs: "none",
                  sm: "none",

                  md: "flex",
                  color: "black",
                  flexDirection: "row",
                },
              }}
            >
              {navItemss.map((item, index) => (
                <Typography
                  onClick={() => navigate(item.link)}
                  sx={{
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <Typography fontWeight={"bold"}>{item.page}</Typography>
                </Typography>
              ))}
            </Stack>
            {/* <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "9px",
                gap: 2,
                width: "100%",
                display: {
                  xs: "flex",
                  sm: "flex",

                  md: "none",
                  lg: "none",
                  xl: "none",
                  color: "black",
                  flexDirection: "row",
                },
              }}
            >
              <img style={{ objectFit: "cover", width: "150px" }} src={logo} />
            </Stack> */}

            {/* CART */}

            {/* <Stack position={"relative"} className="cart">
            <Stack justifyContent={"center"} alignItems="center">
                <CiUser  size={25} color={themeOrange} />
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack justifyContent={"center"} alignItems="center">
                <CustomButton >
                  Login
                </CustomButton>
              </Stack>
            </Stack> */}

            <Stack
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            ></Stack>
          </Stack>
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "none",

                md: "flex",
                color: "black",
                flexDirection: "row",
              },
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <form onSubmit={search}>
              <Stack position={"relative"}>
                <Input
                  onChange={(e) => setData(e.target.value)}
                  placeholder={"Search here"}
                  style={{
                    borderRadius: 45,
                    outlined: "none",
                    border: "none",
                    backgroundColor: themeGray,
                  }}
                />
                <Button
                  sx={{
                    position: "absolute",
                    top: "30%",
                    right: -7,
                    top: 7,
                    cursor: "pointer",
                    p: 0,
                    ":hover": {
                      background: "none",
                    },
                  }}
                  type="submit"
                >
                  <FaSearch size={20} color={"#686565"} />
                </Button>
              </Stack>
            </form>
          </Stack>

          <Stack
            position={"relative"}
            direction={"row"}
            gap={2}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
              },
            }}
          >
            {!userAuth ? (
              <Stack
                justifyContent={"center"}
                onClick={() => setOpen(true)}
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <CiUser size={25} color={themeOrange} />
              </Stack>
            ) : (
              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={exit_session}
              >
                Logout
              </Button>
            )}
            {/* <Divider orientation="vertical" variant="middle" flexItem />
            <Stack justifyContent={"center"} alignItems="center">
              <CiHeart size={25} color={themeOrange} />
            </Stack> */}
          </Stack>
        </Stack>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default Navbar;
