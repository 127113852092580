import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
import "../index.css";
import Navbar from "../component/navbar/Navbar";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../assets/logo.png";
import {
  themeDarkBlue,
  themeDarkBlueHover,
  themeGray,
} from "../utils/colorTheme";
import { FaChevronLeft, FaSearch } from "react-icons/fa";
import Input from "../component/common/Input/Input";
import { CgMenuGridR } from "react-icons/cg";
import { FaChevronDown } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./Home.css";
import AuthorCard from "../component/AuthorCard/AuthorCard";
import QuoteCard from "../component/common/QuoteCard/QuoteCard";
import backImg from "../assets/quoteImg.jpg";
import Footer from "../component/Footer/Footer";
import { authorName } from "../utils/AuthorName";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAuthors,
  getQuoteByID,
  getQuoteBySearch,
  getQuotes,
} from "../services/QuotesService";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import { asyncStatus, searchBy } from "../store/constants";
import CustomButton from "../component/common/Button/Button";
import {
  setQuotesBySearchStatus,
  setSearchByKey,
  setSearchDataState,
  setSearchState,
} from "../store/slices/quoteSlice";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Slider from "react-slick";
import QuoteDetailCard from "../component/QuoteDetailCard/QuoteDetailCard";
import ad_img from "../assets/ad_img.jpg";
import { FEATURED_QUOTES_DATA } from "../utils/FeaturedQuotesData";
import { AuthorsData } from "../utils/AUTHORS_DATA";
const CustomNextArrow = (props) => (
  <Stack className="handleBtn">
    <FaChevronLeft
      style={{ zIndex: 1000, cursor: "pointer" }}
      color="white"
      onClick={props.onClick}
      size={20}
    />
  </Stack>
);

const CustomPrevArrow = (props) => (
  <Stack className="handleBtn">
    <FaChevronRight
      style={{ zIndex: 1000, cursor: "pointer" }}
      color="white"
      onClick={props.onClick}
      size={20}
    />
  </Stack>
  // <FaChevronRight className="handleBtn" onClick={props.onClick} size={15} />
);
function Home() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [bothOrEither, setBothOrEither] = useState("both");

  const [data, setData] = useState();
  const disptach = useDispatch();
  const {
    getQuotesData,
    getQuotesStatus,
    getQuotesError,
    searchByKey,
    quotesBySearchStatus,
    quotesBySearchData,
    quotesBySearchError,
    getAuthorsStatus,
    getAuthorsError,
    getAuthorsData,
    quotesByIdStatus,
  } = useSelector((state) => state.quotes);
  const { userAuth, userData } = useSelector((state) => state.userAuth);
  const quoteLoading = getQuotesStatus === asyncStatus.LOADING;
  const sliderRef = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    // nextArrow: <CustomNextArrow />,
    // prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [currentImage, setCurrentImage] = useState(quoteBackgroundImgList[0]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    disptach(getQuotes(1));
  }, []);
  useEffect(() => {
    if (quotesBySearchStatus === asyncStatus.SUCCEEDED) {
      disptach(setSearchState());
      disptach(setSearchDataState());
    }
  }, [quotesBySearchStatus]);

  const navigateHandler = (_id, index) => {
    localStorage.setItem("currentImgIndex", index);
    navigate(`/product/${_id}`);
  };
  // console.log("searchByKey====================",searchBy)
  const search = (e) => {
    e.preventDefault();
    let firstLetterCapital =
      searchByKey?.toLowerCase()?.charAt(0).toUpperCase() +
      searchByKey?.toLowerCase()?.slice(1);
    console.log(firstLetterCapital);
    if (firstLetterCapital === searchBy.ALL) {
      let obj = {
        query: searchBy.ALL,
        data,
        bothOrEither,
      };
      disptach(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
    } else if (firstLetterCapital === searchBy.AUTHOR) {
      let obj = {
        query: searchBy.AUTHOR,
        data,
      };
      disptach(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/authors");
    } else if (firstLetterCapital === searchBy.TOPIC) {
      let obj = {
        query: searchBy.TOPIC,
        data,
      };
      disptach(setQuotesBySearchStatus(asyncStatus.IDLE));
      navigate("/search", { state: obj });
      // navigate("/topics");
    }
  };
  let imageArray = [
    require("../assets/cover.jpg"),
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1710388926/Group_17_praayi.png",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1710388924/Group_16_lxbiag.png",
  ];
  const [selectedQuote, setSelectedQuote] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    disptach(getAllAuthors());
    let obj = {
      id: "6626fe7bfa537936aa24342c",
      // id: "6626fe4afa537936aa23805e",
      // https://preaching-quote.web.app/product/65e1011a5927f0b2dafea274
      userId: userAuth ? userData?._id : "",
    };
    disptach(getQuoteByID(obj));
  }, []);
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // const [searchBy, setSearchBy] = useState("topic");

  // const handleSearchByChange = (event) => {
  //   setSearchBy(event.target.value);
  // };

  const [shuffledAuthorsData, setShuffledAuthorsData] = useState([]);

  // Original AuthorsData array
  // const AuthorsData = [...]; // Your original array here

  useEffect(() => {
    // Shuffle the AuthorsData array and store it in shuffledAuthorsData state
    const shuffledArray = shuffleArray(AuthorsData);
    setShuffledAuthorsData(shuffledArray);
  }, []);

  // Function to shuffle the array without modifying the original array
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };



  return (
    <div>
      <Navbar />

      {/* Section 01 */}

      <Stack width={1} mt={1}>
        <Stack
          padding={{ xl: 1, lg: 1, md: 2, sm: 2, xs: 1 }}
          alignItems={"center"}
          mt={-6}
        >
          <Stack width={"55%"} paddingY={1}>
            <Stack
              padding={10}
              paddingBottom={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img src={Logo} className={"image-responsive"} />
            </Stack>
            <form onSubmit={search}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Stack width={"100%"} position={"relative"}>
                  <Input
                    placeholder={"Search Quotes..."}
                    style={{
                      width: "100%",
                      borderRadius: 45,
                      outlined: "none",
                      border: "none",
                      backgroundColor: themeGray,
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                    onChange={(e) => setData(e.target.value)}
                  />
                  <Button
                    sx={{
                      position: "absolute",
                      top: "30%",
                      right: 0,
                      cursor: "pointer",
                      p: 0,
                      ":hover": {
                        background: "none",
                      },
                    }}
                    type="submit"
                  >
                    <FaSearch size={20} color={"#686565"} />
                  </Button>
                </Stack>
              </Stack>
            </form>
            {searchByKey?.charAt(0).toUpperCase() + searchByKey?.slice(1) ===
              "ALL" &&
              data?.includes(" ") && (
                <Stack
                  component={"form"}
                  width={"100%"}
                  // sx={{ backgroundColor: "white", border: "1px solid black" }}
                  // borderRadius={5}
                  marginY={1}
                  direction={"row"}
                  mb={4}
                  justifyContent={"start"}
                >
                  <RadioGroup
                    row
                    aria-label="bothEither"
                    name="bothEither"
                    value={bothOrEither}
                    //  defaultValue={searchBy.TOPIC.toLocaleUpperCase()}
                    onChange={(e) => {
                      setBothOrEither(e.target.value);
                    }}
                  >
                    <Stack
                      direction={"row"}
                      style={{ cursor: "pointer" }}
                      alignItems={"center"}
                    >
                      <Radio value={"both"} label="Soft" variant="soft" />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {/* {e.charAt(0).toUpperCase() + e.slice(1)} */}
                        Both
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      style={{ cursor: "pointer" }}
                      alignItems={"center"}
                    >
                      <Radio value={"either"} label="Soft" variant="soft" />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {/* {e.charAt(0).toUpperCase() + e.slice(1)} */}
                        Either
                      </Typography>
                    </Stack>
                  </RadioGroup>
                </Stack>
              )}

            <Stack
              p={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 0 }}
              component={"form"}
              width={"100%"}
              sx={{ backgroundColor: "white", border: "1px solid black" }}
              borderRadius={5}
              marginY={1}
              direction={"row"}
              mb={4}
              justifyContent={"center"}
            >
              <RadioGroup
                row
                aria-label="searchBy"
                name="searchBy"
                value={
                  searchByKey
                    ? searchByKey?.charAt(0).toUpperCase() +
                    searchByKey?.slice(1)
                    : "TOPIC"
                }
                defaultValue={searchBy.TOPIC.toLocaleUpperCase()}
                onChange={(e) => {
                  disptach(setSearchByKey(e.target.value));
                  console.log(e.target.value);
                }}
              >
                {Object.keys(searchBy).map((e) => {
                  return (
                    <Stack
                      direction={"row"}
                      style={{ cursor: "pointer" }}
                      alignItems={"center"}
                    >
                      <Radio
                        value={e.charAt(0).toUpperCase() + e.slice(1)}
                        label="Soft"
                        variant="soft"
                      />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {/* {e.charAt(0).toUpperCase() + e.slice(1)} */}
                        {e.toLowerCase()}
                      </Typography>
                    </Stack>
                  );
                })}
              </RadioGroup>
            </Stack>
          </Stack>
          {/* Section 01 */}

          {/* second section quote work */}
          <Container maxWidth={"xl"}>
            {quotesByIdStatus === asyncStatus.IDLE ||
              quotesByIdStatus === asyncStatus.LOADING ? (
              <Stack
                height={200}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Stack alignItems={"center"} width={1}>
                <QuoteDetailCard userAccessDenied={true} />
              </Stack>
            )}
          </Container>



          <Container maxWidth={"xl"}>
            <Stack alignItems={"center"} my={3}>
              <Stack
                sx={{
                  width: {
                    xl: "970px",
                    lg: "970px",
                    md: "970px",
                    sm: "300px",
                    xs: "300px",
                  },
                  height: {
                    xl: "250px",
                    lg: "250px",
                    md: "250px",
                    sm: "100px",
                    xs: "100px",
                  },
                }}
                alignItems={"center"}
              >
                {/* <Stack>asdsadsad</Stack> */}
                <img
                  width={"100%"}
                  style={{ objectFit: "contain" }}
                  src={ad_img}
                />
              </Stack>
            </Stack>
          </Container>

          {/* Fourth Section */}

          {/* Fifth Section */}

          <Stack width={"100%"}>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                {/* Featured Preaching Slides */}
                Featured Quotes
              </Typography>
              {/* <Divider sx={{height:"2px"}}/> */}
            </Stack>
            {/* <Typography sx={{ fontSize: 16 }}>
              Share our pictorial quotes on Facebook, Twitter, and Instagram.
            </Typography> */}
            <Grid container>
              {quoteLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Stack p={2} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                  </Stack>
                </Grid>
              ) : getQuotesError ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Stack p={2} justifyContent={"center"} alignItems={"center"}>
                    <Typography>
                      Something went wrong please try again{" "}
                      <Button onClick={disptach(getQuotes(1))}>Reload</Button>
                    </Typography>
                  </Stack>
                </Grid>
              ) : (
                getQuotesData &&
                getQuotesData.length &&
                FEATURED_QUOTES_DATA.map(
                  (e, i) =>
                    i < 4 && (
                      <Grid
                        item
                        xs={12}
                        p={2}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        key={i}
                      >
                        <QuoteCard
                          onClick={() => navigateHandler(e.data._id, i)}
                          data={e.data}
                          img={quoteBackgroundImgList[i]}
                        />
                        <Divider />
                      </Grid>
                    )
                )
              )}
            </Grid>
            <Stack alignItems={"center"} justifyContent={"center"} my={3}>
              <CustomButton
                onClick={() => navigate("/featured-quotes")}
                style={{ padding: 20 }}
                children={"View More"}
              />
            </Stack>
          </Stack>

          {/* Fifth Section */}

          {/* Sixth Section */}


          <Container maxWidth={"xl"}>
            <Stack alignItems={"center"} my={3}>
              <Stack
                sx={{
                  width: {
                    xl: "970px",
                    lg: "970px",
                    md: "970px",
                    sm: "300px",
                    xs: "300px",
                  },
                  height: {
                    xl: "250px",
                    lg: "250px",
                    md: "250px",
                    sm: "100px",
                    xs: "100px",
                  },
                }}
                alignItems={"center"}
              >
                {/* <Stack>asdsadsad</Stack> */}
                <img
                  width={"100%"}
                  style={{ objectFit: "contain" }}
                  src={ad_img}
                />
              </Stack>
            </Stack>
          </Container>
          {/* Sixth Section */}

          {/* Seventh Section */}

          <Stack
            width={"100%"}
            marginY={2}
            sx={{
              backgroundColor: "white",
              border: "1px solid black",
              boxShadow: "1px 1px 20px gray",
              borderRadius: 5,
            }}
            padding={"20px"}
          >
            <Typography variant="h5" p={1} fontWeight={"bold"}>
              {/* Explore Inspirational Quotes by Famous Authors */}
              Explore More Quotes By Our Featured Authors
            </Typography>
            <Grid container spacing={1}>
              {shuffledAuthorsData.slice(0, 12)?.map((e, i) => (

                <Grid item xl={3} lg={3} md={3} sm={6} xs={6} key={i}>
                  <ul style={{ padding: 1 }}>
                    <li
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        margin: "5px 0px",
                        color: themeDarkBlue,
                      }}
                      onClick={() => navigate(`/quote-by-author/${e}`)}
                    >
                      {e}
                    </li>
                  </ul>
                </Grid>

              ))}
            </Grid>
            {/* <Grid container spacing={1}>
              {getAuthorsData?.Authors?.map((e, i) => (
                <Grid item xl={3} lg={3} md={3} sm={6} xs={6} key={i}>
                  <ul style={{ padding: 1 }}>
                    <li
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        margin: "5px 0px",
                        color: themeDarkBlue,
                      }}
                      onClick={() => navigate(`/quote-by-author/${e.Author}`)}
                    >
                      {e.Author}
                    </li>
                  </ul>
                </Grid>
              ))}
            </Grid> */}
          </Stack>
          {/* Seventh Section */}

          {/* Eighth Section */}

          <Container maxWidth={"xl"}>
            <Stack alignItems={"center"} my={3}>
              <Stack
                sx={{
                  width: {
                    xl: "970px",
                    lg: "970px",
                    md: "970px",
                    sm: "300px",
                    xs: "300px",
                  },
                  height: {
                    xl: "250px",
                    lg: "250px",
                    md: "250px",
                    sm: "100px",
                    xs: "100px",
                  },
                }}
                alignItems={"center"}
              >
                {/* <Stack>asdsadsad</Stack> */}
                <img
                  width={"100%"}
                  style={{ objectFit: "contain" }}
                  src={ad_img}
                />
              </Stack>
            </Stack>
          </Container>
          {/* Eighth Section */}
        </Stack>
      </Stack>
      <Footer />
    </div>
  );
}

export default Home;
