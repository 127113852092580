import {
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  Slider as MuiSlider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import setBG from "../assets/setBG.jpg";
import sampleBG from "../assets/sampleBG.jpg";
import { themeDarkBlue, themeGray } from "../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getQuoteByID } from "../services/QuotesService";
import SelectComp from "../component/common/selectComp/SelectComp";
import { asyncStatus } from "../utils/asyncStatus";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "react-slick";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { quoteBackgroundImgList } from "../utils/quoteBackgroundImgList";
import FileUpload from "../component/FileUploadInput/FileUpload";
import html2canvas from "html2canvas";
import ShareButtons from "../utils/ShareButtons";
const fontWeightArray = ["Regular", "Bold", "Italic", "Bold Italic"];

const CustomNextArrow = (props) => (
  <Stack className="handleBtn">
    <FaChevronLeft
      style={{ zIndex: 1000, cursor: "pointer" }}
      color="white"
      onClick={props.onClick}
      size={20}
    />
  </Stack>
);

const CustomPrevArrow = (props) => (
  <Stack className="handleBtn">
    <FaChevronRight
      style={{ zIndex: 1000, cursor: "pointer" }}
      color="white"
      onClick={props.onClick}
      size={20}
    />
  </Stack>
  // <FaChevronRight className="handleBtn" onClick={props.onClick} size={15} />
);

function SelectBGPic() {
  const imagesArray = [
    // "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338176/fotor-ai-20240103519_uih6d6.jpg",
    // "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338179/fotor-ai-20240103621_hhxibw.jpg",
    // "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338178/fotor-ai-20240103526_naey0j.jpg",
    // "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338181/fotor-ai-202401033282_zjkcuz.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338317/fotor-ai-2024010333135_iyj9sb.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338182/fotor-ai-202401033546_kstetp.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338183/fotor-ai-202401034013_knbsrp.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338184/fotor-ai-202401034128_yhpjpb.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338313/fotor-ai-202401035341_ltcqoh.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338314/fotor-ai-202401035258_qp9xeg.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338316/fotor-ai-202401035475_rngqph.jpg",
    "https://res.cloudinary.com/dpfni06vw/image/upload/v1708338316/fotor-ai-202401035564_bzz9ym.jpg",
  ];

  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(imagesArray[0]);
  const [svgImage, setSvgImage] = useState(null);
  const [option, setOption] = useState("meme");
  const [isLoading, setIsLoading] = useState(false);

  const [textStyling, setTextStyling] = useState({
    fontWeight: "normal",
    fontSize: 20,
    availabeFontsArray: [
      "Montserrat",
      "Latin Modern Roman Bold",
      "Segoe UI",
      "AftaSansThin-Regular",
      "AlexBrush-Regular",
      "Canterbury",
      "DroidSerif",
      "AlfaSlabOne",
      "Courier Prime",
      "GOODDP",
      "GreatVibes",
      "LiberationSans",
      "monofonto",
      "MouseMemoirs",
      "NunitoSans",
      "PORKYS",
    ],
    fontFamily: "Montserrat",
    fontColor: "#fff",
    fontStyle: "normal",
  });

  const [quoteTextStyling, setQuoteTextStyling] = useSearchParams({
    fontWeight: "normal",
    fontSize: 20,
    fontFamily: "Montserrat",
    fontColor: "#fff",
    fontStyle: "normal",
    top: 0,
    left: 0,
  });
  const [aTextStyling, setATextStyling] = useSearchParams({
    afontWeight: "normal",
    afontSize: 20,
    afontFamily: "Montserrat",
    afontColor: "#fff",
    afontStyle: "normal",
    atop: 120,
    aleft: 265,
  });

  //QUOTE
  const quoteFontWeight = quoteTextStyling.get("fontWeight");
  const quotefontSize = quoteTextStyling.get("fontSize");
  const quoteFontFamily = quoteTextStyling.get("fontFamily");
  const quoteFontColor = quoteTextStyling.get("fontColor");
  const quoteTop = quoteTextStyling.get("top");
  const quoteLeft = quoteTextStyling.get("left");
  //AUTHOR
  const authorFontWeight = aTextStyling.get("afontWeight");
  const authorfontSize = aTextStyling.get("afontSize");
  const authorFontFamily = aTextStyling.get("afontFamily");
  const authorFontColor = aTextStyling.get("afontColor");
  const authorTop = aTextStyling.get("atop");
  const authorLeft = aTextStyling.get("aleft");
  //DIMENSIONS
  const [imgdimensions, setImgDimensions] = useSearchParams({
    dimensions: "16:9",
  });
  const imgDimension = imgdimensions.get("dimensions");

  //Current Image
  const [activeImg, setActiveImg] = useSearchParams({
    index: imagesArray[0],
  });
  const currentImg = activeImg.get("index");

  const fetchAndConvert = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    const fetchAndConvertImage = async (imageUrl) => {
      try {
        const base64data = await fetchAndConvert(imageUrl);
        setSvgImage(base64data);
      } catch (error) {
        console.error("Error fetching and converting image:", error);
      }
    };

    fetchAndConvertImage(currentImg);
  }, [currentImg]);

  function exportAsImage() {
    setIsLoading(true);
    const contentElement = document.getElementById("myContent");

    html2canvas(contentElement, { backgroundColor: null }).then((canvas) => {
      const dataUrl = canvas.toDataURL();

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "your_image.png";
      link.click();
      setIsLoading(false);
    });
  }

  // console.log("aaaaaaaaaa", textStyling?.fontFamily);

  const params = useParams();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { id } = params;

  const { quotesByIdData, quotesByIdStatus, quotesByIdError } = useSelector(
    (state) => state.quotes
  );
  const loading = quotesByIdStatus === asyncStatus.LOADING;
  const isMeme = option === "meme";
  // getQuoteByID(id)

  useEffect(() => {
    let obj = {
      id,
      user: "",
    };
    dispatch(getQuoteByID(obj));
    window.scrollTo(0, 0);
  }, []);

  // ==============================Slick

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const [localImg, setlocalImg] = useState("");

  // const [imgData, setImgData] = useState("");

  const fileHandler = (e) => {
    //GET IMAGE LOCALLY
    // setImgData(e.target.files[0]);
    // setSignUpData({ ...signUpData, logoImg: e.target.files[0] });
    if (e.target.files[0]) {
      setlocalImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [imageWidth, setImageWidth] = useState();
  const url = window.location.href;
  useEffect(() => {
    if (!loading) {
      let a = document.getElementById("myContent");

      setImageWidth(a?.parentElement?.offsetWidth);
    }
  }, [, loading]);
  const [textPositionHandle, setTextPositionHandle] = useState(true);
  useEffect(() => {
    if (textPositionHandle) {
      console.log("adssssssssssssssssssssssssssssssssssssssssssssssss TRUE");

      let a = document.getElementById("myContent");
      let height = a?.parentElement?.offsetHeight;
      console.log("HEIGHT==========>", height);

      setTextPositionHandle(false);
    }

    return () => {
      setTextPositionHandle(true);
    };
  }, [imageWidth]);
  return (
    <>
      <Navbar />

      {loading ? (
        <Stack height={500} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
        </Stack>
      ) : quotesByIdError ? (
        <Typography>
          Something went wrong{" "}
          <Button onClick={() => dispatch(getQuoteByID(id))}>Reload</Button>
        </Typography>
      ) : (
        quotesByIdData && (
          <Container width="100%">
            <Stack alignItems="center" justifyContent="center" mb={1}>
              <Typography style={{ fontSize: 26, fontWeight: "bold" }}>
                {/* Select the Background you want */}
                Slide Maker
              </Typography>
              {/* <Typography style={{ fontSize: 16 }}>
                Share our pictorial quotes on Facebook, Twitter, and Instagram.
              </Typography> */}
            </Stack>
            <Grid container spacing={5}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Stack
                  //   justifyContent="center"
                  alignItems="center"
                  //   mt={4}
                  style={{ cursor: "pointer" }}
                  //   width={1}
                >
                  <Stack
                    id="myContent"
                    style={{
                      width: "100%", // Fixed width of 500px
                      maxWidth: "500px",

                      height:
                        imgDimension === "16:9"
                          ? `${imageWidth * (9 / 16)}px` // 500 * (9/16) = 281.25
                          : imgDimension === "1:1"
                          ? `${imageWidth}px` // 1:1 ratio maintains height same as width
                          : imgDimension === "4:3"
                          ? `${imageWidth * (3 / 4)}px` // 500 * (3/4) = 375
                          : imgDimension === "3:4"
                          ? `${imageWidth * (4 / 3)}px` // 500 * (4/3) = 666.67
                          : imgDimension === "9:16"
                          ? `${imageWidth * (16 / 9)}px` // 500 * (16/9) = 888.89
                          : "0px",
                      backgroundImage: `url(${svgImage})`,
                      backgroundSize: "cover",
                      backgroundColor: "rgba(255, 255, 255, 0)",

                      objectFit: "contain",
                      borderRadius: 20,
                      padding: 30,
                    }}
                  >
                    <Stack position={"relative"} sx={{ width: 1, height: 1 }}>
                      <Typography
                        variant="p"
                        style={{
                          color: `${quoteFontColor}`,
                          fontSize: `${quotefontSize}px`,
                          textAlign: "center",
                          position: "absolute",
                          top: `${quoteTop}px`,
                          left: `${quoteLeft}px`,
                          fontFamily: `${quoteFontFamily}`,

                          fontWeight:
                            quoteFontWeight === "Regular"
                              ? 400
                              : quoteFontWeight === "Bold" ||
                                quoteFontWeight === "Bold Italic"
                              ? 700
                              : 400,
                          fontStyle:
                            quoteFontWeight === "Italic"
                              ? "italic"
                              : quoteFontWeight === "Bold Italic"
                              ? "italic"
                              : "normal",
                        }}
                      >
                        {/* {quoteData?.quote} */}
                        {quotesByIdData?.Quote}
                      </Typography>
                      <Typography
                        style={{
                          color: `${authorFontColor}`,
                          fontSize: `${authorfontSize}px`,
                          textAlign: "center",
                          position: "absolute",
                          top: `${authorTop}px`,
                          left: `${authorLeft}px`,
                          fontFamily: `${authorFontFamily}`,

                          fontWeight:
                            authorFontWeight === "Regular"
                              ? 400
                              : authorFontWeight === "Bold" ||
                                authorFontWeight === "Bold Italic"
                              ? 700
                              : 400,
                          fontStyle:
                            authorFontWeight === "Italic" ||
                            authorFontWeight === "Bold Italic"
                              ? "italic"
                              : "normal",
                        }}
                      >
                        {`~ ${quotesByIdData?.Author}`}
                      </Typography>
                    </Stack>

                    {/* <Stack justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Typography variant="p" style={{ color: "white", fontSize: 14, textAlign: "end" }}>
                {quoteData?.author}
              </Typography>
            </Stack> */}
                  </Stack>
                </Stack>

                <Stack
                  sx={{ zIndex: 100 }}
                  className="leftSlide"
                  position={"relative"}
                >
                  <div
                    style={{
                      marginTop: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Slider ref={sliderRef} {...settings}>
                      {quoteBackgroundImgList.map((imageUrl, index) => (
                        <Stack
                          style={{ cursor: "pointer" }}
                          key={index}
                          gap={2}
                          spacing={4}
                          onClick={() =>
                            setActiveImg((prev) => {
                              prev.set("index", imageUrl);
                              return prev;
                            })
                          }
                        >
                          <img
                            width="100%"
                            height={"100px"}
                            style={{
                              border: `${
                                currentImg === imageUrl ? "3" : "0"
                              }px solid ${themeDarkBlue}`,
                              objectFit: "fill",
                              cursor: "pointer",
                            }}
                            src={imageUrl}
                            alt={`Background ${index}`}
                          />
                        </Stack>
                      ))}
                      {/* Add more images here */}
                    </Slider>
                  </div>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  // mt={2}
                  justifyContent={"center"}
                  gap={3}
                >
                  <Stack bgcolor={themeDarkBlue} borderRadius={"20px"} p={1}>
                    <CustomNextArrow onClick={prevSlide} />
                  </Stack>
                  <Stack bgcolor={themeDarkBlue} borderRadius={"20px"} p={1}>
                    <CustomPrevArrow onClick={nextSlide} />
                  </Stack>
                </Stack>
                <Stack alignItems={"flex-end"} mb={2} mt={3} my={2}>
                  {localImg && (
                    <img
                      width="100px"
                      height={"100px"}
                      style={{
                        objectFit: "fill",
                        cursor: "pointer",
                        border: `${
                          currentImg === localImg ? "3" : "0"
                        }px solid ${themeDarkBlue}`,
                      }}
                      src={localImg}
                      onClick={() =>
                        setActiveImg((prev) => {
                          prev.set("index", localImg);
                          return prev;
                        })
                      }
                      // alt={`Background ${index}`}
                    />
                  )}
                  <FileUpload
                    onChange={fileHandler}
                    sx={{
                      backgroundImage: `linear-gradient( 92.88deg,
                #455eb5 9.16%,
                #5643cc 43.89%,
                #673fd7 64.72%);`,
                      fontSize: 12,
                    }}
                  >
                    {localImg ? "Change Your Image" : "Add Your Image"}
                  </FileUpload>
                </Stack>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Stack>
                  <Typography
                    style={{
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Select Text
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={option}
                      onChange={(e) => setOption(e.target.value)}
                    >
                      <FormControlLabel
                        value="meme"
                        control={<Radio />}
                        label="Quote"
                      />
                      <FormControlLabel
                        value="author"
                        control={<Radio />}
                        label="Author"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Stack
                  width={"100%"}
                  alignItems={"start"}
                  justifyContent={"center"}
                >
                  <Typography
                    style={{
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Image Dimensions
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={imgDimension}
                      onChange={(e) =>
                        setImgDimensions((prev) => {
                          prev.set("dimensions", e.target.value);
                          return prev;
                        })
                      }
                    >
                      <FormControlLabel
                        value="16:9"
                        control={<Radio />}
                        label="16:9"
                      />
                      <FormControlLabel
                        value="4:3"
                        control={<Radio />}
                        label="4:3"
                      />
                      <FormControlLabel
                        value="1:1"
                        control={<Radio />}
                        label="1:1"
                      />
                      <FormControlLabel
                        value="3:4"
                        control={<Radio />}
                        label="3:4"
                      />
                      <FormControlLabel
                        value="9:16"
                        control={<Radio />}
                        label="9:16"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Stack
                  width={"100%"}
                  alignItems={"start"}
                  justifyContent={"center"}
                >
                  <Typography
                    style={{
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Position
                  </Typography>
                  <Stack
                    // width={{ xl: "50%", lg: "50%", md: "50%", sm: "60%", xs: "80%" }}
                    width={1}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Stack width={1}>
                      <Typography
                        style={{
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        Horizontal
                      </Typography>

                      <MuiSlider
                        sx={{ width: "90%", color: themeDarkBlue }}
                        // sx={{width:"90%"}}
                        aria-label="Temperature"
                        value={isMeme ? quoteLeft : authorLeft}
                        // getAriaValueText={valuetext}

                        max={245}
                        onChange={(e) => {
                          isMeme
                            ? setQuoteTextStyling((prev) => {
                                prev.set("left", e.target.value);
                                return prev;
                              })
                            : setATextStyling((prev) => {
                                prev.set("aleft", e.target.value);
                                return prev;
                              });
                        }}
                        color="secondary"
                      />
                    </Stack>
                    <Stack width={1}>
                      <Typography
                        style={{
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        Vertical
                      </Typography>

                      <MuiSlider
                        sx={{ width: "90%", color: themeDarkBlue }}
                        // sx={{width:"90%"}}
                        aria-label="Temperature"
                        value={isMeme ? quoteTop : authorTop}
                        // getAriaValueText={valuetext}
                        // max={245}
                        max={
                          imgDimension === "16:9"
                            ? 205 // 500 * (9/16) = 281.25
                            : imgDimension === "1:1"
                            ? 425 // 1:1 ratio maintains height same as width
                            : imgDimension === "4:3"
                            ? 300 // 500 * (3/4) = 375
                            : imgDimension === "3:4"
                            ? 600 // 500 * (4/3) = 666.67
                            : imgDimension === "9:16"
                            ? 745 // 500 * (16/9) = 888.89
                            : "0px"
                        }
                        onChange={(e) => {
                          isMeme
                            ? setQuoteTextStyling((prev) => {
                                prev.set("top", e.target.value);
                                return prev;
                              })
                            : setATextStyling((prev) => {
                                prev.set("atop", e.target.value);
                                return prev;
                              });
                        }}
                        color="secondary"
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Typography
                  style={{
                    textDecorationLine: "underline",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Text Styling
                </Typography>
                <Stack
                  // width={{ xl: "50%", lg: "50%", md: "50%", sm: "60%", xs: "80%" }}
                  width={1}
                  mb={4}
                  direction={{
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  }}
                  justifyContent={"space-between"}
                  gap={3}
                >
                  <Stack width={1}>
                    <Typography
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      Font size
                    </Typography>
                    <MuiSlider
                      sx={{ width: "90%", color: themeDarkBlue }}
                      // sx={{width:"90%"}}
                      aria-label="Temperature"
                      // defaultValue={textPosition.top}
                      // getAriaValueText={valuetext}
                      min={10}
                      max={64}
                      value={isMeme ? +quotefontSize : +authorfontSize}
                      onChange={(e) => {
                        isMeme
                          ? setQuoteTextStyling((prev) => {
                              prev.set("fontSize", e.target.value);
                              return prev;
                            })
                          : setATextStyling((prev) => {
                              prev.set("afontSize", e.target.value);
                              return prev;
                            });
                      }}
                      color="secondary"
                    />
                  </Stack>

                  <Stack width={1}>
                    <Typography
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      Font family
                    </Typography>
                    <Select
                      sx={{ height: 40 }}
                      value={isMeme ? quoteFontFamily : authorFontFamily}
                      defaultValue={textStyling?.availabeFontsArray[0]}
                      onChange={
                        (e) => {
                          isMeme
                            ? setQuoteTextStyling((prev) => {
                                prev.set("fontFamily", e.target.value);
                                return prev;
                              })
                            : setATextStyling((prev) => {
                                prev.set("afontFamily", e.target.value);
                                return prev;
                              });
                        }
                        // setTextStyling({
                        //   ...textStyling,
                        //   fontFamily: e.target.value,
                        // })
                      }
                    >
                      {textStyling?.availabeFontsArray?.map((e) => {
                        return (
                          <MenuItem
                            sx={{ fontFamily: e, textTransform: "capitalize" }}
                            value={e}
                          >
                            {e}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>

                  <Stack width={1}>
                    <Typography
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        marginTop: 5,
                      }}
                    >
                      Font Color
                    </Typography>
                    <input
                      onChange={(e) => {
                        isMeme
                          ? setQuoteTextStyling((prev) => {
                              prev.set("fontColor", e.target.value);
                              return prev;
                            })
                          : setATextStyling((prev) => {
                              prev.set("afontColor", e.target.value);
                              return prev;
                            });
                      }}
                      value={isMeme ? quoteFontColor : authorFontColor}
                      type="color"
                    />
                  </Stack>
                </Stack>

                <Stack
                  // width={{ xl: "50%", lg: "50%", md: "50%", sm: "60%", xs: "80%" }}
                  width={1}
                  mb={4}
                  direction={"row"}
                  // spacing={1}
                >
                  <Stack flex={1}>
                    <Typography
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      Font Style
                    </Typography>
                    <SelectComp
                      placeholder={"Select Font weight"}
                      renderArray={fontWeightArray}
                      value={isMeme ? quoteFontWeight : authorFontWeight}
                      onChange={(e) => {
                        isMeme
                          ? setQuoteTextStyling((prev) => {
                              prev.set("fontWeight", e.target.value);
                              return prev;
                            })
                          : setATextStyling((prev) => {
                              prev.set("afontWeight", e.target.value);
                              return prev;
                            });
                      }}
                    />
                  </Stack>
                  {/* <Stack flex={1}>
                    <Typography
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      Font Style
                    </Typography>
                    <SelectComp
                      placeholder={"Select Font style"}
                      renderArray={fontStyleArray}
                      value={
                        isMeme
                          ? textStyling.fontStyle
                          : authorTextStyling.fontStyle
                      }
                      onChange={(e) => {
                        isMeme
                          ? setTextStyling({
                              ...textStyling,
                              fontStyle: e.target.value,
                            })
                          : setAuthorTextStyling({
                              ...authorTextStyling,
                              fontStyle: e.target.value,
                            });
                      }}
                    />
                  </Stack> */}
                </Stack>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={3}
                  marginBottom={5}
                >
                  <Button
                    disabled={isLoading}
                    onClick={exportAsImage}
                    variant="outlined"
                  >
                    Download Image {isLoading && <CircularProgress size={20} />}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/make-your-own");
                    }}
                    variant="outlined"
                  >
                    Make your own slide
                  </Button>

                  <ShareButtons url={url} />
                </Stack>
              </Grid>
            </Grid>

            <Stack flexDirection={"row"} justifyContent={"flex-end"}></Stack>

            <canvas id="canvas" style={{ display: "none" }}></canvas>
            {/* <Typography
              mt={4}
              fontSize={20}
              fontWeight="600"
              textAlign="center"
            >
              Select the Background
            </Typography> */}
            {/* <Grid container spacing={2} my={6}>
              {imagesArray.map((imageUrl, index) => (
                <Grid
                  style={{ cursor: "pointer" }}
                  item
                  xs={4}
                  sm={4}
                  md={2}
                  lg={2}
                  xl={2}
                  key={index}
                  onClick={() => setCurrentImage(imageUrl)}
                >
                  <img
                    width="100%"
                    style={{
                      border: `${currentImage === imageUrl ? "3" : "0"
                        }px solid ${themeDarkBlue}`,
                      objectFit: "fill",
                    }}
                    src={imageUrl}
                    alt={`Background ${index}`}
                  />
                </Grid>
              ))}
            </Grid> */}
            {/* <Grid item xl={6} xs={12} sm={12} md={6} lg={6}>
              <Stack
                mt={5}
                sx={{ zIndex: 100 }}
                ml={-10}
                className="leftSlide"
                position={"relative"}
              >
                <div
                  style={{
                    marginTop: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Slider ref={sliderRef} {...settings}>
                    {quoteBackgroundImgList.map((imageUrl, index) => (
                      <Grid
                        style={{ cursor: "pointer" }}
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        key={index}
                        gap={2}
                        spacing={4}
                        onClick={() => setCurrentImage(imageUrl)}
                      >
                        <img
                          width="100%"
                          height={"100px"}
                          style={{
                            border: `${
                              currentImage === imageUrl ? "3" : "0"
                            }px solid ${themeDarkBlue}`,
                            objectFit: "fill",
                            cursor: "pointer",
                          }}
                          src={imageUrl}
                          alt={`Background ${index}`}
                        />
                      </Grid>
                    ))}
                  </Slider>
                </div>
              </Stack>
            </Grid>
            <Stack
              mb={4}
              flexDirection={"row"}
              mt={2}
              justifyContent={"center"}
              gap={3}
            >
              <Stack bgcolor={themeDarkBlue} borderRadius={"20px"} p={1}>
                <CustomNextArrow onClick={prevSlide} />
              </Stack>
              <Stack bgcolor={themeDarkBlue} borderRadius={"20px"} p={1}>
                <CustomPrevArrow onClick={nextSlide} />
              </Stack>
            </Stack> */}
          </Container>
        )
      )}
      <Footer />
    </>
  );
}

export default SelectBGPic;
