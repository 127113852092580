import { Stack, Typography } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';

const ViewQuote = () => {
    const location = useLocation();
    const state = location.state;
    // console.log(state);
  return (
    <Stack
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            width={1}
            id="myContent"
          >
            <Stack
              style={{
                width: "100%",
                height: "100vh",
                backgroundImage: `url(${state.img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                // borderRadius: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="p"
                p={3}
                style={{ color: "white", fontSize: 34, textAlign: "center" }}
              >
                {state?.Quote}
              </Typography>
              <Typography
                variant="p"
                p={3}
                style={{ color: "white", fontSize: 28,fontWeight:"lighter", textAlign: "center" }}
              >
                {state?.Author}
              </Typography>
              {/* <Stack justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Typography variant="p" style={{ color: "white", fontSize: 14, textAlign: "end" }}>
                {quoteData?.author}
              </Typography>
            </Stack> */}
            </Stack>
          </Stack>
  )
}

export default ViewQuote