import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getVerse } from "../services/QuotesService";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/Footer/Footer";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { asyncStatus } from "../store/constants";
import { themeDarkBlue } from "../utils/colorTheme";

const Verse = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { verseDataError, verseDataStatus, verseData } = useSelector(
    (state) => state.quotes
  );

  const LOADING = verseDataStatus == asyncStatus.LOADING;

  console.log(`====>`, verseData);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getVerse({ query: id }));
  }, [, id]);
const navigate = useNavigate()
  return (
    <Stack height={"100vh"}>
      <Stack flex={1}>
        <Navbar />;
      </Stack>
      <Stack flex={10}>
        {LOADING ? (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : verseDataError ? (
          <Stack>
            <Typography>
              Something went wrong{" "}
              <Button onClick={() => dispatch(getVerse({ versename: id }))}>
                Reload
              </Button>
            </Typography>
          </Stack>
        ) : (
          verseData?.length && (
            <Container>
              {verseData?.map((e) => {
                return (
                  <Grid container mt={3} alignItems="start">
                    <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                      <Stack
                        sx={{
                          fontSize: {
                            sm: "14px",
                            xs: `14px`,
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {e?.VerseNo}
                      </Stack>
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                      <Stack
                        sx={{
                          fontSize: {
                            sm: "14px",
                            xs: `14px`,
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: "bold",

                          color: themeDarkBlue,
                        }}
                      >
                        {e?.BSB}
                      </Stack>
                    </Grid>
                  </Grid>
                );
              })}
              
        <Stack alignItems={"center"} mt={2}>
        <Button sx={{textTransform:"none",width:200}} color="inherit" variant="outlined" onClick={()=>{navigate("/bible",{state:{chapter:verseData[0]?.VerseNo?.split(":")[0]}})}}>View full chapter</Button>
        </Stack>
            </Container>
          )
        )}
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"} mt={5}>
        <Typography
          textAlign={"center"}
          maxWidth={580}
          width={"100%"}
          fontSize={12}
          p={1}
        >
          The Holy Bible, Berean Standard Bible, BSB is produced in cooperation
          with Bible Hub, Discovery Bible, OpenBible.com, and the Berean Bible
          Translation Committee. This text of God's Word has been dedicated to
          the public domain. Free resources and databases are available at
          BereanBible.com.
        </Typography>
      </Stack>
      <Stack flex={1}>
        <Footer />
      </Stack>
    </Stack>
  );
};

export default Verse;
