export const serviceConstant = {
  GET_QUOTES: "GET_QUOTES",
  GET_QUOTE_BY_ID: "GET_QUOTE_BY_ID",
  GET_QUOTE_BY_TOPIC_NAME: "GET_QUOTE_BY_TOPIC_NAME",
  GET_QUOTE_BY_AUTHOR_NAME: "GET_QUOTE_BY_AUTHOR_NAME",
  GET_QUOTE_BY_SEARCH: "GET_QUOTE_BY_SEARCH",
  GET_ALL_TOPICS: "GET_ALL_TOPICS",
  GET_ALL_AUTHORS: "GET_ALL_AUTHORS",
  GET_ALL_AUTHORS_PAGINATION: "GET_ALL_AUTHORS_PAGINATION",
  LOGIN: "LOGIN",
  CHECK_AUTH: "CHECK_AUTH",
  REGISTER: "REGISTER",
  LIKE_DISLIKE_QUOTE: "LIKE_DISLIKE_QUOTE",
  SUBSCRIBE_TO_EMAIL: "SUBSCRIBE_TO_EMAIL",
  GET_VERSE: "GET_VERSE",
  GET_MAIN_CHAPTERS: "GET_MAIN_CHAPTERS",
  GET_VERSE_INNER_CHAPTERS: "GET_VERSE_INNER_CHAPTERS",
  GET_SINGLE_VERSE: "GET_SINGLE_VERSE",
};
