import {
  applyMiddleware,
  combineReducers,
  compose,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import user_auth_slice from "./slices/user_auth_slice";
import quotesSlice from "./slices/quoteSlice";

let reducers = combineReducers({
  userAuth: user_auth_slice,
  quotes: quotesSlice,
});
// const rootReducer = (state, action) => {
//   if (action.type === TYPE_LOGOUT_USER) {
//     state = undefined;
//   }
//   return reducers(state, action);
// };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = configureStore(
  { reducer: reducers },
  composeEnhancers(applyMiddleware(thunk))
);
export default store;
