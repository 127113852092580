import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus, searchBy } from "../constants";
import {
  getAllAuthors,
  getAllAuthorsPagination,
  getQuoteByAuthorName,
  getQuoteByID,
  getQuoteBySearch,
  getQuoteByTopicName,
  getQuotes,
  getTopicsAsync,
  getVerse,
  get_inner_single_chapter_verses,
  get_main_chapters,
  get_verse_inner_chapters,
  likeDislikeQuote,
  subscribeToEmail,
} from "../../services/QuotesService";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";

const initialState = {
  getQuotesData: [],
  getQuotesStatus: asyncStatus.IDLE,
  getQuotesError: null,

  //QUOTE-BY-AUTHOR-NAME

  authorQuotesData: null,
  authorQuotesStatus: asyncStatus.IDLE,
  authorQuotesError: null,
  authorQuotesTotalPage: null,
  //QUOTE-BY-TOPIC-NAME

  topicQuotesData: null,
  topicQuotesStatus: asyncStatus.IDLE,
  topicQuotesError: null,
  topicQuotesTotalPages: null,

  //QUOTE-BY-ID

  quotesByIdData: null,
  quotesByIdStatus: asyncStatus.IDLE,
  quotesByIdError: null,

  //QUOTE-BY-SEARCH

  // quotesBySearchData: null,
  // quotesBySearchStatus: asyncStatus.IDLE,
  // quotesBySearchError: null,

  searchByKey: "TOPIC",

  //ALL-TOPICS-DATA
  getTopicsStatus: asyncStatus.IDLE,
  getTopicsError: null,
  getTopicsData: null,

  //ALL-AUTHORS-DATA
  getAuthorsStatus: asyncStatus.IDLE,
  getAuthorsError: null,
  getAuthorsData: null,

  // //ALL AUTHORS DATA WITH PAGINATION
  // getAuthorsPaginationStatus: asyncStatus.IDLE,
  // getAuthorsPaginationError: null,
  // getAuthorsPaginationData: null,

  quotesBySearchStatus: asyncStatus.IDLE,
  quotesBySearchData: [],
  quotesBySearchError: null,

  //LIKE || DISLIKE QUOTE
  QuoteLikeDislikeStatus: asyncStatus.IDLE,
  QuoteLikeDislikeData: null,
  QuoteLikeDislikeError: null,

  //SubscribeToEmail
  SubscribeToEmailStatus: asyncStatus.IDLE,
  SubscribeToEmailData: null,
  SubscribeToEmailError: null,

  //GET-VERSE
  verseDataStatus: asyncStatus.IDLE,
  verseData: null,
  verseDataError: null,

  //GET-CHAPTERS
  chapterMainStatus: asyncStatus.IDLE,
  chapterMainData: null,
  chapterMainError: null,

  //GET-VERSE-INNER-CHAPTERS
  verseInnerChapterData: asyncStatus.IDLE,
  verseInnerChapterStatus: null,
  verseInnerChapterError: null,

  //GET-VERSE-SINGLE
  single_verses_Status: asyncStatus.IDLE,
  single_verses_Data: null,
  single_verses_Error: null,
};

const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    setSearchByKey(state, { payload }) {
      state.searchByKey = payload;
    },
    setQuotesBySearchStatus(state, { payload }) {
      state.quotesBySearchStatus = payload;
    },
    setSearchState(state, { payload }) {
      state.quotesBySearchStatus = asyncStatus.IDLE;
    },
    setSearchDataState(state, { payload }) {
      state.quotesBySearchData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuotes.pending, (state) => {
      state.getQuotesStatus = asyncStatus.LOADING;
      state.getQuotesError = null;
    });
    builder.addCase(getQuotes.fulfilled, (state, { payload }) => {
      const { getQuotesData } = state;
      if (getQuotesData?.length !== null) {
        let a = [...getQuotesData];
        payload.data.map((e) => a.push(e));
        state.getQuotesData = a;
      } else {
        state.getQuotesData = payload.data;
      }
      state.getQuotesStatus = asyncStatus.SUCCEEDED;
      state.getQuotesError = null;
    });
    builder.addCase(getQuotes.rejected, (state, actions) => {
      state.getQuotesStatus = asyncStatus.ERROR;
      state.getQuotesError = actions.error;
    });

    //QUOTE-BY-AUTHOR-NAME
    builder.addCase(getQuoteByAuthorName.pending, (state) => {
      state.authorQuotesStatus = asyncStatus.LOADING;
      state.authorQuotesError = null;
    });
    builder.addCase(getQuoteByAuthorName.fulfilled, (state, { payload }) => {
      state.authorQuotesData = payload.data;
      state.authorQuotesTotalPage = payload.totalPages;
      state.authorQuotesStatus = asyncStatus.SUCCEEDED;
      state.authorQuotesError = null;
    });
    builder.addCase(getQuoteByAuthorName.rejected, (state, actions) => {
      state.authorQuotesStatus = asyncStatus.ERROR;
      state.authorQuotesError = actions.error;
    });
    //QUOTE-BY-TOPIC-NAME
    builder.addCase(getQuoteByTopicName.pending, (state) => {
      state.topicQuotesStatus = asyncStatus.LOADING;
      state.topicQuotesError = null;
    });
    builder.addCase(getQuoteByTopicName.fulfilled, (state, { payload }) => {
      state.topicQuotesData = payload.data;
      state.topicQuotesTotalPages = payload.totalPages;
      state.topicQuotesStatus = asyncStatus.SUCCEEDED;
      state.topicQuotesError = null;
    });
    builder.addCase(getQuoteByTopicName.rejected, (state, actions) => {
      state.topicQuotesStatus = asyncStatus.ERROR;
      state.topicQuotesError = actions.error;
    });

    //QUOTE-BY-ID
    builder.addCase(getQuoteByID.pending, (state) => {
      state.quotesByIdStatus = asyncStatus.LOADING;
      state.quotesByIdError = null;
    });
    builder.addCase(getQuoteByID.fulfilled, (state, { payload }) => {
      state.quotesByIdData = payload.data;
      // console.log(payload.data)
      state.quotesByIdStatus = asyncStatus.SUCCEEDED;
      state.quotesByIdError = null;
    });
    builder.addCase(getQuoteByID.rejected, (state, actions) => {
      state.quotesByIdStatus = asyncStatus.ERROR;
      state.quotesByIdError = actions.error;
    });

    //ALL TOPICS
    builder.addCase(getTopicsAsync.pending, (state) => {
      state.getTopicsStatus = asyncStatus.LOADING;
      state.getTopicsError = null;
    });
    builder.addCase(getTopicsAsync.fulfilled, (state, { payload }) => {
      // state.getTopicsData = payload.Ctegories.flat(); ye flat jo b hai is ki wja sy cheezie break horhi hain agr ye chl jaye tw topics wly page b ek dfa visit kr k dkh lyna wo inteqaal hochuka hoga wrna KCH COMMENTS MAINY SEARCH WLY PAGE PY B LIKHYN HAIN WO B DKH LYNA THNKU
      state.getTopicsData = payload;
      state.getTopicsStatus = asyncStatus.SUCCEEDED;
      state.getTopicsError = null;
    });
    builder.addCase(getTopicsAsync.rejected, (state, actions) => {
      state.getTopicsStatus = asyncStatus.ERROR;
      state.getTopicsError = actions.error;
    });

    //ALL AUTHORS DATA

    builder.addCase(getAllAuthors.pending, (state) => {
      state.getAuthorsStatus = asyncStatus.LOADING;
      state.getAuthorsError = null;
    });
    builder.addCase(getAllAuthors.fulfilled, (state, { payload }) => {
      state.getAuthorsData = payload;
      state.getAuthorsStatus = asyncStatus.SUCCEEDED;
      state.getAuthorsError = null;
    });
    builder.addCase(getAllAuthors.rejected, (state, actions) => {
      state.getAuthorsStatus = asyncStatus.ERROR;
      state.getAuthorsError = actions.error;
    });

    //ALL Quotes Search

    builder.addCase(getQuoteBySearch.pending, (state) => {
      state.quotesBySearchStatus = asyncStatus.LOADING;
      state.quotesBySearchError = null;
    });
    builder.addCase(getQuoteBySearch.fulfilled, (state, { payload }) => {
      if (state.quotesBySearchData.length) {
        state.quotesBySearchData = [
          ...state.quotesBySearchData,
          ...payload.results,
        ];
      } else {
        state.quotesBySearchData = payload.results;
      }
      state.quotesBySearchStatus = asyncStatus.SUCCEEDED;
      state.quotesBySearchError = null;
    });
    builder.addCase(getQuoteBySearch.rejected, (state, actions) => {
      state.quotesBySearchStatus = asyncStatus.ERROR;
      state.quotesBySearchError = actions.error;
    });

    //LIKE || DISLIKE QUOTE
    builder.addCase(likeDislikeQuote.pending, (state) => {
      state.QuoteLikeDislikeStatus = asyncStatus.LOADING;
      state.QuoteLikeDislikeError = null;
    });
    builder.addCase(likeDislikeQuote.fulfilled, (state, { payload }) => {
      state.QuoteLikeDislikeData = payload.results;
      state.QuoteLikeDislikeStatus = asyncStatus.SUCCEEDED;
      state.quotesByIdData.likes = !payload.message.includes("disliked")
        ? state.quotesByIdData.likes + 1
        : state.quotesByIdData.likes - 1;
      state.quotesByIdData.isLiked = !state.quotesByIdData.isLiked;
      state.QuoteLikeDislikeError = null;
    });
    builder.addCase(likeDislikeQuote.rejected, (state, actions) => {
      state.QuoteLikeDislikeStatus = asyncStatus.ERROR;
      state.QuoteLikeDislikeError = actions.error;
    });

    // SubscribeToEmail

    builder.addCase(subscribeToEmail.pending, (state) => {
      state.SubscribeToEmailStatus = asyncStatus.LOADING;
      state.SubscribeToEmailError = null;
    });
    builder.addCase(subscribeToEmail.fulfilled, (state, { payload }) => {
      state.SubscribeToEmailStatus = asyncStatus.SUCCEEDED;
      state.SubscribeToEmailData = payload.data;
      success_toast_message(payload.message);
      state.SubscribeToEmailError = null;
    });
    builder.addCase(subscribeToEmail.rejected, (state, actions) => {
      state.SubscribeToEmailStatus = asyncStatus.ERROR;
      state.SubscribeToEmailError = actions.error;
      error_toast_message(actions.error.message);
    });

    //GET-VERsE
    builder.addCase(getVerse.pending, (state) => {
      state.verseDataStatus = asyncStatus.LOADING;
      state.verseDataError = null;
    });
    builder.addCase(getVerse.fulfilled, (state, { payload }) => {
      state.verseData = payload.data;
      state.verseDataStatus = asyncStatus.SUCCEEDED;
      state.verseDataError = null;
    });
    builder.addCase(getVerse.rejected, (state, actions) => {
      state.verseDataStatus = asyncStatus.ERROR;
      state.verseDataError = actions.error;
    });

    //GET-CHAPTERS
    builder.addCase(get_main_chapters.pending, (state) => {
      state.chapterMainStatus = asyncStatus.LOADING;
      state.chapterMainError = null;
    });
    builder.addCase(get_main_chapters.fulfilled, (state, { payload }) => {
      state.chapterMainData = payload.data[0];
      state.chapterMainStatus = asyncStatus.SUCCEEDED;
      state.chapterMainError = null;
    });
    builder.addCase(get_main_chapters.rejected, (state, actions) => {
      state.chapterMainStatus = asyncStatus.ERROR;
      state.chapterMainError = actions.error;
    });

    //GET-VERSE-INNER-CHAPTERS
    builder.addCase(get_verse_inner_chapters.pending, (state) => {
      state.verseInnerChapterStatus = asyncStatus.LOADING;
      state.verseInnerChapterError = null;
    });
    builder.addCase(get_verse_inner_chapters.fulfilled, (state, { payload }) => {
      state.verseInnerChapterData = payload;
      state.verseInnerChapterStatus = asyncStatus.SUCCEEDED;
      state.verseInnerChapterError = null;
    });
    builder.addCase(get_verse_inner_chapters.rejected, (state, actions) => {
      state.verseInnerChapterStatus = asyncStatus.ERROR;
      state.verseInnerChapterError = actions.error;
    });

    //GET-VERSE-INNER-CHAPTERS
    builder.addCase(get_inner_single_chapter_verses.pending, (state) => {
      state.single_verses_Status = asyncStatus.LOADING;
      state.single_verses_Error = null;
    });
    builder.addCase(get_inner_single_chapter_verses.fulfilled, (state, { payload }) => {
      state.single_verses_Data = payload;
      state.single_verses_Status = asyncStatus.SUCCEEDED;
      state.single_verses_Error = null;
    });
    builder.addCase(get_inner_single_chapter_verses.rejected, (state, actions) => {
      state.single_verses_Status = asyncStatus.ERROR;
      state.single_verses_Error = actions.error;
    });
  },
});

export const {
  setSearchByKey,
  setQuotesBySearchStatus,
  setSearchState,
  setSearchDataState,
} = quotesSlice.actions;
export default quotesSlice.reducer;
